import { Route, Redirect } from 'react-router'
import { Navigate } from 'react-router-dom'
import { Link } from "react-router-dom";
import React from "react";
import axios from "axios";
import upute from '../Biblio - upute za korisnike v1.2.pdf';
function Home(props) {



    const [repo, setRepo] = React.useState(null);
    
 

    React.useEffect(() => {
        
        axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/sviRadovi/0/8').then((response) => {
            setRepo(response.data);
           
        });
    }, []);





    if (!repo) return null;



    return (
        

        <div className="container">

            {props.prijavljen ? (
                <Navigate to="/radovi" />
            ) : (
         
                <div>

<div className="text-center">
                        
                        <Link className="btn btn-secondary rounded-corners ubuntu-light-cond" to={'/radovi'} >Pretraži sve radove djelatnika FERIT-a</Link>
                        <br/>
                        <br/>
                        </div>


                <div className="row">

                    <div className="col-md-3 padding15">
                    </div>
                    <div className="col-md-6 padding15">
                        <hr/>
                        <div className="ubuntu-dark-cond fw-bold">
                            Biblio v1.2 novosti:<br/>                                                       
                        </div>
                        <div className="ubuntu-dark-cond">
                            - Omogućeno zaključavanje datuma objave i filtriranje prema tom parametru.<br/>
                            - Omogućeno označavanje radova izrađenih izvan ustanove (FERIT-a) i filtriranje prema tom parametru.<br/>
                            - Omogućeno označavanje radova koji su duplikati kako se ne bi prikazivali u pretrazi.<br/>
                            - Omogućen unos <i>Journal Citation Indicatora (JCI)</i> i kvartila prema istome.<br/>
                            - Omogućeno filtriranje prema JCI kvartilima. <br/>
                            - Omogućeno označavanje radova koji su u otvorenom pristupu i filtriranje prema tom parametru.<br/>                                                       
                        </div>
                        <br/>
                        <div className="ubuntu-dark-cond">Za više informacija o korištenju novih funkcionalnosti pogledajte <a href={upute} className="ubuntu-result-bold" download={true}>Upute za korisnike</a>.</div>
                        <hr/><br/>
                        <div className="ubuntu-dark-cond h5 text-center">&nbsp;Nedavno dodani radovi:&nbsp;</div>
                        
                        {repo.data.map((post, index) => {



                            return (
                                <>
                                    <hr />
                                    {/*<div className="full-width ubuntu-result-bold"><Link className="full-width ubuntu-result-bold" to={{pathname: '/rad', state:{nesto: "dfg"}}}> {post.naslov_en} </Link></div>*/}
                                    {/*<div className="full-width ubuntu-result-bold"><Link className="full-width ubuntu-result-bold" to={{pathname: '/rad', nesto: true}}> {post.naslov_en} </Link></div>*/}
                                    <div className="full-width ubuntu-result-bold">
                                        <Link className="full-width ubuntu-result-bold" to={`/rad/${post.id}`}> {post.naslov_en} </Link></div>
                                    <div className="full-width ubuntu-result"> {post.autori} </div>
                                    <div className="full-width ubuntu-result"> {post.naziv_casopisa} </div>
                                    <div className="full-width ubuntu-result"> {post.godina} </div>
                                    <div className="full-width "> <span className="ubuntu-light-cond-invert">&nbsp;{post.crosbi_kategorija}&nbsp;</span> </div>
                                </>
                            )

                        })}
                        
                    </div>
                    <div className="col-md-3 padding15">
                    </div>
                        <div className="text-center">
                        <hr />
                        <Link className="btn btn-secondary rounded-corners ubuntu-light-cond" to={'/radovi'} >Pretraži više radova...</Link>
                        <br/>
                        <br/>
                        </div>
                </div>

                </div>
            )}
        </div>
    );
}

export default Home;