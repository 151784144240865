import logo from './biblio_logo.png';
import ferit_logo from './ferit_logo_small.png';
import upute from './Biblio - upute za korisnike v1.2.pdf';


import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import './App.css';
import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee, faSignOutAlt, faSignInAlt, faPlusSquare, faSearch, faPlus, faServer, faUser, faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useRef, createContext } from 'react';
import DatePicker from 'react-datepicker';

import { Route, Switch, BrowserRouter as Router, Routes, Link, Navigate, Redirect } from "react-router-dom";
import Home from './pages/Home';
import Radovi from './pages/Radovi';
import Rad from './pages/Rad';
import UrediRad from './pages/UrediRad';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';


library.add(fab, faCheckSquare, faCoffee, faSignOutAlt, faSignInAlt, faPlusSquare, faSearch, faPlus, faServer, faUser, faArrowCircleUp, faArrowCircleDown)

toast.configure()

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwt')}`;

function App() {

  const filters = useSelector(state => state.filters);
  const prijavljeniKorisnik = useSelector(state => state.prijavljeniKorisnik);

  const [term, setTerm] = useState('');

  const handleInput = () => {
    console.log(term);
  }

  const [prijavljen, setPrijavljen] = useState(false)
  const [korisnik, setKorisnik] = useState('Anonimni korisnik')
  const [korisnikFull, setKorisnikFull] = useState({ value: 'sviRadovi', label: 'Svi autori' })
  const [dropDownKor, setDropdownKor] = useState('Anonimni korisnik')




  const prijavaPath = "https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api.php/biblioredirect?path=" + encodeURIComponent(process.env.REACT_APP_LOGIN_RETURN_PATH)


  React.useEffect(() => {


    const jwt = new URL(window.location).searchParams.get('jwt');

    //ako ima jwt u URL odmah pokuši njega (kod prijave)
    if (jwt) {
      localStorage.setItem('jwt', jwt);            
    }
    else{
      let local =localStorage.getItem('jwt')
      
      let expired = true;

      //ako je local storage prazan prvo mu dodijeli John Doe token, a ako nije onda provjerava jel token u LS expired
      if (local == null) {
        localStorage.setItem('jwt', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhYWkiOiJBbm9uaW1uaSBrb3Jpc25payIsImltZSI6IiIsInByZXppbWUiOiIiLCJ0aXR1bGEiOiIiLCJlbWFpbCI6IiIsImlhdCI6MTY3MTYxODk3OCwiZXhwIjoxOTcxNzA1Mzc4fQ.RlRmy2QbHmiGhVz80ngUxuQzpZCU4Z5jDsUC8VixsHw');
      }
      else {

        let decoded = jwtDecode(local);
        let expDate = new Date(decoded.exp * 1000);
        let now = new Date();
        if (expDate > now) expired = false;
        console.log("jwt exp decoded", expDate, "now is", now, "expired", expired);
        if (expired) localStorage.setItem('jwt', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhYWkiOiJBbm9uaW1uaSBrb3Jpc25payIsImltZSI6IiIsInByZXppbWUiOiIiLCJ0aXR1bGEiOiIiLCJlbWFpbCI6IiIsImlhdCI6MTY3MTYxODk3OCwiZXhwIjoxOTcxNzA1Mzc4fQ.RlRmy2QbHmiGhVz80ngUxuQzpZCU4Z5jDsUC8VixsHw');
    
      }


    }


    console.log("raw jwt", localStorage.getItem("jwt"));

    const decodedToken = jwtDecode(localStorage.getItem('jwt'));

    prijavljeniKorisnik.aai = decodedToken.aai;
    console.log("jwt: ", decodedToken);
    setKorisnik(decodedToken.aai);

    if (decodedToken.aai === "Anonimni korisnik") {
      setKorisnikFull({ value: 'sviRadovi', label: 'Svi autori' });
      setPrijavljen(false);
    }
    else {
      setKorisnikFull({ value: decodedToken.aai, label: decodedToken.prezime + " " + decodedToken.ime });
      setDropdownKor(decodedToken.aai);
      setPrijavljen(true);
      filters.autori[0] = { label: decodedToken.prezime + " " + decodedToken.ime, value: decodedToken.aai }      
    }

    console.log("App kaže:", filters.autori);


    /*
        //axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/logged-user', { withCredentials: true} )
        axios('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/logged-user', {
          //axios('https://www.ferit.unios.hr/biblio/?server=1', {
          method: 'GET',
          withCredentials: true
        })
          .then((response) => {
    
            //console.log("prijavljen je:" + response.data.data[0].ime);
            if (response.data.data[0].aai == "") {
              setKorisnik('Anonimni korisnik');
              setKorisnikFull({ value: 'sviRadovi', label: 'Svi autori' });
              setPrijavljen(false);
    
            }
            else {
              prijavljeniKorisnik.aai = response.data.data[0].aai;
              setKorisnik(response.data.data[0].aai);
              setKorisnikFull({ value: response.data.data[0].aai, label: response.data.data[0].prezime + " " + response.data.data[0].ime });
              setDropdownKor(response.data.data[0].aai);
              setPrijavljen(true);
              filters.autori[0] = { label: response.data.data[0].prezime + " " + response.data.data[0].ime, value: response.data.data[0].aai }
              
            }
            
          });
    */

          const handleLoad = () => {
            // Perform actions after the component has fully loaded
            console.log("AFTER RELOAD")
            if (window.location.href.indexOf("radovi") != -1) logoElement.current?.click();
          };
          window.addEventListener('load', handleLoad);
          return () => {
            window.removeEventListener('load', handleLoad);
          };


  }, []);

  //if (!korisnik) return null;

  function handlePrijavaOdjava() {

    if (!prijavljen) {
      // setKorisnik('hleventic');
      // setDropdownKor('kromic');
    }
    else {
      // setKorisnik('Anonimni korisnik');
      // setDropdownKor('Anonimni korisnik');
    }

    setPrijavljen(!prijavljen);

  }

  function handleOdjava(){
    //poništavanje jwt tokena u local storageu, stavljam jwt od Anonimnog korisnika
    localStorage.setItem('jwt', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhYWkiOiJBbm9uaW1uaSBrb3Jpc25payIsImltZSI6IiIsInByZXppbWUiOiIiLCJ0aXR1bGEiOiIiLCJlbWFpbCI6IiIsImlhdCI6MTY3MTYxODk3OCwiZXhwIjoxOTcxNzA1Mzc4fQ.RlRmy2QbHmiGhVz80ngUxuQzpZCU4Z5jDsUC8VixsHw');
  }

  function handleLogoClick() {
    filters.autori = []
    filters.autori[0] = korisnikFull
    filters.datumOd = new Date(new Date().getFullYear() - 5 + "-" + new Date().toLocaleString("en-US", { month: "2-digit" }) + "-" + new Date().toLocaleString("en-US", { day: '2-digit' }))
    filters.datumDo = new Date()
    filters.radSDatumom = { label: "Svejedno", value: 2 }
    filters.vrstaPublikacije = { label: 'Sve', value: 0 }
    filters.podkategorijaPublikacije = { label: 'Sve', value: 0 }
    filters.kvartil = { label: "Svejedno", value: 0 }
    filters.kvartilJCRMulti = [{ label: "Svejedno", value: 0 }]
    filters.kvartilJCIMulti = [{ label: "Svejedno", value: 0 }]
    filters.kvartilSJRMulti = [{ label: "Svejedno", value: 0 }]
    filters.povoljnijiQ = { label: "DA (od svih)", value: 1 }
    filters.kvartilJCR = { label: "Svejedno", value: 0 }
    filters.kvartilSJR = { label: "Svejedno", value: 0 }
    filters.inozemniCasopis = { label: "Svejedno", value: 2 }
    filters.radSaStudentom = { label: "Svejedno", value: 2 }
    filters.radSDoktorandom = { label: "Svejedno", value: 2 }
    filters.radSGospodarstvom = { label: "Svejedno", value: 2 }
    filters.radNaProjektu = { label: "Svejedno", value: 2 }
    filters.medjuzavodskiRad = { label: "Svejedno", value: 2 }
    filters.radOtvoreniPristup = { label: "Svejedno", value: 2 }
    filters.vanUstanove = { label: "Ne", value: 0 }
    filters.indeksiranost = [true, true, true, true, true]

  }

  const searchHandler = () => {

    console.log('Searched');
    // return  <Navigate to="/radovi" />

  }

  function handleMojiClick() {

    setDropdownKor('kromic');
    setKorisnik('kromic');
    console.log("dd: " + dropDownKor + "    kor:" + korisnik);
  }

  function handleSviClick() {
    setDropdownKor('Anonimni korisnik');
    console.log("dd: " + dropDownKor + "    kor:" + korisnik);
  }

  const handleKeyDown = (event) => {

    if (event.key === "Enter") {
      console.log("pritisnuta tipka: ", event.key);
      var link = document.getElementById('search-button');
      link.click();
    }
  }

  const logoElement = React.useRef()

  return (

    <div className="container">

      <div className="row dark-gray-back rounded-corners-bottom">

        <div className="col-md-3 col-lg-2">
          <a href="https://www.ferit.unios.hr">
            <img src={ferit_logo} className="top-image" alt="Ferit logo" />
          </a>
        </div>

        <div className="col-md-6 col-lg-offset-7 col-lg-3">
        </div>

        <div className="col-md-5 dark-gray-back float-right">





        </div>


        <div className="col-md-2 dark-gray-back login-corner">

          <div className="navbar-right lr-padding ubuntu-white ">
            {korisnik == "Anonimni korisnik" ? korisnik : korisnikFull.label}
            {/*{korisnik}*/}
            &nbsp;
            {/*{prijavljeniKorisnik.aai}*/}
          </div>
          <div className="navbar-right lr-padding ubuntu-white ubuntu-link">

            {prijavljen ?
              /*<Link className="ubuntu-link" to="/" onClick={handlePrijavaOdjava}>
                <span>Odjava </span><FontAwesomeIcon icon="sign-out-alt" />
              </Link>*/
              /*<a className="ubuntu-link" href="https://www.ferit.unios.hr/2021/odjava?q=/2021/" //onClick={handlePrijavaOdjava}>
                <span>Odjava </span><FontAwesomeIcon icon="sign-out-alt" />
              </a>*/
              <a className="ubuntu-link" href={process.env.REACT_APP_LOGIN_RETURN_PATH} onClick={handleOdjava}>
                <span>Odjava </span><FontAwesomeIcon icon="sign-out-alt" />
              </a>
              :
              /*<Link className="ubuntu-link" to="/" onClick={handlePrijavaOdjava}>
                <span>Prijava </span><FontAwesomeIcon icon="sign-in-alt" />
              </Link>*/

              <a className="ubuntu-link" href={"https://www.ferit.unios.hr/new-login/?r=https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/login-jwt?path=" + encodeURIComponent(process.env.REACT_APP_LOGIN_RETURN_PATH)} /*onClick={handlePrijavaOdjava}*/>
                <span>Prijava </span><FontAwesomeIcon icon="sign-out-alt" />
              </a>
            }


          </div>
        </div>

      </div>

      <div className="row">
        <div className="col-xs-12 text-center border-bottom">


          <Link ref={logoElement} onClick={handleLogoClick} to='/'><img src={logo} className="logo-image" alt="Biblio logo" /></Link>
          {/* process.env.REACT_APP_ENV_NAME */}

        </div>
      </div>
{/*
      <div className="row">
            <p className='center ubuntu-red border-bottom padding15'> OBAVIJEST: Zbog usklađivanja s novom CroRIS infrastrukturom, Biblio za sada ne povlači i ne prikazuje radove koji su uneseni u CroRIS nakon 7.7.2023. </p>
      </div>
*/}

      {/*
      <div className="row">
        <div className="col-lg-8">

        </div>
        <div className="col-lg-4">
          <ul className="nav justify-content-end">

            <li className="nav-item">
              <a className="nav-link ubuntu-link2">Dodaj rad&nbsp;&nbsp;<FontAwesomeIcon icon="plus" /></a>
              <Link className="nav-link ubuntu-link2" to='/Rad'>Dodaj rad&nbsp;&nbsp;<FontAwesomeIcon icon="plus" /></Link>
            </li>
            <li className="nav-item">
              <a className="nav-link ubuntu-link3">Svi radovi&nbsp;&nbsp;<FontAwesomeIcon icon="server" /></a>
              <Link className="nav-link ubuntu-link3" to='/radovi' onClick={handleSviClick}>Svi radovi&nbsp;&nbsp;<FontAwesomeIcon icon="server" /></Link>
            </li>
            <li className="nav-item">
              <a className="nav-link ubuntu-link4">Moji radovi&nbsp;&nbsp;<FontAwesomeIcon icon="user" /></a>
              <Link className="nav-link ubuntu-link4" to='/radovi' onClick={handleMojiClick}>Moji radovi&nbsp;&nbsp;<FontAwesomeIcon icon="user" /></Link>
            </li>
          </ul>
        </div >
      </div >

*/}
      <div className="row center searchbar">
        <div className="col-md-3"></div>
        <div className="col-md-6 align-right lr-padding-large">
          <input id="searchterm" onChange={e => setTerm(e.target.value)} onKeyDown={e => handleKeyDown(e)}
            /*e => e.key === 'Enter' && console.log("stisnuo enter1")*/
            to={'/radovi'} name="q" type="text" className="searchbar-input ubuntu-light rounded-corners left-padded-input" placeholder="Traži..." aria-label="searchterm" aria-describedby="basic-addon2">
          </input>  <div className="input-group-append">

          </div>
        </div>
        <div className="col-md-3 search-btn lr-padding-large align-left">
          {/*<button className="btn btn-secondary search-btn-style align-left rounded-corners ubuntu-light-cond" type="submit" onClick={searchHandler}>
          <FontAwesomeIcon icon="search" />
  </button>*/}
          <Link id="search-button" className="btn btn-secondary search-btn-style align-left rounded-corners" to={'/radovi'} onClick={searchHandler}>
            <FontAwesomeIcon icon="search" />
          </Link>
        </div>

      </div>



      <Routes>
        <Route path='/' exact={true} element={<Home prijavljen={prijavljen} korisnik={korisnik} />}>

        </Route>

        <Route path='/radovi' element={<Radovi searchterm={term} dropDownKor={dropDownKor} korisnik={korisnik} prijavljen={prijavljen}/>}>

        </Route>
        <Route path='/rad/:id' element={<Rad korisnik={korisnik} setDropdownKor={setDropdownKor} />}>

        </Route>

        <Route path='/uredirad/:id' element={<UrediRad korisnik={korisnik} />}>

        </Route>
      </Routes>

      {/*ovaj dio s loadingom na glavnom pageu za sada ne radi
      <div>
        {false ? (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : (<div></div>)}
      </div>
      */}



      <div className="row dark-gray-back rounded-corners">
        <footer className="page-footer font-small dark-gray-back ubuntu-gray rounded-corners-top">

          <div className="row">
            <div className="col-md-4 text-center py-1">Biblio v1.2</div>
            <div className="footer-copyright text-center py-1 col-md-4">© 2022
              <a href="https://www.ferit.unios.hr" className="ubuntu-link"> FERIT</a>
            </div>
            <div className="footer-copyright text-center py-1 col-md-4">
              <a href={upute} className="ubuntu-link" download={true}>Upute za korisnike</a>
            </div>
          </div>
        </footer>

      </div>




    </div >

  );
}

export default App;
