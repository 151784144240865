import Filteri from "../components/Filteri";
import { Link, useNavigate } from "react-router-dom";
import App from "../App";
import React, { useEffect, useRef, useState, Component, useContext } from "react";
import axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { NavigateBeforeSharp } from "@material-ui/icons";
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from 'react-bootstrap/Spinner';
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { Alignment, Document, Packer, Paragraph, TextRun, ImageRun } from "docx";
import { saveAs } from "file-saver";
/*import * as fs from "fs";*/
import { faCheckSquare, faCoffee, faSignOutAlt, faPlusSquare, faSave, faSearch, faPlus, faServer, faUser, faArrowCircleUp, faArrowCircleDown, faFilter, faEdit, faChevronLeft, faRetweet, faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons'
library.add(faCheckSquare, faCoffee, faSignOutAlt, faPlusSquare, faSave, faSearch, faPlus, faServer, faUser, faArrowCircleUp, faArrowCircleDown, faFilter, faEdit, faChevronLeft,faRetweet, faExclamationTriangle )

toast.configure()

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwt')}`;

function Radovi(props) {

    const filters = useSelector(state => state.filters);
    const prijavljeniKorisnik = useSelector(state => state.prijavljeniKorisnik);

    let api = 'sviRadovi';
    let ct = 0;

    const [filterCount, setFilterCount] = React.useState(ct);
    const [selectedAuthors, setSelectedAuthors] = useState(filters.autori);

    const [loading, setLoading] = useState(true);

    let textToCopyCrosbi = "";
    let textToCopyIEEE = "";
    let textToCopyIzvjestaj = "";

    let izvjestajAradovi = "";
    let izvjestajBradovi = "";
    let izvjestajCradovi = "";
    let izvjestajKnjige = "";
    let izvjestajOstaliCasRadovi = "";
    izvjestajAradovi = "";
    izvjestajBradovi = "";
    izvjestajCradovi = "";
    izvjestajKnjige = "";
    izvjestajOstaliCasRadovi = "";
    let ctA = 0;
    let ctB = 0;
    let ctC = 0;
    let ctKnjige = 0;
    let ctOstaliCas = 0;
    let ctJCR = [0, 0, 0, 0, 0, 0];
    let ctJCI = [0, 0, 0, 0, 0, 0];
    let ctSJR = [0, 0, 0, 0, 0, 0];



    function refreshPage() {
        window.location.reload(false);
    }

    const probaJWT = ()=>{
        console.log("radovi vide local storage: ",localStorage.getItem('jwt'))
            axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/read-jwt', {
          })
                .then(response => console.log("read-jwt kaže: ",response.data))


    }

    const osvjeziSCROSBI = async () => {
        setLoading(true);
        let year = 2022;
        let success = 0;
        console.log("---->", filters.datumOd.getFullYear());
        for (year = filters.datumOd.getFullYear(); year <= filters.datumDo.getFullYear(); year++) {
           
            console.log("---->", 'https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/osvjezi-radove-autora/' + year + '/' + props.korisnik);
            await axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/osvjezi-radove-autora/' + year + '/' + props.korisnik).then((response) => {
                if (response.data.status === 'success') {
                    success++;
                }
            })
            
        }

        if (success === (filters.datumDo.getFullYear() - filters.datumOd.getFullYear() + 1)) {
            setLoading(false);
            toast.info('Osvježeni su podaci s CROSBI baze korisnika ' + props.korisnik + ' od ' + filters.datumOd.getFullYear() + '. - ' + filters.datumDo.getFullYear() + '. godine.', {
                position: toast.POSITION.BOTTOM_CENTER,
                autClose: 2000,
                transition: Slide
            });

            
            window.location.reload();

        }
        else{
            setLoading(false);
            toast.error('Neuspješno osvježavanje!', {
                position: toast.POSITION.BOTTOM_CENTER,
                autClose: 2000,
                transition: Slide
            });
        }


        /**/
    }


/*
    function prebrojiFiltrirano0() {
        ct = 0;
        {
            repo.data.filter((post) => {
                if (post.naslov_en.toLowerCase().includes(props.searchterm.toLowerCase()) || post.autori.toLowerCase().includes(props.searchterm.toLowerCase())) {
                    if (Number(post.godina) >= datumOd.getFullYear() && Number(post.godina) <= datumDo.getFullYear()) {
                        if (kategorija == 'Sve') {
                            return post;
                        }
                        else {
                            if (post.crosbi_kategorija.toLowerCase().includes(kategorija.toLowerCase())) {
                                return post;

                            }
                        }
                    }
                }
            }).filter((post) => {
                if (kvartil == 0) {
                    return post;
                }
                else {

                    if (najboljiKvartil(post.autori_na_radu) == kvartil) {
                        return post;
                    }
                }
            }).filter((post) => {
                if (sGospodarstvom == 2) {
                    return post;
                }
                else {

                    if (post.rad_sa_gospodarstvom == sGospodarstvom) {
                        return post;
                    }
                }
            }).filter((post) => {
                if (naProjektu == 2) {
                    return post;
                }
                else {
                    if (post.rad_na_projektu == naProjektu) {
                        return post;
                    }
                }
            }).filter((post) => {
                if (saStudentom == 2) {
                    return post;
                }
                else {

                    let flagSaStud = 0;
                    post.autori_na_radu.forEach(element => {
                        // if (props.prijavljen==true) {
                        if (element.aai == props.korisnik) flagSaStud = element.rad_sa_studentom;
                        //}
                        // else {
                        //   if(element.rad_sa_studentom==1)flagSaStud=1;
                        // console.log("FLAG SA STUD   ",flagSaStud);
                        //}
                    });


                    if (flagSaStud == saStudentom) {
                        return post;
                    }

                }
            }).map((post, index) => {

                ct++;
            })

        }
        return ct;
    }
*/

    function prebrojiFiltrirano() {
        ct = 0;
        {

            repo.data.filter((post) => {
                if ((post.naslov_en.toLowerCase().includes(props.searchterm.toLowerCase()) || post.autori.toLowerCase().includes(props.searchterm.toLowerCase()))&& post.duplikat==0) {

                    if ((post.datum_objave === "0000-00-00" || post.datum_objave_lock==0) && (sDatumom==2 || sDatumom==0)) {

                        // prisilno podešavam da radovi bez datuma budu 1.1. u godini objave
                        let prisilniDatum = post.godina + "-01-01"    
                        if (moment(prisilniDatum).format("YYYY-MM-DD") >= moment(datumOd).format("YYYY-MM-DD") && moment(prisilniDatum).format("YYYY-MM-DD") <= moment(datumDo).format("YYYY-MM-DD"))  //ovo je novi način gdje sve radove bez datuma smatra 1.1. u godini objave           
                        //if (Number(post.godina) >= datumOd.getFullYear() && Number(post.godina) <= datumDo.getFullYear())   // ovo je stari način kad samo gleda godinu objave
                        {
                            if (kategorija == 'Sve') {
                                if (post.crosbi_kategorija === 'Radovi u časopisima' ||
                                    post.crosbi_kategorija === 'Prilog u časopisu' ||
                                    post.crosbi_kategorija === 'Radovi u zbornicima skupova' ||
                                    post.crosbi_kategorija === 'Prilog sa skupa (u zborniku)' ||
                                    post.crosbi_kategorija === 'Prilog sa skupa (u časopisu)' ||
                                    post.crosbi_kategorija === 'Knjige i poglavlja u knjigama' ||
                                    post.crosbi_kategorija === 'Prilog u knjizi' ||
                                    post.crosbi_kategorija === 'Autorska knjiga' ||
                                    post.crosbi_kategorija === 'Urednička knjiga') {
                                    return post;
                                }
                                //return post;
                            }
                            else {
                                let kat = (kategorija=='Knjige i poglavlja u knjigama'? 'knji':kategorija);
                                kat = (kat=='Prilog sa skupa (u zborniku)'? 'Prilog sa skupa (u':kat);    //ako je odabran u ddl prilog u  zborniku da hvata i prilog u časopisu i zborniku, ali ne i neobjavljen
                                if (post.crosbi_kategorija.toLowerCase().includes(kat.toLowerCase())) {
                                    //return post;

                                    //filtriranje indeksiranosti 
                                    if (post.crosbi_kategorija === 'Radovi u zbornicima skupova' ||
                                        post.crosbi_kategorija === 'Prilog sa skupa (u zborniku)' ||
                                        post.crosbi_kategorija === 'Prilog sa skupa (u časopisu)' ||
                                        post.crosbi_kategorija === 'Knjige i poglavlja u knjigama' ||
                                        post.crosbi_kategorija === 'Prilog u knjizi' ||
                                        post.crosbi_kategorija === 'Autorska knjiga' ||
                                        post.crosbi_kategorija === 'Urednička knjiga') {
                                        return post;
                                    }
                                    else {
                                        let ctInd = 0;
                                        if ((post.indeksiranost_casopisa === null || post.indeksiranost_casopisa.length ===0)&& filters.indeksiranost[4] === true) {
                                            return post;
                                        }
                                        else if (post.indeksiranost_casopisa === null) { }
                                        else {
                                            post.indeksiranost_casopisa.map((post) => {
                                                if (post === "Current Contents Connect (CCC)" && filters.indeksiranost[0] === true) {
                                                    ctInd++;
                                                }
                                                else if ((post === "Web of Science Core Collection, Science Citation Index Expanded (WoSCC-SCI-Exp)" || post === "Science Citation Index Expanded (SCI-EXP)") && filters.indeksiranost[1] === true) {
                                                    ctInd++;
                                                }
                                                else if ((post === "Web of Science Core Collection, Emerging Sources Citation Index (WoSCC-ESCI)" || post === "Emerging Sources Citation Index (ESCI)") && filters.indeksiranost[2] === true) {
                                                    ctInd++;
                                                }
                                                else if (post === "Scopus" && filters.indeksiranost[3] === true) {
                                                    ctInd++;
                                                }
                                            })
                                            console.log("ctInd = " + ctInd);
                                            if (ctInd > 0) { ctInd = 0; return post; }
                                        }
                                    }
                                    //filtriranje indeksiranosti kraj
                                }
                            }
                        }
                    }
                    else if(sDatumom==2 || (sDatumom==1 && post.datum_objave_lock==1)){


                        if (moment(post.datum_objave).format("YYYY-MM-DD") >= moment(datumOd).format("YYYY-MM-DD") && moment(post.datum_objave).format("YYYY-MM-DD") <= moment(datumDo).format("YYYY-MM-DD")) {
                            if (kategorija == 'Sve') {
                                if (post.crosbi_kategorija === 'Radovi u časopisima' ||
                                post.crosbi_kategorija === 'Prilog u časopisu' ||
                                post.crosbi_kategorija === 'Radovi u zbornicima skupova' ||
                                post.crosbi_kategorija === 'Prilog sa skupa (u zborniku)' ||
                                post.crosbi_kategorija === 'Prilog sa skupa (u časopisu)' ||
                                post.crosbi_kategorija === 'Knjige i poglavlja u knjigama' ||
                                post.crosbi_kategorija === 'Prilog u knjizi' ||
                                post.crosbi_kategorija === 'Autorska knjiga' ||
                                post.crosbi_kategorija === 'Urednička knjiga') {
                                return post;
                            }
                            //return post;
                            }
                            else {
                                let kat = (kategorija=='Knjige i poglavlja u knjigama'? 'knji':kategorija);
                                kat = (kat=='Prilog sa skupa (u zborniku)'? 'Prilog sa skupa (u':kat);    //ako je odabran u ddl prilog u  zborniku da hvata i prilog u časopisu i zborniku, ali ne i neobjavljen
                                if (post.crosbi_kategorija.toLowerCase().includes(kat.toLowerCase())) {
                                    //return post;

                                    //filtriranje indeksiranosti 
                                    if (post.crosbi_kategorija === 'Radovi u zbornicima skupova' ||
                                        post.crosbi_kategorija === 'Prilog sa skupa (u zborniku)' ||
                                        post.crosbi_kategorija === 'Prilog sa skupa (u časopisu)' ||
                                        post.crosbi_kategorija === 'Knjige i poglavlja u knjigama' ||
                                        post.crosbi_kategorija === 'Prilog u knjizi' ||
                                        post.crosbi_kategorija === 'Autorska knjiga' ||
                                        post.crosbi_kategorija === 'Urednička knjiga') {
                                        return post;
                                    }
                                    else {
                                        let ctInd = 0;
                                        if ((post.indeksiranost_casopisa === null || post.indeksiranost_casopisa.length ===0) && filters.indeksiranost[4] === true) {
                                            return post;
                                        }
                                        else if (post.indeksiranost_casopisa === null) { }
                                        else {
                                            post.indeksiranost_casopisa.map((post) => {
                                                if (post === "Current Contents Connect (CCC)" && filters.indeksiranost[0] === true) {
                                                    ctInd++;
                                                }
                                                else if ((post === "Web of Science Core Collection, Science Citation Index Expanded (WoSCC-SCI-Exp)" || post === "Science Citation Index Expanded (SCI-EXP)") && filters.indeksiranost[1] === true) {
                                                    ctInd++;
                                                }
                                                else if ((post === "Web of Science Core Collection, Emerging Sources Citation Index (WoSCC-ESCI)" || post === "Emerging Sources Citation Index (ESCI)") && filters.indeksiranost[2] === true) {
                                                    ctInd++;
                                                }
                                                else if (post === "Scopus" && filters.indeksiranost[3] === true) {
                                                    ctInd++;
                                                }
                                            })
                                            console.log("ctInd = " + ctInd);
                                            if (ctInd > 0) { ctInd = 0; return post; }
                                        }
                                    }
                                    //filtriranje indeksiranosti kraj
                                }
                            }
                        }
                    }
                }
            })/*.filter((post) => {
                if (kvartil == 0) {
                    return post;
                }
                else {
                    if (najboljiKvartil(post.autori_na_radu) == kvartil) {
                        return post;
                    }
                }
            })
            .filter((post) => {
                if (kvartilJCR == 0) {
                    return post;
                }
                else {
                    if (najboljiKvartilJCR(post.autori_na_radu) == kvartilJCR) {
                        return post;
                    }
                }
            }).filter((post) => {
                if (kvartilSJR == 0) {
                    return post;
                }
                else {
                    if (najboljiKvartilSJR(post.autori_na_radu) == kvartilSJR) {
                        return post;
                    }
                }
            })*/
                .filter((post) => {
                    if (podkategorija == 0) {
                        return post;
                    }
                    else if(podkategorija == 1){
                        if (post.crosbi_podkategorija == "Znanstveni radovi u zbornicima skupova" ||
                            post.crosbi_podkategorija == "Znanstveni i pregledni radovi" ||
                            post.crosbi_podkategorija == "Izvorni znanstveni rad" ||
                            post.crosbi_podkategorija == "izvorni znanstveni rad" ||
                            post.crosbi_podkategorija == "Prethodno priopćenje" ||
                            post.crosbi_podkategorija == "Pregledni rad (znanstveni)" ||
                            post.crosbi_podkategorija == "Kratko priopćenje"
                        ) {
                            return post;
                        }
                    }
                    else if(podkategorija == 2){
                        if (post.crosbi_podkategorija == "Stručni rad" ||
                            post.crosbi_podkategorija == "stručni rad" ||
                            post.crosbi_podkategorija == "Stručni radovi" ||
                            post.crosbi_podkategorija == "Pregledni rad (stručni)" ||
                            post.crosbi_podkategorija == "Stručni radovi u zbornicima skupova"
                        ) {
                            return post;
                        }
                    }
                    else{
                        if (post.crosbi_podkategorija != "Znanstveni radovi u zbornicima skupova" &&
                            post.crosbi_podkategorija != "Znanstveni i pregledni radovi" &&
                            post.crosbi_podkategorija != "Izvorni znanstveni rad" &&
                            post.crosbi_podkategorija != "izvorni znanstveni rad" &&
                            post.crosbi_podkategorija != "Prethodno priopćenje" &&
                            post.crosbi_podkategorija != "Pregledni rad (znanstveni)" &&
                            post.crosbi_podkategorija != "Kratko priopćenje" &&
                            post.crosbi_podkategorija != "Stručni rad" &&
                            post.crosbi_podkategorija != "stručni rad" &&
                            post.crosbi_podkategorija != "Stručni radovi" &&
                            post.crosbi_podkategorija != "Pregledni rad (stručni)" &&
                            post.crosbi_podkategorija != "Stručni radovi u zbornicima skupova"
                        ) {
                            return post;
                        }
                    }
                }).filter((post) => {
                    let ctJCRMatch = 0,ctJCIMatch = 0, ctSJRMatch = 0;
                    if (kvartilJCR.length == 0) {
                        ctJCRMatch++;                       
                    }
                    else {
                        kvartilJCR.map((selJCR) => {
                            if (najboljiKvartilJCR(post.autori_na_radu) == selJCR.value || selJCR.value === 0) {
                                ctJCRMatch++;
                            }
                        })
                    }
                    if (kvartilJCI.length == 0) {
                        ctJCIMatch++;                       
                    }
                    else {
                        kvartilJCI.map((selJCI) => {
                            if (najboljiKvartilJCI(post.autori_na_radu) == selJCI.value || selJCI.value === 0) {
                                ctJCIMatch++;
                            }
                        })
                    }
                    if (kvartilSJR.length == 0) {
                        ctSJRMatch++;
                    }
                    else {
                        kvartilSJR.map((selSJR) => {
                            if (najboljiKvartilSJR(post.autori_na_radu) == selSJR.value || selSJR.value === 0) {
                                ctSJRMatch++;
                            }
                        })
                    }

                    if (povoljnijiQ.value === 1) {
                        if (ctJCRMatch > 0 || ctJCIMatch > 0 || ctSJRMatch > 0) return post;
                    }
                    else if (povoljnijiQ.value === 2) {
                        if ((ctJCRMatch > 0 || ctJCIMatch > 0) && ctSJRMatch > 0) return post;
                    }
                    else {
                        if (ctJCRMatch > 0 && ctJCIMatch > 0 && ctSJRMatch > 0) return post;
                    }


                }).filter((post) => {
                    if (inozemniCasopis == 2) {
                        return post;
                    }
                    else {

                        if (post.inozemni == inozemniCasopis) {
                            return post;
                        }
                    }
                }).filter((post) => {
                if (sGospodarstvom == 2) {
                    return post;
                }
                else {

                    if (post.rad_sa_gospodarstvom == sGospodarstvom) {
                        return post;
                    }
                }
            }).filter((post) => {
                if (naProjektu == 2) {
                    return post;
                }
                else {
                    if (post.rad_na_projektu == naProjektu) {
                        return post;
                    }
                }
            }).filter((post) => {
                if (medjuzavodskiRad == 2) {
                    return post;
                }
                else {

                    if (post.medjuzavodski_rad == medjuzavodskiRad) {
                        return post;
                    }
                }
            }).filter((post) => {
                if (radSDrugimZnanInst == 2) {
                    return post;
                }
                else {

                    if (post.rad_s_drugim_znanstvenim_institucijama == radSDrugimZnanInst) {
                        return post;
                    }
                }
            }).filter((post) => {
                if (radSInozemnimZnan == 2) {
                    return post;
                }
                else {

                    if (post.rad_s_inozemnim_znanstvenicima == radSInozemnimZnan) {
                        return post;
                    }
                }
            }).filter((post) => {
                if (saStudentom == 2) {
                    return post;
                }
                else {
                    let flagSaStud = 0;
                    post.autori_na_radu.forEach((element) => {
                        selectedAuthors.map((selElement) => {
                            if ((element.aai === selElement.value || selElement.value == "sviRadovi") && element.rad_sa_studentom != 0) {
                                flagSaStud = element.rad_sa_studentom;
                            }
                        });
                    });
                    if (flagSaStud == saStudentom) {
                        return post;
                    }
                }
            }).filter((post) => {
                if (sDoktorandom == 2) {
                    return post;
                }
                else {
                    let flagSDok = 0;
                    post.autori_na_radu.forEach((element) => {
                        selectedAuthors.map((selElement) => {
                            if ((element.aai === selElement.value || selElement.value == "sviRadovi") && element.rad_s_doktorandom != 0) {
                                flagSDok = element.rad_s_doktorandom;
                            }
                        });
                    });
                    if (flagSDok == sDoktorandom) {
                        return post;
                    }
                }
            }).filter((post) => {
                if (otvoreniPristup == 2) {
                    return post;
                }
                else {

                    if (post.rad_u_otvorenom_pristupu == otvoreniPristup) {
                        return post;
                    }
                }
            }).filter((post) => {
                if (vanUstanove == 2) {
                    return post;
                }
                else {

                    let flagVanUstanove = 0;
                    post.autori_na_radu.forEach((element) => {
                        selectedAuthors.map((selElement) => {
                            if ((element.aai === selElement.value || selElement.value == "sviRadovi") && element.rad_van_ustanove != 0) {
                                flagVanUstanove = element.rad_van_ustanove;

                            }
                        });
                    });


                    if (flagVanUstanove == vanUstanove) {
                        return post;
                    }

                }
            }).map((post, index) => {
                ct++;
            })
        }
        return ct;
    }


    function kopirajBibliografiju(text) {

        navigator.clipboard.writeText(text);

        toast.info('Spremljeno u međuspremnik. Možete zalijepiti s CTRL+V na željeno mjesto.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autClose: 2000,
            transition: Slide
        });

    }

    function kreirajIzvjestaj() {

        /*
               var text = "IZVJEŠTAJ\n--------------\n"
              
                       text += "Odabrano je sljedeće:";
               
                       text += "\nAutori: " + filters.autori.map((e) => { return " " + e.label; });
                       text += "\nDatum od-do: " + filters.datumOd.toLocaleDateString("hr-HR") + " - " + filters.datumDo.toLocaleDateString("hr-HR");
                       text += "\nVrsta publikacije: " + filters.vrstaPublikacije.label;
                       text += "\n--------------";
               
               text += "\nPronađenih radova: " + (ctA + ctB + ctC + ctOstaliCas) + "\n\n";
       
               text += "A radova: " + ctA + "\n";
               text += "B radova: " + ctB + "\n";
               text += "Ostalih radova u časopisima: " + ctOstaliCas + "\n";
               text += "C radova: " + ctC + "\n\n";
       
               text += "Prema JCR:\n"
               text += "Q1: " + ctJCR[1] + "\n";
               text += "Q2: " + ctJCR[2] + "\n";
               text += "Q3: " + ctJCR[3] + "\n";
               text += "Q4: " + ctJCR[4] + "\n\n";
       
               text += "Prema SJR:\n"
               text += "Q1: " + ctSJR[1] + "\n";
               text += "Q2: " + ctSJR[2] + "\n";
               text += "Q3: " + ctSJR[3] + "\n";
               text += "Q4: " + ctSJR[4] + "\n\n";
               
                       text += izvjestajAradovi;
                       text += izvjestajBradovi;
                       text += izvjestajCradovi;
               
                       navigator.clipboard.writeText(text);
               
                       toast.info('Spremljeno u međuspremnik. Možete zalijepiti s CTRL+V na željeno mjesto.', {
                           position: toast.POSITION.BOTTOM_CENTER,
                           autClose: 2000,
                           transition: Slide
                       });
               */

        const doc = new Document({
            compatibility: "word2007",
            sections: [{
                properties: {},
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "IZVJEŠTAJ:",
                                bold: true,
                                size: 32,
                                font: "Ubuntu",
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Odabrano je sljedeće:",
                                bold: true,
                                size: 22,
                                font: "Arial",
                                break: 2
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Autori:",
                                bold: true,
                                size: 20,
                                font: "Arial"
                            }),
                            new TextRun({
                                text: " " + filters.autori.map((e) => { return " " + e.label; }),
                                bold: false,
                                size: 20,
                                font: "Arial"
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Datum objave (od-do):",
                                bold: true,
                                size: 20,
                                font: "Arial",
                                tab: 1
                            }),
                            new TextRun({
                                text: " " + filters.datumOd.toLocaleDateString("hr-HR") + " - " + filters.datumDo.toLocaleDateString("hr-HR"),
                                bold: false,
                                size: 20,
                                font: "Arial"
                            }),
                        ],
                    }),

                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Vrsta publikacije:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: " " + filters.vrstaPublikacije.label,
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                        ],
                    }),

                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Pronađenih radova: " + (ctA + ctB + ctC + ctKnjige + ctOstaliCas),
                                bold: true,
                                size: 22,
                                font: "Arial",
                                break: 1
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Radova u časopisima A kategorije:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: " " + ctA,
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                        ],
                    }),
                    /*new Paragraph({
                        children: [
                            new TextRun({
                                text: "Radova u časopisima B kategorije:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: " " + ctB,
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                        ],
                    }),*/
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Radova u ostalim časopisima:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: " " + ctOstaliCas,
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Radova u zbornicima skupova:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: " " + ctC,
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Knjiga i poglavlja u knjigama:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: " " + ctKnjige,
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Prema JCR - Journal Impact Factor (JIF): ",
                                bold: true,
                                size: 22,
                                font: "Arial",
                                break: 1
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Q1:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: " " + ctJCR[1],
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: "Q2:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                                break: 1
                            }),
                            new TextRun({
                                text: " " + ctJCR[2],
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: "Q3:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                                break: 1
                            }),
                            new TextRun({
                                text: " " + ctJCR[3],
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: "Q4:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                                break: 1
                            }),
                            new TextRun({
                                text: " " + ctJCR[4],
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                        ],
                    }),

                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Prema JCR - Journal Citation Indicator (JCI): ",
                                bold: true,
                                size: 22,
                                font: "Arial",
                                break: 1
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Q1:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: " " + ctJCI[1],
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: "Q2:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                                break: 1
                            }),
                            new TextRun({
                                text: " " + ctJCI[2],
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: "Q3:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                                break: 1
                            }),
                            new TextRun({
                                text: " " + ctJCI[3],
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: "Q4:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                                break: 1
                            }),
                            new TextRun({
                                text: " " + ctJCI[4],
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                        ],
                    }),

                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Prema SJR - SCImago Journal Rank Indicator: ",
                                bold: true,
                                size: 22,
                                font: "Arial",
                                break: 1
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Q1:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: " " + ctSJR[1],
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: "Q2:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                                break: 1
                            }),
                            new TextRun({
                                text: " " + ctSJR[2],
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: "Q3:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                                break: 1
                            }),
                            new TextRun({
                                text: " " + ctSJR[3],
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                            new TextRun({
                                text: "Q4:",
                                bold: true,
                                size: 20,
                                font: "Arial",
                                break: 1
                            }),
                            new TextRun({
                                text: " " + ctSJR[4],
                                bold: false,
                                size: 20,
                                font: "Arial",
                            }),
                        ],
                    }),


                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Radovi u časopisima A kategorije:\n\n",
                                bold: true,
                                size: 22,
                                font: "Arial",
                                break: 2
                            }),
                        ],
                    }),
                    new Paragraph({
                        children:
                            izvjestajAradovi.split("\n").map(line => { return new TextRun({ text: line, size: 20, font: 'Arial', break: 1 }) })
                        ,
                    }),
                    /*new Paragraph({
                        children: [
                            new TextRun({
                                text: "Radovi u časopisima B kategorije:\n\n",
                                bold: true,
                                size: 22,
                                font: "Arial",
                                break: 1
                            }),
                        ],
                    }),
                    new Paragraph({
                        children:
                            izvjestajBradovi.split("\n").map(line => { return new TextRun({ text: line, size: 20, font: 'Arial', break: 2 }) })
                        ,
                    }),*/
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Radovi u ostalim časopisima:\n\n",
                                bold: true,
                                size: 22,
                                font: "Arial",
                                break: 1
                            }),
                        ],
                    }),
                    new Paragraph({
                        children:
                            izvjestajOstaliCasRadovi.split("\n").map(line => { return new TextRun({ text: line, size: 20, font: 'Arial', break: 1 }) })
                        ,
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Radovi u zbornicima skupova:\n\n",
                                bold: true,
                                size: 22,
                                font: "Arial",
                                break: 1
                            }),
                        ],
                    }),
                    new Paragraph({
                        children:
                            izvjestajCradovi.split("\n").map(line => { return new TextRun({ text: line, size: 20, font: 'Arial', break: 1 }) })
                        ,
                    }),

                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Knjige i poglavlja u knjigama:\n\n",
                                bold: true,
                                size: 22,
                                font: "Arial",
                                break: 1
                            }),
                        ],
                    }),
                    new Paragraph({
                        children:
                            izvjestajKnjige.split("\n").map(line => { return new TextRun({ text: line, size: 20, font: 'Arial', break: 1 }) })
                        ,
                    }),
                ],
            }],
        });



        Packer.toBlob(doc).then(blob => {
            saveAs(blob, "Izvještaj.docx");
        });

    }

    const [korisnik, setKorisnik] = useState(props.korisnik);
    const [dropDownKor, setDropDownKor] = useState(props.dropDownKor);

    //const [data, setData] = useState(props.korisnik == 'Anonimni korisnik' ? 'sviRadovi' : 'radovi/' + props.korisnik);


    let initAutori = "radovi/" + props.korisnik;

    const navigate = useNavigate();

    console.log("redux autori u radovima: ", filters.autori);
    console.log("redux u radovima - length: ", filters.autori.length);
    console.log("props.korisnik kod refresha: ", props.korisnik);
    if (filters.autori.length === 0) {
        //if (filters.autori.value != null) {
        if (props.korisnik != "Anonimni korisnik") {
            initAutori = "radovi/" + props.korisnik;
            navigate('/');
            filters.odabraniSvi=false;
        }
        else {
            filters.odabraniSvi=true;
            initAutori = "sviRadoviOdDo/"+ filters.datumOd.getFullYear() +"/" + filters.datumDo.getFullYear();

        }


    }
    else if (filters.autori.length === 1) {
        
        if (filters.autori[0].value === "sviRadovi"){
            //initAutori = filters.autori[0].value
            filters.odabraniSvi=true;
            initAutori = "sviRadoviOdDo/"+ filters.datumOd.getFullYear() +"/" + filters.datumDo.getFullYear();
        }
        else{
            
            initAutori = "radovi/" + filters.autori[0].value;
            filters.odabraniSvi=false;
        }

    }
    else {
        let multiAutor = "";
        let odabraniSvi = false;
        filters.autori.map((e) => {
            if (e.value === "sviRadovi")                
                odabraniSvi = true;
            if (multiAutor === "")
                multiAutor += e.value;
            else
                multiAutor += "," + e.value;
        })
        initAutori = "radovi-vise-autora/" + multiAutor;
        if (odabraniSvi) {
            //initAutori = "sviRadovi";
            filters.odabraniSvi=true;
            initAutori = "sviRadoviOdDo/"+ filters.datumOd.getFullYear() +"/" + filters.datumDo.getFullYear();
        }
        else filters.odabraniSvi=false;
    }

    console.log("usao u postavljanje ", initAutori);

    const [data, setData] = useState(initAutori);


    //setData(initAutori);


    let i = 0;
    console.log("INIT AUTORI: ", initAutori, data, i++);


    const childToParent = (childdata) => {
        /*setData(childdata);
        setFilterCount(ct);
        api = data;*/
        
        if (childdata.length === 0) {
            setData("sviRadoviOdDo/"+ filters.datumOd.getFullYear() +"/" + filters.datumDo.getFullYear());
            filters.odabraniSvi=true;
        }
        else if (childdata.length === 1) {
            if (childdata[0].value === "sviRadovi"){
                //setData(childdata[0].value)
                setData("sviRadoviOdDo/"+ filters.datumOd.getFullYear() +"/" + filters.datumDo.getFullYear());
                filters.odabraniSvi=true;
            }
            else{
                setData('radovi/' + childdata[0].value);
                filters.odabraniSvi=false;
            }
        }
        else {
            let multiAutor = "";
            let odabraniSvi = false;
            childdata.map((e) => {
                if (e.value === "sviRadovi")
                    odabraniSvi = true;
                    filters.odabraniSvi=true;
                if (multiAutor === "")
                    multiAutor += e.value;
                else
                    multiAutor += "," + e.value;
            })
            setData('radovi-vise-autora/' + multiAutor);
            if (odabraniSvi) {
                setData("sviRadoviOdDo/"+ filters.datumOd.getFullYear() +"/" + filters.datumDo.getFullYear());
                toast.warning('Odabrali ste sve autore i pojedinačnog autora. Prikazuju se podaci za sve autore.', {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autClose: 2000,
                    transition: Slide
                });

            }
            else filters.odabraniSvi=false;
            console.log("multiautor: ", data);
        }


        setSelectedAuthors(
            childdata.map((e) => {
                //console.log("radovi su dobili selektirane:", e.value)
                return { value: e.value, label: e.prezime + " " + e.ime }
            })
        )

        console.log("radovi su dobili selektirane:", selectedAuthors)

    }

    let initKategorija = filters.vrstaPublikacije.label;
    console.log("redux kategorija u radovima: ", filters.vrstaPublikacije);
    const [kategorija, setKategorija] = useState(initKategorija);
    const childToParentKat = (childdata) => {
        setKategorija(childdata);
        setFilterCount(ct);
    }

    let initPodkategorija = filters.podkategorijaPublikacije.value;
    const [podkategorija, setPodkategorija] = useState(initPodkategorija);
    const childToParentPodkat = (childdata) => {
        setPodkategorija(childdata);
        setFilterCount(ct);
    }
    
    const [indeksiranost, setIndeksiranost] = useState(filters.indeksiranost)
    const childToParentInd = (childdata) => {
        setIndeksiranost(childdata);
        setFilterCount(ct);
        console.log("ispisujem stanmje filtera indeksiranosti:" + indeksiranost)
    }

    let initKvartil = filters.kvartil.value;
    const [kvartil, setKvartil] = useState(initKvartil);

    //let initKvartilJCR = filters.kvartilJCR;
    let initKvartilJCR = filters.kvartilJCRMulti;
    const [kvartilJCR, setKvartilJCR] = useState(initKvartilJCR);

    let initKvartilJCI = filters.kvartilJCIMulti;
    const [kvartilJCI, setKvartilJCI] = useState(initKvartilJCI);

    //let initKvartilSJR = filters.kvartilSJR.value;
    let initKvartilSJR = filters.kvartilSJRMulti;
    const [kvartilSJR, setKvartilSJR] = useState(initKvartilSJR);

    const[povoljnijiQ, setPovoljnijiQ] = useState(filters.povoljnijiQ);

    const childToParentQ = (childdata) => {
        setKvartil(childdata);
        setFilterCount(ct);
    }
    const childToParentQJCR = (childdata) => {
        setKvartilJCR(childdata);
        setFilterCount(ct);
    }
    const childToParentQJCI = (childdata) => {
        setKvartilJCI(childdata);
        setFilterCount(ct);
    }
    const childToParentQSJR = (childdata) => {
        setKvartilSJR(childdata);
        setFilterCount(ct);
    }

    const childToParentPovoljnijiQ = (childdata) => {
        setPovoljnijiQ(childdata);   
    }


    let datumPrije5 = new Date().getFullYear() - 5 + "-" + new Date().toLocaleString("en-US", { month: "2-digit" }) + "-" + new Date().toLocaleString("en-US", { day: '2-digit' })

    let initDatumOd = filters.datumOd;
    const [datumOd, setDatumOd] = useState(initDatumOd);
    const childToParentDatumOd = (childdata) => {
        setDatumOd(childdata);
        setFilterCount(ct);
        if(filters.odabraniSvi===true)setData("sviRadoviOdDo/"+ filters.datumOd.getFullYear() +"/" + filters.datumDo.getFullYear());
        console.log("pokupio od:" + childdata);
    }

    let initDatumDo = filters.datumDo;
    const [datumDo, setDatumDo] = useState(initDatumDo);
    const childToParentDatumDo = (childdata) => {
        setDatumDo(childdata);
        setFilterCount(ct);
        if(filters.odabraniSvi===true)setData("sviRadoviOdDo/"+ filters.datumOd.getFullYear() +"/" + filters.datumDo.getFullYear());
        console.log("pokupio do:" + childdata);
    }

    
    let initSDatumom = filters.radSDatumom.value
    const [sDatumom, setSDatumom] = useState(initSDatumom);
    const childToParentSDatumom = (childdata) => {
        setSDatumom(childdata);
        setFilterCount(ct);
    }


    let initSaStudentom = filters.radSaStudentom.value
    const [saStudentom, setSaStudentom] = useState(initSaStudentom);
    const childToParentStud = (childdata) => {
        setSaStudentom(childdata);
        setFilterCount(ct);
    }

    let initSDoktorandom = filters.radSDoktorandom.value
    const [sDoktorandom, setSDoktorandom] = useState(initSDoktorandom);
    const childToParentSDoktorandom = (childdata) => {
        setSDoktorandom(childdata);
        setFilterCount(ct);
    }

    let initSGospodarstvom = filters.radSGospodarstvom.value
    const [sGospodarstvom, setSGospodarstvom] = useState(initSGospodarstvom);
    const childToParentGosp = (childdata) => {
        setSGospodarstvom(childdata);
        setFilterCount(ct);
    }

    let initNaProjektu = filters.radNaProjektu.value
    const [naProjektu, setNaProjektu] = useState(initNaProjektu);
    const childToParentProj = (childdata) => {
        setNaProjektu(childdata);
        setFilterCount(ct);
    }

    let initMedjuzavodski = filters.medjuzavodskiRad.value
    const [medjuzavodskiRad, setMedjuzavodskiRad] = useState(initMedjuzavodski);
    const childToParentMedjuzavodski = (childdata) => {
        setMedjuzavodskiRad(childdata);
        setFilterCount(ct);
    }

    let initRadSDrugimZnanInst = filters.radSDrugimZnanInst.value
    const [radSDrugimZnanInst, setRadSDrugimZnanInst] = useState(initRadSDrugimZnanInst);
    const childToParentRadSDrugimZnanInst = (childdata) => {
        setRadSDrugimZnanInst(childdata);
        setFilterCount(ct);
    }

    let initRadSInozemnimZnan = filters.radSInozemnimZnan.value
    const [radSInozemnimZnan, setRadSInozemnimZnan] = useState(initRadSInozemnimZnan);
    const childToParentRadSInozemnimZnan = (childdata) => {
        setRadSInozemnimZnan(childdata);
        setFilterCount(ct);
    }

    let initInozemniCasopis = filters.inozemniCasopis.value
    const [inozemniCasopis, setInozemniCasopis] = useState(initInozemniCasopis);
    const childToParentInozemniCas = (childdata) => {
        setInozemniCasopis(childdata);
        setFilterCount(ct);
    }

    
    let initOtvoreniPristup = filters.radOtvoreniPristup.value
    const [otvoreniPristup, setOtvoreniPristup] = useState(initOtvoreniPristup);
    const childToParentOtvoreniPristup = (childdata) => {
        setOtvoreniPristup(childdata);
        setFilterCount(ct);
    }

    let initVanUstanove = filters.vanUstanove.value
    const [vanUstanove, setVanUstanove] = useState(initVanUstanove);
    const childToParentVanUstanove = (childdata) => {
        setVanUstanove(childdata);
        setFilterCount(ct);
    }

    const [repo, setRepo] = React.useState(null);
    const [tempRepo, setTempRepo] = React.useState(null);


    const handleBeforeUnload = () => {
        console.log('Page is about to refresh or close!')
        // other lines of code
      }



    React.useEffect(() => {
        setLoading(true);
        filters.loading = true;


        console.log("useeffect daje data",data);
        console.log("useeffect daje initautori",initAutori);
                
        axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/' + data).then((response) => {
            setRepo(response.data);
            setLoading(false);
            filters.loading = false;
            /* setRepo(
                 {response.data.map(value=>{
     
                         return value.autori_na_radu
                 }).filter(item => !selectedAuthors.includes(item.aai))            
             }
             )*/

            // console.log("effect " + response.data.filter(item => {if(!selectedAuthors.includes(item.aai))return item}));
        });

        


    }, [data]);

    if (!repo) return null;





    function najboljiKvartil(values) {
        let najQ = 0;
        /*axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-id/' + id).then((response) => {
            response.data.data[0].autori_na_radu.map((post, index) => {
                return post.kvartili_autora_na_radu;
            }).map((post, index) =>  {            //return post;  
                if(post.length>0)najQ=post.kvartil;
                
            })
        });*/

        let sviKvartili = [];

        console.log("post.autori_na_radu 1712", values)
        console.log("filters.autori", filters.autori)
        console.log("selectedAuthors", selectedAuthors)


        values.forEach(element => {
            element.kvartili_autora_na_radu.forEach(value => {

                if (value.naziv_kategorizacije === "jcr" || value.naziv_kategorizacije === "sjr") {
                    selectedAuthors.forEach(author => {

                        if (element.aai === author.value) {
                            if (value.kvartil != null) {
                                if (najQ == 0) najQ = value.kvartil;
                                else if (value.kvartil < najQ && najQ > 0) najQ = value.kvartil;
                            }
                        }
                        else if (author.value === 'sviRadovi') {
                            if (value.kvartil != null) {
                                if (najQ == 0) najQ = value.kvartil;
                                else if (value.kvartil < najQ && najQ > 0) najQ = value.kvartil;
                            }
                        }
                    })
                }
            })
        })

        return najQ;
    }

    function najboljiKvartilJCR(values) {
        let najQ = 0;
        /*axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-id/' + id).then((response) => {
            response.data.data[0].autori_na_radu.map((post, index) => {
                return post.kvartili_autora_na_radu;
            }).map((post, index) =>  {            //return post;  
                if(post.length>0)najQ=post.kvartil;
                
            })
        });*/

        let sviKvartili = [];

        console.log("post.autori_na_radu 1712", values)
        console.log("filters.autori", filters.autori)
        console.log("selectedAuthors", selectedAuthors)


        values.forEach(element => {
            element.kvartili_autora_na_radu.forEach(value => {


                if (value.naziv_kategorizacije === "jcr") {
                    selectedAuthors.forEach(author => {

                        if (element.aai === author.value) {
                            if (value.kvartil != null) {
                                if (najQ == 0) najQ = value.kvartil;
                                else if (value.kvartil < najQ && najQ > 0) najQ = value.kvartil;
                            }
                        }
                        else if (author.value === 'sviRadovi') {
                            if (value.kvartil != null) {
                                if (najQ == 0) najQ = value.kvartil;
                                else if (value.kvartil < najQ && najQ > 0) najQ = value.kvartil;
                            }
                        }
                    })
                }
            })
        })

        return najQ;
    }

    function najboljiKvartilJCI(values) {
        let najQ = 0;
        let sviKvartili = [];

        values.forEach(element => {
            element.kvartili_autora_na_radu.forEach(value => {

                if (value.naziv_kategorizacije === "jci") {
                    selectedAuthors.forEach(author => {

                        if (element.aai === author.value) {
                            if (value.kvartil != null) {
                                if (najQ == 0) najQ = value.kvartil;
                                else if (value.kvartil < najQ && najQ > 0) najQ = value.kvartil;
                            }
                        }
                        else if (author.value === 'sviRadovi') {
                            if (value.kvartil != null) {
                                if (najQ == 0) najQ = value.kvartil;
                                else if (value.kvartil < najQ && najQ > 0) najQ = value.kvartil;
                            }
                        }
                    })
                }
            })
        })

        return najQ;
    }

    function najboljiKvartilSJR(values) {
        let najQ = 0;
        /*axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-id/' + id).then((response) => {
            response.data.data[0].autori_na_radu.map((post, index) => {
                return post.kvartili_autora_na_radu;
            }).map((post, index) =>  {            //return post;  
                if(post.length>0)najQ=post.kvartil;
                
            })
        });*/

        let sviKvartili = [];

        console.log("post.autori_na_radu 1712", values)
        console.log("filters.autori", filters.autori)
        console.log("selectedAuthors", selectedAuthors)


        values.forEach(element => {
            element.kvartili_autora_na_radu.forEach(value => {

                if (value.naziv_kategorizacije === "sjr") {
                    selectedAuthors.forEach(author => {

                        if (element.aai === author.value) {
                            if (value.kvartil != null) {
                                if (najQ == 0) najQ = value.kvartil;
                                else if (value.kvartil < najQ && najQ > 0) najQ = value.kvartil;
                            }
                        }
                        else if (author.value === 'sviRadovi') {
                            if (value.kvartil != null) {
                                if (najQ == 0) najQ = value.kvartil;
                                else if (value.kvartil < najQ && najQ > 0) najQ = value.kvartil;
                            }
                        }
                    })
                }
            })
        })

        return najQ;
    }

    return (
        

        <div className="row">
            {/*<div ref={App.myContainer}>
                
            </div>*/}
            <div className="col-md-3 border-right padding15">
                <Filteri
                    dropDownKor={dropDownKor}
                    korisnik={korisnik}
                    childToParent={childToParent}
                    childToParentKat={childToParentKat}
                    childToParentPodkat={childToParentPodkat}
                    childToParentInd={childToParentInd}
                    childToParentQ={childToParentQ}
                    childToParentQJCR={childToParentQJCR}
                    childToParentQJCI={childToParentQJCI}
                    childToParentQSJR={childToParentQSJR}
                    childToParentPovoljnijiQ={childToParentPovoljnijiQ}
                    childToParentInozemniCas={childToParentInozemniCas}
                    childToParentDatumOd={childToParentDatumOd}
                    childToParentDatumDo={childToParentDatumDo}
                    childToParentSDatumom={childToParentSDatumom}
                    childToParentStud={childToParentStud}
                    childToParentSDoktorandom={childToParentSDoktorandom}
                    childToParentGosp={childToParentGosp}
                    childToParentProj={childToParentProj}
                    childToParentMedjuzavodski={childToParentMedjuzavodski}
                    childToParentRadSDrugimZnanInst={childToParentRadSDrugimZnanInst}
                    childToParentRadSInozemnimZnan={childToParentRadSInozemnimZnan}
                    childToParentOtvoreniPristup={childToParentOtvoreniPristup}
                    childToParentVanUstanove={childToParentVanUstanove}
                />
            </div>
            {loading ? (
                /* <Spinner animation="border" role="status" classname="spinner">
                    <span className="visually-hidden">Loading...</span>
                 </Spinner>*/
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div className="col-md-9 padding15">

                    <div className="row">
                        <div className="col-md-3">
                            <p className="ubuntu-gray">Broj pronađenih radova:

                                <span id="prebroji" className="ubuntu-dark-cond fw-bold" >
                                    &nbsp;
                                    {prebrojiFiltrirano()}
                                </span>
                                {/*prebrojiFiltrirano() === 1 ? "rad" : (prebrojiFiltrirano() < 4 ? "rada" : "radova")*/}.&nbsp;&nbsp;&nbsp;

                                {/*<button id="getAlertCopy" className="btn-simple" onClick={() => { navigator.clipboard.writeText(textToCopyCrosbi) }}>
                        Kopiraj sve
                        <i className="fa fa-copy"></i>
                    </button>*/}
                            </p>
                        </div>
                        <div className="col-md-2">
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="btn btn-secondary ubuntu-light-cond" size="sm">
                                    Kopiraj sve
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => { kopirajBibliografiju(textToCopyCrosbi) }}>CROSBI</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { kopirajBibliografiju(textToCopyIEEE) }}>IEEE</Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="col-md-2 mobile-right">
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="btn btn-secondary ubuntu-light-cond" size="sm">
                                    Izvještaji
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => { kreirajIzvjestaj() }}>.docx</Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                        </div>{(filters.autori.length===1 && filters.autori[0].value===props.korisnik)?
                            <div className="col-md-4 mobile-right">
                                {/*<a className="ubuntu-link5" href="/radovi" onClick={() => {osvjeziSCROSBI()}}>
                                    <span>Osvježi podatke s CROSBI-ja </span><FontAwesomeIcon icon="retweet" />
                </a>*/}
                                
                                <button className="ubuntu-light-cond ubuntu-link5"  onClick={() => {osvjeziSCROSBI()}}>
                                    Osvježi podatke s CROSBI-ja <FontAwesomeIcon icon="retweet" />
                                </button> </div>:<div/>
                                }

                           
                    </div>

                    {/*<div id="alertCopy" className="alert alert-warning text-center alert-dismissible fade show ubuntu-dark padding-top-bottom" role="alert"
                    style="display:none">
                    Kopirano u međuspremnik. Koristiti Ctrl+V za umetanje.
                    <button type="button" id="closeAlertCopy" className="close" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
                </div>*/}



                    {

                        repo.data.filter((post) => {
                            //console.log("filtrirano po autorima:", post);
                            if ((post.naslov_en.toLowerCase().includes(props.searchterm.toLowerCase()) || post.autori.toLowerCase().includes(props.searchterm.toLowerCase()))&& post.duplikat==0 && filters.autori.length!=0) {

                                if ((post.datum_objave === "0000-00-00" || post.datum_objave_lock==0) && (sDatumom==2 || sDatumom==0)) {
                                    // prisilno podešavam da radovi bez datuma budu 1.1. u godini objave
                                    let prisilniDatum = post.godina + "-01-01"    
                                    //console.log("Prisilni datum:",prisilniDatum,post.datum_objave, post.naslov_en)
                                    if (moment(prisilniDatum).format("YYYY-MM-DD") >= moment(datumOd).format("YYYY-MM-DD") && moment(prisilniDatum).format("YYYY-MM-DD") <= moment(datumDo).format("YYYY-MM-DD"))  //ovo je novi način gdje sve radove bez datuma smatra 1.1. u godini objave           
                                    // if (Number(post.godina) >= datumOd.getFullYear() && Number(post.godina) <= datumDo.getFullYear())  // ovo je stari način kad samo gleda godinu objave
                                     {
                                        console.log("sastud:" + saStudentom + "   post: " + post.rad_sa_studentom);
                                        /* if (saStudentom == 2 || post.rad_sa_student== saStudentom) */
                                        if (kategorija == 'Sve') {
                                            let flagInd = "";
                                            if (post.crosbi_kategorija === 'Radovi u zbornicima skupova' || post.crosbi_kategorija ==='Prilog sa skupa (u zborniku)' || post.crosbi_kategorija ==='Prilog sa skupa (u časopisu)') {
                                                izvjestajCradovi += post.citation_en;
                                                izvjestajCradovi += "\n\n";
                                                ctC++     
                                                return post;                                           
                                            }
                                            else if (post.crosbi_kategorija === 'Knjige i poglavlja u knjigama' ||
                                                post.crosbi_kategorija === 'Prilog u knjizi' ||
                                                post.crosbi_kategorija === 'Autorska knjiga' ||
                                                post.crosbi_kategorija === 'Urednička knjiga') {
                                                izvjestajKnjige += post.citation_en;
                                                izvjestajKnjige += "\n\n";
                                                ctKnjige++
                                                return post;
                                            }
                                            else if(post.crosbi_kategorija === 'Radovi u časopisima' || post.crosbi_kategorija === 'Prilog u časopisu') {
                                                let ctInd = 0;
                                                if (post.indeksiranost_casopisa === null || post.indeksiranost_casopisa.length ===0) {
                                                    izvjestajOstaliCasRadovi += post.citation_en;
                                                    izvjestajOstaliCasRadovi += "\n\n";
                                                    ctOstaliCas++   
                                                }
                                                else {
                                                    post.indeksiranost_casopisa.map((post) => {
                                                        if (post === "Current Contents Connect (CCC)") {
                                                            ctInd++;
                                                            flagInd = "a";
                                                        }
                                                        else if (post === "Web of Science Core Collection, Science Citation Index Expanded (WoSCC-SCI-Exp)" || post === "Science Citation Index Expanded (SCI-EXP)") {
                                                            ctInd++;
                                                            flagInd = "a";
                                                        }
                                                        else if (post === "Web of Science Core Collection, Emerging Sources Citation Index (WoSCC-ESCI)" || post === "Emerging Sources Citation Index (ESCI)") {
                                                            ctInd++;
                                                            flagInd = "a";
                                                        }
                                                        else if (post === "Scopus") {
                                                            if(flagInd!="a") 
                                                                flagInd = "a"; //po novom zakonu i scopus je A kategorija
                                                            
                                                            //flagInd = "b";
                                                            ctInd++;
                                                        }
                                                    })

                                                    if (ctInd > 0) {
                                                        ctInd = 0;
                                                        if (flagInd === "a") {
                                                            izvjestajAradovi += post.citation_en;
                                                            let najJCR = najboljiKvartilJCR(post.autori_na_radu);
                                                            let najJCI = najboljiKvartilJCI(post.autori_na_radu);
                                                            let najSJR = najboljiKvartilSJR(post.autori_na_radu);
                                                            if(najJCR!=0)izvjestajAradovi += "   [JCR JIF Q" + najJCR + "]";
                                                            if(najJCI!=0)izvjestajAradovi += "   [JCR JCI Q" + najJCI + "]";
                                                            if(najSJR!=0)izvjestajAradovi += "   [SJR Q" + najSJR + "]";
                                                            if(post.impact_factor_casopisa!=0)izvjestajAradovi += "   JIF=" + post.impact_factor_casopisa;
                                                            if(post.jci_casopisa!=0)izvjestajAradovi += "   JCI=" + post.jci_casopisa;
                                                            izvjestajAradovi += "\n\n";
                                                            ctA++;
                                                        }
                                                        else if (flagInd === "b") {
                                                            izvjestajBradovi += post.citation_en;
                                                            izvjestajBradovi += "\n\n";
                                                            ctB++
                                                        }                                                        
                                                    }
                                                }
                                                return post;
                                            }
                                            //return post;
                                        }
                                        else {

                                            let kat = (kategorija=='Knjige i poglavlja u knjigama'? 'knji':kategorija);
                                            kat = (kat=='Prilog sa skupa (u zborniku)'? 'Prilog sa skupa (u':kat);    //ako je odabran u ddl prilog u  zborniku da hvatai i prilog u časopisu i zborniku ali ne i neobjavljen
                                            if (post.crosbi_kategorija.toLowerCase().includes(kat.toLowerCase())) {
                                                //return post;
                                                let flagInd = "";

                                                //filtriranje indeksiranosti 
                                                if (post.crosbi_kategorija === 'Radovi u zbornicima skupova'|| post.crosbi_kategorija ==='Prilog sa skupa (u zborniku)'|| post.crosbi_kategorija ==='Prilog sa skupa (u časopisu)') {
                                                    //flagInd = "c";
                                                    izvjestajCradovi += post.citation_en;
                                                    izvjestajCradovi += "\n\n";
                                                    ctC++
                                                    console.log("konf:", ctC);
                                                    return post;
                                                }
                                                else if (post.crosbi_kategorija === 'Knjige i poglavlja u knjigama' ||
                                                    post.crosbi_kategorija === 'Prilog u knjizi' ||
                                                    post.crosbi_kategorija === 'Autorska knjiga' ||
                                                    post.crosbi_kategorija === 'Urednička knjiga') {
                                                    izvjestajKnjige += post.citation_en;
                                                    izvjestajKnjige += "\n\n";
                                                    ctKnjige++
                                                    return post;
                                                }
                                                else {
                                                    let ctInd = 0;
                                                    
                                                    if (post.indeksiranost_casopisa === null || post.indeksiranost_casopisa.length ===0) {    
                                                        //console.log("indeksiranost1712-2:", post.indeksiranost_casopisa.length);
                                                            if (filters.indeksiranost[4] === true) {
                                                                //flagInd = "o";
                                                                izvjestajOstaliCasRadovi += post.citation_en;
                                                                izvjestajOstaliCasRadovi += "\n\n";
                                                                ctOstaliCas++
                                                                return post;
                                                            }                                                       
                                                    }                                                    
                                                    else {
                                                        flagInd="";
                                                        post.indeksiranost_casopisa.map((post) => {
                                                            if (post === "Current Contents Connect (CCC)"){ 
                                                                flagInd = "a";
                                                                console.log("flagInd! = " + flagInd);
                                                                if(filters.indeksiranost[0] === true) {
                                                                    ctInd++;                                     
                                                                }
                                                            }
                                                            else if (post === "Web of Science Core Collection, Science Citation Index Expanded (WoSCC-SCI-Exp)" || post === "Science Citation Index Expanded (SCI-EXP)") {
                                                                flagInd = "a";
                                                                if (filters.indeksiranost[1] === true) {
                                                                    ctInd++;

                                                                }
                                                            }
                                                            else if (post === "Web of Science Core Collection, Emerging Sources Citation Index (WoSCC-ESCI)" || post === "Emerging Sources Citation Index (ESCI)") {
                                                                flagInd = "a";
                                                                if (filters.indeksiranost[2] === true) {
                                                                    ctInd++;
                                                                }
                                                            }
                                                            else if (post === "Scopus") {
                                                                if(flagInd!="a")flagInd = "a";  //po novom zakonu i scopus je A kategorija
                                                                if (filters.indeksiranost[3] === true) {
                                                                    ctInd++;

                                                                }
                                                            }
                                                        })

                                                        console.log("flagInd2 = " + flagInd);
                                                        console.log("ctInd2 = " + ctInd);


                                                        if (ctInd > 0) {
                                                            ctInd = 0;

                                                            if (flagInd === "a") {
                                                                izvjestajAradovi += post.citation_en;
                                                                let najJCR = najboljiKvartilJCR(post.autori_na_radu);
                                                                let najJCI = najboljiKvartilJCI(post.autori_na_radu);
                                                                let najSJR = najboljiKvartilSJR(post.autori_na_radu);
                                                                if(najJCR!=0)izvjestajAradovi += "   [JCR JIF Q" + najJCR + "]";
                                                                if(najJCI!=0)izvjestajAradovi += "   [JCR JCI Q" + najJCI + "]";
                                                                if(najSJR!=0)izvjestajAradovi += "   [SJR Q" + najSJR + "]";
                                                                if(post.impact_factor_casopisa!=0)izvjestajAradovi += "   JIF=" + post.impact_factor_casopisa;
                                                                if(post.jci_casopisa!=0)izvjestajAradovi += "   JCI=" + post.jci_casopisa;
                                                                izvjestajAradovi += "\n\n";
                                                                ctA++;
                                                            }
                                                            else if (flagInd === "b") {
                                                                izvjestajBradovi += post.citation_en;
                                                                izvjestajBradovi += "\n\n";
                                                                ctB++
                                                            }
                                                            

                                                            return post;
                                                        }
                                                        
                                                    }
                                                }
                                                



                                                //filtriranje indeksiranosti kraj
                                            }
                                        }
                                    }
                                }
                                else if(sDatumom==2 || (sDatumom==1 && post.datum_objave_lock==1)) {

                                    /*if (Number(post.godina) >= datumOd.getFullYear() && Number(post.godina) <= datumDo.getFullYear())*/
                                    if (moment(post.datum_objave).format("YYYY-MM-DD") >= moment(datumOd).format("YYYY-MM-DD") && moment(post.datum_objave).format("YYYY-MM-DD") <= moment(datumDo).format("YYYY-MM-DD")) {
                                        console.log("sastud:" + saStudentom + "   post: " + post.rad_sa_studentom);
                                        /* if (saStudentom == 2 || post.rad_sa_student== saStudentom) */

                                        if (kategorija == 'Sve') {
                                            let flagInd = "";
                                            if (post.crosbi_kategorija === 'Radovi u zbornicima skupova' || post.crosbi_kategorija ==='Prilog sa skupa (u zborniku)'|| post.crosbi_kategorija ==='Prilog sa skupa (u časopisu)') {
                                                izvjestajCradovi += post.citation_en;
                                                izvjestajCradovi += "\n\n";
                                                ctC++;
                                                return post;                                             
                                            }
                                            else if (post.crosbi_kategorija === 'Knjige i poglavlja u knjigama' ||
                                                post.crosbi_kategorija === 'Prilog u knjizi' ||
                                                post.crosbi_kategorija === 'Autorska knjiga' ||
                                                post.crosbi_kategorija === 'Urednička knjiga') {
                                                izvjestajKnjige += post.citation_en;
                                                izvjestajKnjige += "\n\n";
                                                ctKnjige++
                                                return post;
                                            }
                                            else if(post.crosbi_kategorija === 'Radovi u časopisima' || post.crosbi_kategorija === 'Prilog u časopisu'){
                                                let ctInd = 0;
                                                if (post.indeksiranost_casopisa === null || post.indeksiranost_casopisa.length ===0) {
                                                    izvjestajOstaliCasRadovi += post.citation_en;
                                                    izvjestajOstaliCasRadovi += "\n\n";
                                                    ctOstaliCas++                                                    
                                                }
                                                else {
                                                    post.indeksiranost_casopisa.map((post) => {
                                                        if (post === "Current Contents Connect (CCC)") {
                                                            ctInd++;
                                                            flagInd = "a";
                                                        }
                                                        else if (post === "Web of Science Core Collection, Science Citation Index Expanded (WoSCC-SCI-Exp)" || post === "Science Citation Index Expanded (SCI-EXP)") {
                                                            ctInd++;
                                                            flagInd = "a";
                                                        }
                                                        else if (post === "Web of Science Core Collection, Emerging Sources Citation Index (WoSCC-ESCI)" || post === "Emerging Sources Citation Index (ESCI)") {
                                                            ctInd++;
                                                            flagInd = "a";
                                                        }
                                                        else if (post === "Scopus") {
                                                            if(flagInd!="a")flagInd = "a";//po novom zakonu i scopus je A kategorija
                                                            //flagInd = "b";
                                                            ctInd++;                                                            
                                                        }
                                                    })

                                                    if (ctInd > 0) {
                                                        ctInd = 0;
                                                        if (flagInd === "a") {
                                                            izvjestajAradovi += post.citation_en;
                                                            let najJCR = najboljiKvartilJCR(post.autori_na_radu);
                                                            let najJCI = najboljiKvartilJCI(post.autori_na_radu);
                                                            let najSJR = najboljiKvartilSJR(post.autori_na_radu);
                                                            if(najJCR!=0)izvjestajAradovi += "   [JCR JIF Q" + najJCR + "]";
                                                            if(najJCI!=0)izvjestajAradovi += "   [JCR JCI Q" + najJCI + "]";
                                                            if(najSJR!=0)izvjestajAradovi += "   [SJR Q" + najSJR + "]";
                                                            if(post.impact_factor_casopisa!=0)izvjestajAradovi += "   JIF=" + post.impact_factor_casopisa;
                                                            if(post.jci_casopisa!=0)izvjestajAradovi += "   JCI=" + post.jci_casopisa;
                                                            izvjestajAradovi += "\n\n";
                                                            ctA++;
                                                        }
                                                        else if (flagInd === "b") {
                                                            izvjestajBradovi += post.citation_en;
                                                            izvjestajBradovi += "\n\n";
                                                            ctB++
                                                        }                                                        
                                                    }
                                                }
                                                return post;
                                            }
                                            //return post;
                                        }
                                        else {
                                            let kat = (kategorija=='Knjige i poglavlja u knjigama'? 'knji':kategorija);
                                            kat = (kat=='Prilog sa skupa (u zborniku)'? 'Prilog sa skupa (u':kat);    //ako je odabran u ddl prilog u  zborniku da hvata i prilog u časopisu i zborniku, ali ne i neobjavljen
                                            if (post.crosbi_kategorija.toLowerCase().includes(kat.toLowerCase())) {
                                                //return post;
                                                let flagInd = "";

                                                //filtriranje indeksiranosti 
                                                if (post.crosbi_kategorija === 'Radovi u zbornicima skupova' || post.crosbi_kategorija ==='Prilog sa skupa (u zborniku)'|| post.crosbi_kategorija ==='Prilog sa skupa (u časopisu)') {
                                                    //flagInd = "c";
                                                    izvjestajCradovi += post.citation_en;
                                                    izvjestajCradovi += "\n\n";
                                                    ctC++
                                                    console.log("konf:", ctC);
                                                    return post;
                                                }
                                                else if (post.crosbi_kategorija === 'Knjige i poglavlja u knjigama' ||
                                                    post.crosbi_kategorija === 'Prilog u knjizi' ||
                                                    post.crosbi_kategorija === 'Autorska knjiga' ||
                                                    post.crosbi_kategorija === 'Urednička knjiga') {
                                                    izvjestajKnjige += post.citation_en;
                                                    izvjestajKnjige += "\n\n";
                                                    ctKnjige++
                                                    return post;
                                                }
                                                else {
                                                    let ctInd = 0;

                                                    if (post.indeksiranost_casopisa === null || post.indeksiranost_casopisa.length ===0) {    
                                                       
                                                            if (filters.indeksiranost[4] === true) {
                                                                //flagInd = "o";
                                                                izvjestajOstaliCasRadovi += post.citation_en;
                                                                izvjestajOstaliCasRadovi += "\n\n";
                                                                ctOstaliCas++
                                                                return post;
                                                            }                                                       
                                                    }                                                    
                                                    else {
                                                        flagInd="";
                                                        post.indeksiranost_casopisa.map((post) => {
                                                            if (post === "Current Contents Connect (CCC)"){ 
                                                                flagInd = "a";
                                                                console.log("flagInd! = " + flagInd);
                                                                if(filters.indeksiranost[0] === true) {
                                                                    ctInd++;                                     
                                                                }
                                                            }
                                                            else if (post === "Web of Science Core Collection, Science Citation Index Expanded (WoSCC-SCI-Exp)" || post === "Science Citation Index Expanded (SCI-EXP)") {
                                                                flagInd = "a";
                                                                if (filters.indeksiranost[1] === true) {
                                                                    ctInd++;

                                                                }
                                                            }
                                                            else if (post === "Web of Science Core Collection, Emerging Sources Citation Index (WoSCC-ESCI)" || post === "Emerging Sources Citation Index (ESCI)") {
                                                                flagInd = "a";
                                                                if (filters.indeksiranost[2] === true) {
                                                                    ctInd++;
                                                                }
                                                            }
                                                            else if (post === "Scopus") {
                                                                if(flagInd!="a")flagInd = "a";   //po novom zakonu i scopus je A kategorija
                                                                if (filters.indeksiranost[3] === true) {
                                                                    ctInd++;

                                                                }
                                                            }
                                                        })

                                                        console.log("flagInd2 = " + flagInd);
                                                        console.log("ctInd2 = " + ctInd);


                                                        if (ctInd > 0) {
                                                            ctInd = 0;

                                                            if (flagInd === "a") {
                                                                izvjestajAradovi += post.citation_en;
                                                                let najJCR = najboljiKvartilJCR(post.autori_na_radu);
                                                                let najJCI = najboljiKvartilJCI(post.autori_na_radu);
                                                                let najSJR = najboljiKvartilSJR(post.autori_na_radu);
                                                                if(najJCR!=0)izvjestajAradovi += "   [JCR JIF Q" + najJCR + "]";
                                                                if(najJCI!=0)izvjestajAradovi += "   [JCR JCI Q" + najJCI + "]";
                                                                if(najSJR!=0)izvjestajAradovi += "   [SJR Q" + najSJR + "]";
                                                                if(post.impact_factor_casopisa!=0)izvjestajAradovi += "   JIF=" + post.impact_factor_casopisa;
                                                                if(post.jci_casopisa!=0)izvjestajAradovi += "   JCI=" + post.jci_casopisa;
                                                                izvjestajAradovi += "\n\n";
                                                                ctA++;
                                                            }
                                                            else if (flagInd === "b") {
                                                                izvjestajBradovi += post.citation_en;
                                                                izvjestajBradovi += "\n\n";
                                                                ctB++
                                                            }
                                                            


                                                            return post;
                                                        }
                                                        
                                                    }
                                                }
                                                



                                                //filtriranje indeksiranosti kraj
                                            }
                                        }

                                        /*
                                        if (kategorija == 'Sve') {
                                            



                                            return post;
                                        }
                                        else {

                                            if (post.crosbi_kategorija.toLowerCase().includes(kategorija.toLowerCase())) {
                                                //return post; 
                                                let flagInd = "z";

                                                //filtriranje indeksiranosti 
                                                if (post.crosbi_kategorija === 'Radovi u zbornicima skupova') {
                                                    flagInd = "c";
                                                    izvjestajCradovi += post.citation_en;
                                                    izvjestajCradovi += "\n\n";
                                                    ctC++
                                                    return post;
                                                }
                                                else {
                                                    let ctInd = 0;
                                                    if (post.indeksiranost_casopisa === null && filters.indeksiranost[4] === true) {
                                                        flagInd = "o";
                                                        izvjestajOstaliCasRadovi += post.citation_en;
                                                        izvjestajOstaliCasRadovi += "\n\n";
                                                        ctOstaliCas++
                                                        return post;
                                                    }
                                                    else if (post.indeksiranost_casopisa === null) { }
                                                    else {
                                                        post.indeksiranost_casopisa.map((post) => {
                                                            if (post === "Current Contents Connect (CCC)" && filters.indeksiranost[0] === true) {
                                                                ctInd++;
                                                                flagInd = "a";
                                                                console.log("flagInd! = " + flagInd);
                                                            }
                                                            else if (post === "Science Citation Index Expanded (SCI-EXP)" && filters.indeksiranost[1] === true) {
                                                                ctInd++;
                                                                flagInd = "a";
                                                            }
                                                            else if (post === "Emerging Sources Citation Index (ESCI)" && filters.indeksiranost[2] === true) {
                                                                ctInd++;
                                                                flagInd = "a";
                                                            }
                                                            else if (post === "Scopus" && filters.indeksiranost[3] === true) {
                                                                ctInd++;
                                                                flagInd = "b";
                                                            }
                                                        })


                                                        console.log("ctInd2 = " + ctInd);
                                                        if (ctInd > 0) {
                                                            ctInd = 0;

                                                            if (flagInd === "a") {
                                                                izvjestajAradovi += post.citation_en;
                                                                izvjestajAradovi += "\n\n";
                                                                ctA++;
                                                            }
                                                            else if (flagInd === "b") {
                                                                izvjestajBradovi += post.citation_en;
                                                                izvjestajBradovi += "\n\n";
                                                                ctB++
                                                            }
                                   

                                                            return post;
                                                        }
                                                    }
                                                }


                                                //filtriranje indeksiranosti kraj
                                            }
                                        }*/
                                    }
                                }
                            }
                        }).filter((post) => {
                            if (podkategorija == 0) {
                                return post;
                            }
                            else if(podkategorija == 1){
                                if (post.crosbi_podkategorija == "Znanstveni radovi u zbornicima skupova" ||
                                    post.crosbi_podkategorija == "Znanstveni i pregledni radovi" ||
                                    post.crosbi_podkategorija == "Izvorni znanstveni rad" ||
                                    post.crosbi_podkategorija == "izvorni znanstveni rad" ||
                                    post.crosbi_podkategorija == "Prethodno priopćenje" ||
                                    post.crosbi_podkategorija == "Pregledni rad (znanstveni)" ||
                                    post.crosbi_podkategorija == "Kratko priopćenje"
                                ) {
                                    return post;
                                }
                            }
                            else if(podkategorija == 2){
                                if (post.crosbi_podkategorija == "Stručni rad" ||
                                    post.crosbi_podkategorija == "stručni rad" ||
                                    post.crosbi_podkategorija == "Stručni radovi" ||
                                    post.crosbi_podkategorija == "Pregledni rad (stručni)" ||
                                    post.crosbi_podkategorija == "Stručni radovi u zbornicima skupova"
                                ) {
                                    return post;
                                }
                            }
                            else{
                                if (post.crosbi_podkategorija != "Znanstveni radovi u zbornicima skupova" &&
                                    post.crosbi_podkategorija != "Znanstveni i pregledni radovi" &&
                                    post.crosbi_podkategorija != "Izvorni znanstveni rad" &&
                                    post.crosbi_podkategorija != "izvorni znanstveni rad" &&
                                    post.crosbi_podkategorija != "Prethodno priopćenje" &&
                                    post.crosbi_podkategorija != "Pregledni rad (znanstveni)" &&
                                    post.crosbi_podkategorija != "Kratko priopćenje" &&
                                    post.crosbi_podkategorija != "Stručni rad" &&
                                    post.crosbi_podkategorija != "stručni rad" &&
                                    post.crosbi_podkategorija != "Stručni radovi" &&
                                    post.crosbi_podkategorija != "Pregledni rad (stručni)" &&
                                    post.crosbi_podkategorija != "Stručni radovi u zbornicima skupova"
                                ) {
                                    return post;
                                }
                            }
                        }).filter((post) => {
                            /*if (kvartilJCR == 0) {
                                //console.log("////// svejedno jcr kvartil"); 
                                return post;                          
                            }
                            else {
                                if (najboljiKvartilJCR(post.autori_na_radu) == kvartilJCR) {
                                    //console.log("//////selectirani autori za najbolji jcr kvartil",post.autori_na_radu);
                                    //console.log("////// jcr kvartil",kvartilJCR);
                                    return post;
                                }
                            }*/


                            //novi način (radi samo za odabrane autore najkvartil):
                            ctJCR[najboljiKvartilJCR(post.autori_na_radu)]++;
                            ctJCI[najboljiKvartilJCI(post.autori_na_radu)]++;
                            ctSJR[najboljiKvartilSJR(post.autori_na_radu)]++;


                            let ctJCRMatch = 0,ctJCIMatch = 0, ctSJRMatch = 0;
                            if (kvartilJCR.length == 0) {
                                ctJCRMatch++;
                                //return post;
                            }
                            else {
                                console.log("odabrani jcr kvartil ", kvartilJCR, "duljina", kvartilJCR.length);
                                kvartilJCR.map((selJCR) => {
                                    if (najboljiKvartilJCR(post.autori_na_radu) == selJCR.value || selJCR.value === 0) {
                                        console.log("provjeren najbolji jcr kvartil", najboljiKvartilJCR(post.autori_na_radu));
                                        console.log("provjerena vrijednost kvartila jcr", selJCR.value, " i=", i);
                                        ctJCRMatch++;
                                    }
                                })
                            }

                            if (kvartilJCI.length == 0) {
                                ctJCIMatch++;
                                //return post;
                            }
                            else {
                                kvartilJCI.map((selJCI) => {
                                    if (najboljiKvartilJCI(post.autori_na_radu) == selJCI.value || selJCI.value === 0) {
                                        ctJCIMatch++;
                                    }
                                })
                            }

                            if (kvartilSJR.length == 0) {
                                ctSJRMatch++;
                                //return post;
                            }
                            else {
                                kvartilSJR.map((selSJR) => {
                                    if (najboljiKvartilSJR(post.autori_na_radu) == selSJR.value || selSJR.value === 0) {
                                        ctSJRMatch++;
                                    }
                                })
                            }


                            console.log("Povoljniji q je", povoljnijiQ.value)


                            if (povoljnijiQ.value === 1) {
                                console.log("**ILI");
                                if (ctJCRMatch > 0 || ctJCIMatch>0 || ctSJRMatch > 0) return post;                                
                            }
                            else if (povoljnijiQ.value === 2){
                                if ((ctJCRMatch > 0 || ctJCIMatch>0) && ctSJRMatch > 0) return post;
                            }
                            else {
                                console.log("**I");
                                if (ctJCRMatch > 0 && ctJCIMatch>0 && ctSJRMatch > 0) return post;
                            }




                        })/*.filter((post) => {
                            if (kvartilSJR == 0) {
                                //console.log("////// svejedno sjr kvartil"); 
                                return post;
                            }
                            else {
                                if (najboljiKvartilSJR(post.autori_na_radu) == kvartilSJR) {
                                    //console.log("//////selectirani autori za najbolji sjr kvartil",post.autori_na_radu);
                                    //console.log("////// sjr kvartil",kvartilSJR);
                                    return post;
                                }
                            }
                        })*/.filter((post) => {
                            if (inozemniCasopis == 2) {
                                return post;
                            }
                            else {

                                if (post.inozemni == inozemniCasopis) {
                                    return post;
                                }
                            }
                        }).filter((post) => {
                            if (sGospodarstvom == 2) {
                                return post;
                            }
                            else {

                                if (post.rad_sa_gospodarstvom == sGospodarstvom) {
                                    return post;
                                }
                            }
                        }).filter((post) => {
                            if (naProjektu == 2) {
                                return post;
                            }
                            else {
                                if (post.rad_na_projektu == naProjektu) {
                                    return post;
                                }
                            }
                        }).filter((post) => {
                            if (medjuzavodskiRad == 2) {
                                return post;
                            }
                            else {

                                if (post.medjuzavodski_rad == medjuzavodskiRad) {
                                    return post;
                                }
                            }
                        }).filter((post) => {
                            if (radSDrugimZnanInst == 2) {
                                return post;
                            }
                            else {

                                if (post.rad_s_drugim_znanstvenim_institucijama == radSDrugimZnanInst) {
                                    return post;
                                }
                            }
                        }).filter((post) => {
                            if (radSInozemnimZnan == 2) {
                                return post;
                            }
                            else {

                                if (post.rad_s_inozemnim_znanstvenicima == radSInozemnimZnan) {
                                    return post;
                                }
                            }
                        }).filter((post) => {
                            if (saStudentom == 2) {
                                return post;
                            }
                            else {

                                let flagSaStud = 0;
                                console.log("SELECTIRANI", selectedAuthors);
                                post.autori_na_radu.forEach((element) => {
                                    selectedAuthors.map((selElement) => {
                                        console.log("SELECTIRANI", selElement);

                                        if ((element.aai === selElement.value || selElement.value == "sviRadovi") && element.rad_sa_studentom != 0) {
                                            flagSaStud = element.rad_sa_studentom;

                                        }
                                    });
                                });


                                if (flagSaStud == saStudentom) {
                                    return post;
                                }

                            }
                        }).filter((post) => {
                            if (sDoktorandom == 2) {
                                return post;
                            }
                            else {

                                let flagSDok = 0;
                                post.autori_na_radu.forEach((element) => {
                                    selectedAuthors.map((selElement) => {
                                        if ((element.aai === selElement.value || selElement.value == "sviRadovi") && element.rad_s_doktorandom != 0) {
                                            flagSDok = element.rad_s_doktorandom;

                                        }
                                    });
                                });


                                if (flagSDok == sDoktorandom) {
                                    return post;
                                }

                            }
                        }).filter((post) => {
                            if (otvoreniPristup == 2) {
                                return post;
                            }
                            else {

                                if (post.rad_u_otvorenom_pristupu == otvoreniPristup) {
                                    return post;
                                }
                            }
                        }).filter((post) => {
                            if (vanUstanove == 2) {
                                return post;
                            }
                            else {

                                let flagVanUstanove = 0;
                                post.autori_na_radu.forEach((element) => {
                                    selectedAuthors.map((selElement) => {
                                        if ((element.aai === selElement.value || selElement.value == "sviRadovi") && element.rad_van_ustanove != 0) {
                                            flagVanUstanove = element.rad_van_ustanove;

                                        }
                                    });
                                });


                                if (flagVanUstanove == vanUstanove) {
                                    return post;
                                }

                            }
                        }).map((post, index) => {

                            ct++;

                            let autori_ieee_old = "" //ovjde imam staru verziju dok su autori bili pravilnim redoslijedom vezani uz rad
                            post.autori_na_radu.map((post) => {
                                autori_ieee_old += post.ime.charAt(0) + ". " + post.prezime + ", ";
                                return 0;
                            })

                            let autori_ieee = "" //ovo je nova verzija koja parsira redom autore iz stringa i stavlja ih u ieee format
                            let prez_ime = post.autori.split(';').map(author => author.trim());
                           
                            let processedNames = prez_ime.map(name => {
                                // Trim any leading/trailing whitespace
                                name = name.trim();
                               
                                if (name) {  // Check if the name is not empty
                                    // Split the name by comma to separate first and last names
                                    let parts = name.split(',').map(part => part.trim());

                                    if (parts.length === 2) {
                                        let [lastName, firstName] = parts;
                            
                                        // Take the first letter of the first name and add a period
                                        let initial = firstName[0] + '.';
                                    
                                        // Return the processed name in the desired format
                                        return initial + ' ' + lastName;
                                    }
                                    else console.log("errname",parts) 
                                }                                               
                                return '';  // Return an empty string if the name was empty
                            });
                            
                            autori_ieee = processedNames.filter(name => name).join(', ');
                            // Ensure the output ends with a comma if needed
                            if (autori_ieee && !autori_ieee.endsWith(',')) {
                                autori_ieee += ', ';
                            }
                            //console.log("autori_ieee", autori_ieee)

                            if (post.crosbi_kategorija === "Radovi u časopisima")
                                textToCopyIEEE += autori_ieee + "\"" + post.naslov_en + ",\" " + post.naziv_casopisa + ", vol. " + post.volume + ", no. " + post.no_issue + ", pp. " + post.paper_pages + ", " + post.godina + ".\n\n";
                            else
                                textToCopyIEEE += autori_ieee + "\"" + post.naslov_en + ",\" in " + post.naziv_casopisa + ", " + post.godina + ", pp. " + post.paper_pages + "\n\n";

                            textToCopyCrosbi += post.citation_en.replace("\n","\r") + "\n\n";

                            // OVDJE POČINJE KREIRANJE IZVJEŠTAJA
                            var flagSJR = 0;
                            var flagJCR = 0;
                            var maxSJRQ = 5;
                            var maxJCRQ = 5;

                            if (post.crosbi_kategorija === "Radovi u časopisima") {

                                [].concat.apply([], post.autori_na_radu.map((post, index) => {
                                    if (post.kvartili_autora_na_radu.length > 0) {
                                        let qkat = post.kvartili_autora_na_radu.map((item) => {
                                            item.q = post.kvartili_autora_na_radu.kvartil;
                                            item.ime = post.kvartili_autora_na_radu.naziv_kategorizacije;
                                            return item;
                                        });
                                        return qkat;
                                    }
                                }).filter((post) => {
                                    return post !== undefined;
                                })
                                ).filter((post) => {

                                    if (post.naziv_kategorizacije === "jcr") {
                                        flagJCR++;
                                        if (post.kvartil < maxJCRQ) maxJCRQ = post.kvartil;
                                    }
                                    if (post.naziv_kategorizacije === "sjr") {
                                        flagSJR++;
                                        if (post.kvartil < maxSJRQ) maxSJRQ = post.kvartil;
                                    }
                                    /*if (post.naziv_kategorizacije.toLowerCase().includes('sjr')){
                                     flagSJR++;
     
                                     return post;
                                    }*/
                                })


                                //stari način (radi za sve autore najkvartil):
                                //ctJCR[maxJCRQ]++;
                                //ctSJR[maxSJRQ]++;

                                //novi način (radi samo za odabrane autore najkvartil):
                                //ctJCR[najboljiKvartilJCR(post.autori_na_radu)]++;
                                //ctSJR[najboljiKvartilSJR(post.autori_na_radu)]++;



                                /*
                                                            post.autori_na_radu.map((autor) => {
                                                                return autor.kvartili_autora_na_radu;
                                                            }).map((kvartil)=>{
                                                                if(kvartil.naziv_kategorizacije === "jcr"){flagJCR++;}
                                                                if(kvartil.naziv_kategorizacije === "sjr"){flagSJR++;}
                                                                console.log("Kat4print:", kvartil.naziv_kategorizacije)
                                                            }))*/

                                console.log(post.naslov_en + " - JCR" + flagJCR + " - SJR" + flagSJR);





                                if (flagJCR > 0) {
                          
                                   /* izvjestajAradovi += post.citation_en;
                                    if (maxJCRQ > 0 && maxJCRQ < 5) izvjestajAradovi += "   [JCR Q" + maxJCRQ + "]";
                                    if (maxSJRQ > 0 && maxSJRQ < 5) izvjestajAradovi += "   [SJR Q" + maxSJRQ + "]";
                                    izvjestajAradovi += "\n\n";
                                    ctA++;*/
                                }
                                else if (flagSJR > 0) {
                                    /*izvjestajBradovi += post.citation_en;
                                    if (maxJCRQ > 0 && maxJCRQ < 5) izvjestajBradovi += "   [JCR Q" + maxJCRQ + "]";
                                    if (maxSJRQ > 0 && maxSJRQ < 5) izvjestajBradovi += "   [SJR Q" + maxSJRQ + "]";
                                    izvjestajBradovi += "\n\n";
                                    ctB++;*/
                                }
                                else {
                                    /*ctOstaliCas++;
                                    izvjestajOstaliCasRadovi += post.citation_en + "\n";*/
                                }

                            }
                            else {
                                /*ctC++;
                                izvjestajCradovi += post.citation_en + "\n";*/
                            }


                            //OVDJE ZAVRŠAVA KREIRANJE IZVJEŠTAJA

                            return (
                                <div>
                                    <hr />
                                    {/*<div className="full-width ubuntu-result-bold"><Link className="full-width ubuntu-result-bold" to={{pathname: '/rad', state:{nesto: "dfg"}}}> {post.naslov_en} </Link></div>*/}
                                    {/*<div className="full-width ubuntu-result-bold"><Link className="full-width ubuntu-result-bold" to={{pathname: '/rad', nesto: true}}> {post.naslov_en} </Link></div>*/}
                                    <div className="row">
                                        <div className="col-md-11">
                                            <div className="full-width ubuntu-result-bold">
                                                <Link className="full-width ubuntu-result-bold" to={`/rad/${post.id}`}> {post.naslov_en} </Link></div>
                                            <div className="full-width ubuntu-result"> {post.autori} </div>
                                            <div className="full-width ubuntu-result"> {post.naziv_casopisa} </div>
                                            <div className="full-width ubuntu-result"> {(post.datum_objave != "0000-00-00") && (post.datum_objave.slice(0,4)) != post.godina ? post.datum_objave.slice(0,4): post.godina} </div>
                                            {post.datum_objave === "0000-00-00" && post.datum_objave_lock==0? <span className="ubuntu-red-cond"> <FontAwesomeIcon icon="exclamation-triangle" /> Publikacija nema unesen i zaključan datum objave! </span> : <div></div>}
                                            {post.datum_objave != "0000-00-00" && post.datum_objave_lock==0? <span className="ubuntu-red-cond"> <FontAwesomeIcon icon="exclamation-triangle" /> Publikacija nema zaključan datum objave! </span> : <div></div>}

                                            <div className="full-width ">
                                                <span className="ubuntu-dark-cond-invert">&nbsp;{post.crosbi_kategorija}&nbsp;</span>&nbsp;&nbsp;
                                                <span className="ubuntu-light-cond-invert">&nbsp;{post.crosbi_podkategorija}&nbsp;</span>&nbsp;&nbsp;
                                                {/*
                                                    najboljiKvartil(post.autori_na_radu) ?
                                                        //najboljiKvartil(filters.autori) ?
                                                        <span className="ubuntu-dark-cond-invert">&nbsp;Q{najboljiKvartil(post.autori_na_radu)}&nbsp;</span>
                                                        :
                                                        <div />
                                                */}
                                                
                                                {
                                                    najboljiKvartilJCR(post.autori_na_radu) ?
                                                        /*najboljiKvartil(filters.autori) ?*/
                                                        <span><span className="ubuntu-brown-cond-invert">&nbsp;JCR JIF Q{najboljiKvartilJCR(post.autori_na_radu)}&nbsp;</span>&nbsp;&nbsp;</span>
                                                        :
                                                        <span />
                                                }
                                                {
                                                    najboljiKvartilJCI(post.autori_na_radu) ?
                                                        /*najboljiKvartil(filters.autori) ?*/
                                                        <span><span className="ubuntu-brown-cond-invert">&nbsp;JCR JCI Q{najboljiKvartilJCI(post.autori_na_radu)}&nbsp;</span>&nbsp;&nbsp;</span>
                                                        :
                                                        <span />
                                                }
                                                
                                                
                                                {
                                                    najboljiKvartilSJR(post.autori_na_radu) ?
                                                        /*najboljiKvartil(filters.autori) ?*/
                                                        <span><span className="ubuntu-beige-cond-invert">&nbsp;SJR Q{najboljiKvartilSJR(post.autori_na_radu)}&nbsp;</span>&nbsp;&nbsp;</span>
                                                        :
                                                        <span />
                                                }                                                
                                                {
                                                    post.impact_factor_casopisa!=0.0 ?
                                                        <span><span className="ubuntu-green-cond-invert">&nbsp;JIF={post.impact_factor_casopisa}&nbsp;</span>&nbsp;&nbsp;</span>
                                                        :
                                                        <span />
                                                }
                                                {
                                                    post.jci_casopisa!=0.0 ?
                                                        <span><span className="ubuntu-green-cond-invert">&nbsp;JCI={post.jci_casopisa}&nbsp;</span>&nbsp;&nbsp;</span>
                                                        :
                                                        <span />
                                                }
                                                                                                {/*
                                                    post.rad_u_otvorenom_pristupu=="1" ?
                                                        <span><a className="ubuntu-light-cond-invert fst-italic" href={`https://doi.org/${repo.data[0].urls_doi}`}>&nbsp;Open Access&nbsp;&nbsp;</a>&nbsp;&nbsp;</span>
                                                        :
                                                        <span />
                                            */}
                                            </div>
                                        </div>
                                        <div className="col-md-1 align-right ubuntu-gray fw-bold">
                                            #{index + 1}
                                        </div>
                                    </div>
                                </div>

                            )

                        })}







                </div>

            )}

        </div>


    );






}

export default Radovi;