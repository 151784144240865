import React, { useState } from 'react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from "react-select";
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import axios from 'axios';
import { faCheckSquare, faCoffee, faSignOutAlt, faPlusSquare, faSearch, faPlus, faServer, faUser, faArrowCircleUp, faArrowCircleDown, faFilter, faEdit, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { propTypes } from 'react-bootstrap/esm/Image';

import { useSelector } from 'react-redux';
import { FilterSharp } from '@material-ui/icons';
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
library.add(fab, faCheckSquare, faCoffee, faSignOutAlt, faPlusSquare, faSearch, faPlus, faServer, faUser, faArrowCircleUp, faArrowCircleDown, faFilter, faEdit, faChevronLeft)

toast.configure()




function Filteri({ dropDownKor, korisnik, childToParent, childToParentKat, childToParentPodkat, childToParentInd, childToParentQ, childToParentQJCR,childToParentQJCI, childToParentQSJR,childToParentPovoljnijiQ, childToParentDatumOd, childToParentDatumDo, childToParentSDatumom, childToParentStud, childToParentSDoktorandom, childToParentGosp, childToParentProj, childToParentMedjuzavodski, childToParentRadSDrugimZnanInst, childToParentRadSInozemnimZnan, childToParentInozemniCas,childToParentOtvoreniPristup, childToParentVanUstanove }) {

  const filters = useSelector(state => state.filters);

  let datumPrije5 = new Date().getFullYear() - 5 + "-" + new Date().toLocaleString("en-US", { month: "2-digit" }) + "-" + new Date().toLocaleString("en-US", { day: '2-digit' })

  const [startDate, setStartDate] = useState(filters.datumOd);
  const [endDate, setEndDate] = useState(filters.datumDo);



  const [CCC, setCCC] = React.useState(filters.indeksiranost[0]);
  const [SCIEXP, setSCIEXP] = React.useState(filters.indeksiranost[1]);
  const [ESCI, setESCI] = React.useState(filters.indeksiranost[2]);
  const [Scopus, setScopus] = React.useState(filters.indeksiranost[3]);
  const [neindeksirano, setNeindeksirano] = React.useState(filters.indeksiranost[4]);

  const [autori, setAutori] = React.useState(null);

  React.useEffect(() => {
    axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/autori').then((response) => {
      setAutori(response.data);

    });
    
  }, []);

  if (!autori) return null;

  let kor = { label: "Svi autori", value: "sviRadovi" }


  const selectZavod = (zavod) => {

    filters.autori.length = 0;
    let i = 0;
    autori.data.forEach(element => {
      if (element["zavod"] == zavod) {
        filters.autori[i] = { value: element["aai"], label: element["prezime"] + " " + element["ime"] };
        console.log("zpi" + element["aai"]);
        i++;
      }
    });

    childToParent(filters.autori);
    //handleSelect(filters.autori);

    toast.info('Odabrali ste sve članove iz ' + zavod, {
      position: toast.POSITION.BOTTOM_CENTER,
      autClose: 1000,
      transition: Slide
    });

  }

  const selectIG = (ig) => {
    let nazivIG;
    filters.autori.length = 0;
    let i = 0;
    autori.data.forEach(element => {
      if (element["id_grupe"] == ig) {
        filters.autori[i] = { value: element["aai"], label: element["prezime"] + " " + element["ime"] };
        nazivIG = element["naziv_grupe"];
        i++;
      }
    });

    childToParent(filters.autori);
    //handleSelect(filters.autori);

    toast.info('Odabrali ste sve članove iz ' + ig + " ("+ nazivIG +")", {
      position: toast.POSITION.BOTTOM_CENTER,
      autClose: 1000,
      transition: Slide
    });

  }

  const handleSelect = (e) => {
    /*if (e.value == "sviRadovi")
    childToParent(e.value);
    else
    childToParent('radovi/' + e.value);*/

    if (e.length === 0) {
      //e[0] = { label: "Svi autori", value: "sviRadovi" }
      e[0] = { label: "Nema odabira", value: "nista" }
    }
    else if(e.length > 1 && e[0].value	== "nista"){
      e.shift();
      //filters.autori.shift();
      console.log("autoriiiiii",e)
    }

    filters.autori = e;
    console.log("redux");
    console.log("redux - autori su:", filters.autori);
    childToParent(e);
  }

  let prethodniAutor = "";
  let listaAutora = [];
  let dropDownEle = { label: "Svi autori", value: "sviRadovi" };
  listaAutora.push(dropDownEle);
  autori.data.forEach((element, index) => {
    let dropDownEle = { label: element["prezime"] + " " + element["ime"], value: element["aai"] };
    //console.log("next",autori.data[index+1]);
    if (index + 1 < autori.data.length && index > 0 && (dropDownEle.label === autori.data[index + 1].prezime + " " + autori.data[index + 1].ime || dropDownEle.label === autori.data[index - 1].prezime + " " + autori.data[index - 1].ime)) {
      dropDownEle = { label: element["prezime"] + " " + element["ime"] + " (" + element["broj_znanstvenika"] + ")", value: element["aai"] };
    }

    if (element["aai"] == dropDownKor) {
      kor = dropDownEle;
    }
    //prethodniAutor = dropDownEle.label;
    console.log("dropdownkor je " + dropDownKor);
    listaAutora.push(dropDownEle);

  });

  let kvartili = [
    //{ label: "Svejedno", value: 0 },
    { label: "Q1", value: 1 },
    { label: "Q2", value: 2 },
    { label: "Q3", value: 3 },
    { label: "Q4", value: 4 }
  ]

  let povoljnijiQ = [
    {label: "DA (od JCR)", value: 2},
    {label: "DA (od svih)", value: 1},
    {label: "NE", value: 0}  
  ]

  let kategorije = [
    { label: "Sve", value: 0 },
    /* { label: "Radovi u časopisima", value: 1 },*/
    { label: "Prilog u časopisu", value: 1 },
    /* { label: "Radovi u zbornicima skupova", value: 2 }, */
    { label: "Prilog sa skupa (u zborniku)", value: 2 },
   
    { label: "Knjige i poglavlja u knjigama", value: 3 }
    
  ]


  let podkategorije = [
    { label: "Sve", value: 0 },
    { label: "Znanstveni rad", value: 1 },
    { label: "Stručni rad", value: 2 },
    { label: "Ostalo", value: 3 }
  ]


  const handleKategorijaSelect = (e) => {
    childToParentKat(e.label);
    filters.vrstaPublikacije = e;
  }

  const handlePodkategorijaSelect = (e) => {
    childToParentPodkat(e.value);
    filters.podkategorijaPublikacije = e;
  }
  

  const handleCCC = () => {
    setCCC(CCC ? false : true);
    filters.indeksiranost[0] = !filters.indeksiranost[0];
    childToParentInd([CCC, SCIEXP, ESCI, Scopus, neindeksirano]);
  };
  const handleSCIEXP = () => {
    setSCIEXP(SCIEXP ? false : true);
    filters.indeksiranost[1] = !filters.indeksiranost[1];
    childToParentInd([CCC, SCIEXP, ESCI, Scopus, neindeksirano]);
  };
  const handleESCI = () => {
    setESCI(ESCI ? false : true);
    filters.indeksiranost[2] = !filters.indeksiranost[2];
    childToParentInd([CCC, SCIEXP, ESCI, Scopus, neindeksirano]);
  };
  const handleScopus = () => {
    setScopus(Scopus ? false : true);
    filters.indeksiranost[3] = !filters.indeksiranost[3];
    childToParentInd([CCC, SCIEXP, ESCI, Scopus, neindeksirano]);
  };
  const handleNeindeksirano = () => {
    setNeindeksirano(neindeksirano ? false : true);
    filters.indeksiranost[4] = !filters.indeksiranost[4];
    childToParentInd([CCC, SCIEXP, ESCI, Scopus, neindeksirano]);
  };



  const handleDatumOd = (e) => {
    filters.datumOd = e;
    childToParentDatumOd(e);
    
  }

  const handleDatumDo = (e) => {
    filters.datumDo = e;
    childToParentDatumDo(e);
    
  }


  let sDatumom = [
    { label: "Svejedno", value: 2 },
    { label: "Da", value: 1 },
    { label: "Ne", value: 0 }
  ]

  const handleSDatumomSelect = (e) => {
    childToParentSDatumom(e.value);
    filters.radSDatumom = e;
  }



  const handleKvartiliSelect = (e) => {
    childToParentQ(e.value);
    filters.kvartil = e;
  }

  const handleKvartiliJCRSelect = (e) => {
    //ovo je staro za rad s jednim odabranim kvartilom
    //childToParentQJCR(e.value);
    //filters.kvartilJCR = e;   

    if (e.length === 0) {
      e[0] = { label: "Svejedno", value: 0 }
    }    

    if(e[0].value===0 && e.length>1){
      e = e.slice(1);
      console.log("sliced array",e);
    }
    
    childToParentQJCR(e);
    filters.kvartilJCRMulti = e;
  }

  const handleKvartiliJCISelect = (e) => {
    if (e.length === 0) {
      e[0] = { label: "Svejedno", value: 0 }
    }    

    if(e[0].value===0 && e.length>1){
      e = e.slice(1);
      console.log("sliced array",e);
    }
    
    childToParentQJCI(e);
    filters.kvartilJCIMulti = e;
  }
  const handleKvartiliSJRSelect = (e) => {
    //ovo je staro za rad s jednim odabranim kvartilom
    //childToParentQSJR(e.value);
    //filters.kvartilSJR = e;

    if (e.length === 0) {
      e[0] = { label: "Svejedno", value: 0 }
    }    

    if(e[0].value===0 && e.length>1){
      e = e.slice(1);//briše "svejedno" ako je odabran još neki kvartil
    }
    
    childToParentQSJR(e);
    filters.kvartilSJRMulti = e;

  }

  const handlePovoljnijiQSelect = (e) => {
    childToParentPovoljnijiQ(e);
    filters.povoljnijiQ = e;
  }


  let saStudentom = [
    { label: "Svejedno", value: 2 },
    { label: "Da", value: 1 },
    { label: "Ne", value: 0 }
  ]

  const handleSaStudentomSelect = (e) => {
    childToParentStud(e.value);
    filters.radSaStudentom = e;
  }

  let sDoktorandom = [
    { label: "Svejedno", value: 2 },
    { label: "Da", value: 1 },
    { label: "Ne", value: 0 }
  ]

  const handleSDoktorandomSelect = (e) => {
    childToParentSDoktorandom(e.value);
    filters.radSDoktorandom = e;
  }

  let sGospodarstvom = [
    { label: "Svejedno", value: 2 },
    { label: "Da", value: 1 },
    { label: "Ne", value: 0 }
  ]

  const handleSGospodarstvomSelect = (e) => {
    childToParentGosp(e.value);
    filters.radSGospodarstvom = e;
  }

  let naProjektu = [
    { label: "Svejedno", value: 2 },
    { label: "Da", value: 1 },
    { label: "Ne", value: 0 }
  ]

  const handleNaProjektuSelect = (e) => {
    childToParentProj(e.value);
    filters.radNaProjektu = e;
  }


  let medjuzavodskiRad = [
    { label: "Svejedno", value: 2 },
    { label: "Da", value: 1 },
    { label: "Ne", value: 0 }
  ]

  const handleMedjuzavodskiSelect = (e) => {
    childToParentMedjuzavodski(e.value);
    filters.medjuzavodskiRad = e;
  }


  let radSDrugimZnanInst = [
    { label: "Svejedno", value: 2 },
    { label: "Da", value: 1 },
    { label: "Ne", value: 0 }
  ]

  const handleRadSDrugimZnanInstSelect = (e) => {
    childToParentRadSDrugimZnanInst(e.value);
    filters.radSDrugimZnanInst = e;
  }

  let radSInozemnimZnan = [
    { label: "Svejedno", value: 2 },
    { label: "Da", value: 1 },
    { label: "Ne", value: 0 }
  ]

  const handleRadSInozemnimZnan = (e) => {
    childToParentRadSInozemnimZnan(e.value);
    filters.radSInozemnimZnan = e;
  }

  let inozemniCasopis = [
    { label: "Svejedno", value: 2 },
    { label: "Da", value: 1 },
    { label: "Ne", value: 0 }
  ]
  const handleInozemniCasopisSelect = (e) => {
    childToParentInozemniCas(e.value);
    filters.inozemniCasopis = e;
  }

  let otvoreniPristup = [
    { label: "Svejedno", value: 2 },
    { label: "Da", value: 1 },
    { label: "Ne", value: 0 }
  ]
  const handleRadOtvoreniPristup = (e) => {
    childToParentOtvoreniPristup(e.value);
    filters.radOtvoreniPristup = e;
  }

  let vanUstanove = [
    { label: "Svejedno", value: 2 },
    { label: "Da", value: 1 },
    { label: "Ne", value: 0 }
  ]
  const handleVanUstanoveSelect = (e) => {
    childToParentVanUstanove(e.value);
    filters.vanUstanove = e;
  }

  const temaZaSelect = (theme) => ({
    ...theme,
    borderRadius: 5,
    colors: {
      ...theme.colors,
      text: 'orangered',
      primary25: 'lightGrey',
      primary50: 'lightGrey',
      primary: 'gray',
    },
  })


  return (

    <div className="padding15">
      <div className="form-group lr-padding-rem padding-bottom">
        <label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Autor:</label>
        <Select id="autor" className="padding-bottom ubuntu-gray" theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            text: 'orangered',
            primary25: 'lightGrey',
            primary50: 'lightGrey',
            primary: 'gray',
          },
        })} placeholder="Odaberi..." options={listaAutora} isMulti={true} isClearable={true} onChange={handleSelect} defaultValue={filters.autori}>
        </Select>
        {/*<Select id="autor" className="padding-bottom ubuntu-light-cond" placeholder="Odaberi..." options={listaAutora} isMulti={false} onChange={handleSelect} defaultValue={listaAutora[0]}>
        </Select>*/}

      </div>
      {/*
      <div className="center">
      <Link className="ubuntu-beige-cond-invert" onClick={() => { }} to={"/radovi"}>&nbsp;Odaberi sve autore&nbsp;</Link>
      </div>
      */}
      <div className="center">
        <Link className="ubuntu-light-cond-invert" onClick={() => { selectIG("IG01") }} to={"/radovi"}>&nbsp;IG01&nbsp;</Link>
        &nbsp;
        <Link className="ubuntu-light-cond-invert" onClick={() => { selectIG("IG02") }} to={"/radovi"}>&nbsp;IG02&nbsp;</Link>
        &nbsp;
        <Link className="ubuntu-light-cond-invert" onClick={() => { selectIG("IG03") }} to={"/radovi"}>&nbsp;IG03&nbsp;</Link>
        &nbsp;
        <Link className="ubuntu-light-cond-invert" onClick={() => { selectIG("IG04") }} to={"/radovi"}>&nbsp;IG04&nbsp;</Link>
        &nbsp;
        <Link className="ubuntu-light-cond-invert" onClick={() => { selectIG("IG05") }} to={"/radovi"}>&nbsp;IG05&nbsp;</Link>
      </div>
      <div className="center">
        <Link className="ubuntu-light-cond-invert" onClick={() => { selectIG("IG06") }} to={"/radovi"}>&nbsp;IG06&nbsp;</Link>
        &nbsp;
        <Link className="ubuntu-light-cond-invert" onClick={() => { selectIG("IG07") }} to={"/radovi"}>&nbsp;IG07&nbsp;</Link>
        &nbsp;
        <Link className="ubuntu-light-cond-invert" onClick={() => { selectIG("IG08") }} to={"/radovi"}>&nbsp;IG08&nbsp;</Link>
        &nbsp;
        <Link className="ubuntu-light-cond-invert" onClick={() => { selectIG("IG09") }} to={"/radovi"}>&nbsp;IG09&nbsp;</Link>
        &nbsp;
        <Link className="ubuntu-light-cond-invert" onClick={() => { selectIG("IG10") }} to={"/radovi"}>&nbsp;IG10&nbsp;</Link>

      </div>
      <hr />
      <div className="center">
        <Link className="ubuntu-beige-cond-invert" onClick={() => { selectZavod("Zavod za zajedničke predmete") }} to={"/radovi"}>&nbsp;ZZP&nbsp;</Link>
        &nbsp;
        <Link className="ubuntu-beige-cond-invert" onClick={() => { selectZavod("Zavod za programsko inženjerstvo") }} to={"/radovi"}>&nbsp;ZPI&nbsp;</Link>
        &nbsp;
        <Link className="ubuntu-beige-cond-invert" onClick={() => { selectZavod("Zavod za računalno inženjerstvo i automatiku") }} to={"/radovi"}>&nbsp;ZRIA&nbsp;</Link>
        &nbsp;
        <Link className="ubuntu-beige-cond-invert" onClick={() => { selectZavod("Zavod za elektroenergetiku") }} to={"/radovi"}>&nbsp;ZEE&nbsp;</Link>
        &nbsp;
        <Link className="ubuntu-beige-cond-invert" onClick={() => { selectZavod("Zavod za elektrostrojarstvo") }} to={"/radovi"}>&nbsp;ZES&nbsp;</Link>
        &nbsp;
        <Link className="ubuntu-beige-cond-invert" onClick={() => { selectZavod("Zavod za komunikacije") }} to={"/radovi"}>&nbsp;ZAKOM&nbsp;</Link>

      </div>
      <hr />

      <div className="form-group lr-padding-rem">
        <label className="ubuntu-dark-cond fw-bold padding-bottom">Datum objave: </label>
        <div className="form-group  row padding-bottom">

          <br />
          <div className="col-md-1 align-middle">
            <FontAwesomeIcon className="ubuntu-dark" icon="arrow-circle-down" />
          </div>
          <div className="col-md-9">
            <DatePicker className="datepicker ubuntu-dark nice-textbox" selected={startDate} onChange={(date) => { setStartDate(date); handleDatumOd(date) }} dateFormat="dd.MM.yyyy" showYearDropdown scrollableYearDropdown />
          </div>
        </div>

        <div className="form-group row padding-bottom">

          <br />
          <div className="col-md-1 align-middle">
            <FontAwesomeIcon className="ubuntu-dark" icon="arrow-circle-up" />
          </div>
          <div className="col-md-9">
            <DatePicker className="datepicker ubuntu-dark nice-textbox" selected={endDate} onChange={(date) => { setEndDate(date); handleDatumDo(date) }} dateFormat="dd.MM.yyyy" showYearDropdown scrollableYearDropdown />
             </div>
        </div>
      
       
          <div className="col-md-12">
          <label className="ubuntu-dark-cond fw-bold padding-top5 padding-bottom">Samo radovi s datumom:</label>
          </div>
          <div className="col-md-12">
          <Select className="padding-bottom ubuntu-gray" theme={temaZaSelect} options={sDatumom} onChange={handleSDatumomSelect} defaultValue={filters.radSDatumom} />
        
             </div>
       
      
      </div>


        

      <hr />

      <div className="form-group lr-padding-rem padding-bottom">
        <label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Vrsta publikacije:</label>

        <Select className="ubuntu-gray" theme={temaZaSelect} options={kategorije} onChange={handleKategorijaSelect} defaultValue={filters.vrstaPublikacije} />

      </div>

      <div className="form-group lr-padding-rem padding-bottom">
        <label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Podkategorija publikacije:</label>

        <Select className="ubuntu-gray" theme={temaZaSelect} options={podkategorije} onChange={handlePodkategorijaSelect} defaultValue={filters.podkategorijaPublikacije} />

      </div>

      {filters.vrstaPublikacije.value === 1 ?
        (

          <div className="form-group lr-padding-rem padding-bottom border-left">
            <label className='ubuntu-dark-cond fw-bold small'>Indeksirano u:</label>
            <div>
              <label className='ubuntu-dark-cond small'>
                <input
                  type="checkbox"
                  checked={CCC}
                  onChange={handleCCC}
                />
                &nbsp;&nbsp;Current Contents Connect (CCC)
              </label>
            </div>
            <div>
              <label className='ubuntu-dark-cond small'>
                <input
                  type="checkbox"
                  checked={SCIEXP}
                  onChange={handleSCIEXP}
                />
                &nbsp;&nbsp;WoSCC - Science Citation Index Expanded (SCI-EXP)
              </label>
            </div>
            <div>
              <label className='ubuntu-dark-cond small'>
                <input
                  type="checkbox"
                  checked={ESCI}
                  onChange={handleESCI}
                />
                &nbsp;&nbsp;WoSCC - Emerging Sources Citation Index (ESCI)
              </label>
            </div>
            <div>
              <label className='ubuntu-dark-cond small'>
                <input
                  type="checkbox"
                  checked={Scopus}
                  onChange={handleScopus}
                />
                &nbsp;&nbsp;Scopus
              </label>
            </div>
            <div>
              <label className='ubuntu-dark-cond small'>
                <input
                  type="checkbox"
                  checked={neindeksirano}
                  onChange={handleNeindeksirano}
                />
                &nbsp;&nbsp;Ostalo / neindeksirano
              </label>
            </div>
          </div>

        )

        : (<div></div>)}
      <hr />
      <div className="form-group lr-padding-rem padding-top5">
        {/*<label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Kvartil:</label>*/}
        {/*<Select className="padding-bottom ubuntu-gray" theme={temaZaSelect} options={kvartili} onChange={handleKvartiliSelect} defaultValue={filters.kvartil} />*/}
        
        <div className="form-group row padding-bottom">
          <div className="col-md-6">
            <label className="ubuntu-dark-cond fw-bold padding-top5">Kvartil JCR - JIF:</label>
          </div>
          </div>
        <div className="padding-bottom">
          <div className="col-md-12">
            <Select className="padding-bottom ubuntu-gray" theme={temaZaSelect} isMulti={true} options={kvartili} onChange={handleKvartiliJCRSelect} value={filters.kvartilJCRMulti}/*defaultValue={filters.kvartilJCR}*/ />
          </div>
        </div>

        <div className="form-group row padding-bottom">
          <div className="col-md-6">
            <label className="ubuntu-dark-cond fw-bold padding-top5">Kvartil JCR - JCI:</label>
          </div>
          </div>
        <div className="padding-bottom">
          <div className="col-md-12">
            <Select className="padding-bottom ubuntu-gray" theme={temaZaSelect} isMulti={true} options={kvartili} onChange={handleKvartiliJCISelect} value={filters.kvartilJCIMulti}/*defaultValue={filters.kvartilJCR}*/ />
          </div>
        </div>

        <div className="form-group row padding-bottom">
          <div className="col-md-5">
            <label className="ubuntu-dark-cond fw-bold padding-top5">Kvartil SJR:</label>
          </div>
        </div>
        <div className="padding-bottom">
          <div className="col-md-12">
            <Select className="padding-bottom ubuntu-gray" theme={temaZaSelect} isMulti={true} options={kvartili} onChange={handleKvartiliSJRSelect} value={filters.kvartilSJRMulti}/*defaultValue={filters.kvartilSJR}*/ />
          </div>
        </div>        

        <div className="form-group row padding-top5">
          <div className="col-md-3">
          <label className="ubuntu-dark-cond fw-bold padding-top5">Povoljniji:</label>
          </div>
          <div className="col-md-9">
            <Select className="padding-bottom ubuntu-gray" theme={temaZaSelect} options={povoljnijiQ} onChange={handlePovoljnijiQSelect} defaultValue={filters.povoljnijiQ} />
          </div>

        </div>

      </div>
      <hr />

      <div className="form-group lr-padding-rem padding-bottom">
        <label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Inozemni časopis:</label>
        <Select className="ubuntu-gray" theme={temaZaSelect} options={inozemniCasopis} onChange={handleInozemniCasopisSelect} defaultValue={filters.inozemniCasopis} />
      </div>
      <hr />

      <div className="form-group lr-padding-rem padding-bottom">
        <label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Rad sa studentom:</label>
        <Select className="ubuntu-gray" theme={temaZaSelect} options={saStudentom} onChange={handleSaStudentomSelect} defaultValue={filters.radSaStudentom} />
        {/*
        <label class="radio">
          <input type="radio" value="Ne" name="saStudentom" /> Ne
          <span class="checkround"></span>
        </label>
        <label class="radio">
          <input type="radio" value="Da" name="saStudentom" /> Da
          <span class="checkround"></span>
        </label>
*/}
      </div>
      <hr />

      <div className="form-group lr-padding-rem padding-bottom">
        <label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Rad s doktorandom:</label>
        <Select className="ubuntu-gray" theme={temaZaSelect} options={sDoktorandom} onChange={handleSDoktorandomSelect} defaultValue={filters.radSDoktorandom} />

      </div>
      <hr />

      <div className="form-group lr-padding-rem padding-bottom">
        <label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Rad s gospodarstvom:</label>
        <Select className="ubuntu-gray" theme={temaZaSelect} options={sGospodarstvom} onChange={handleSGospodarstvomSelect} defaultValue={filters.radSGospodarstvom} />
        {/*
        <label class="radio">
          <input type="radio" value="Ne" name="sIndustrijom" /> Ne
          <span class="checkround"></span>
        </label>
        <label class="radio">
          <input type="radio" value="Da" name="sIndustrijom" /> Da
          <span class="checkround"></span>
        </label>
        */}
      </div>
      <hr />
      <div className="form-group lr-padding-rem padding-bottom">
        <label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Rad na projektu:</label>
        <Select className="ubuntu-gray" theme={temaZaSelect} options={naProjektu} onChange={handleNaProjektuSelect} defaultValue={filters.radNaProjektu} />
        {/*
        <label class="radio">
          <input type="radio" value="Ne" name="naProjektu" /> Ne
          <span class="checkround"></span>
        </label>
        <label class="radio">
          <input type="radio" value="Da" name="naProjektu" /> Da
          <span class="checkround"></span>
        </label>*/}
      </div>
      <hr />

      <div className="form-group lr-padding-rem padding-bottom">
        <label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Međuzavodski rad:</label>
        <Select className="ubuntu-gray" theme={temaZaSelect} options={medjuzavodskiRad} onChange={handleMedjuzavodskiSelect} defaultValue={filters.medjuzavodskiRad} />
        {/*
        <label class="radio">
          <input type="radio" value="Ne" name="naProjektu" /> Ne
          <span class="checkround"></span>
        </label>
        <label class="radio">
          <input type="radio" value="Da" name="naProjektu" /> Da
          <span class="checkround"></span>
        </label>*/}
      </div>
      <hr />

      <div className="form-group lr-padding-rem padding-bottom">
        <label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Rad s drugim znanstvenim institucijama:</label>
        <Select className="ubuntu-gray" theme={temaZaSelect} options={radSDrugimZnanInst} onChange={handleRadSDrugimZnanInstSelect} defaultValue={filters.radSDrugimZnanInst} />
      </div>

      <hr />
      <div className="form-group lr-padding-rem padding-bottom">
        <label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Rad s inozemnim znanstvenicima:</label>
        <Select className="ubuntu-gray" theme={temaZaSelect} options={radSInozemnimZnan} onChange={handleRadSInozemnimZnan} defaultValue={filters.radSInozemnimZnan} />
      </div>
      <hr />

      <div className="form-group lr-padding-rem padding-bottom">
        <label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Rad u otvorenom pristupu:</label>
        <Select className="ubuntu-gray" theme={temaZaSelect} options={otvoreniPristup} onChange={handleRadOtvoreniPristup} defaultValue={filters.radOtvoreniPristup} />
      </div>
      <hr />

      <div className="form-group lr-padding-rem padding-bottom">
        <label for="selectType" className="ubuntu-dark-cond fw-bold padding-bottom">Rad van ustanove:</label>
        <Select className="ubuntu-gray" theme={temaZaSelect} options={vanUstanove} onChange={handleVanUstanoveSelect} defaultValue={filters.vanUstanove} />

      </div>
      <hr />

      {/*<div className="text-center">
        <button className="btn btn-secondary rounded-corners " type="submit" onChange={() => childToParent("ovo će biti predano radovima")}>Filtriraj&nbsp;&nbsp;<FontAwesomeIcon className="ubuntu-white" icon="filter" /></button>

      </div>*/}
    </div>
  );
}

export default Filteri;