import React, { useState, useRef } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { useLocation, useHistory, useParams, Link, NavLink, Navigate ,useNavigate} from "react-router-dom"
import { fab } from '@fortawesome/free-brands-svg-icons'
import axios from "axios"
import moment from "moment"
import DatePicker from 'react-datepicker'
import { faCheckSquare, faCoffee, faSignOutAlt, faPlusSquare, faSave, faSearch, faPlus, faServer, faUser, faArrowCircleUp, faArrowCircleDown, faFilter, faEdit, faChevronLeft, faTrash, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { useSSRSafeId } from "@react-aria/ssr"
import Select from "react-select";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
toast.configure()

library.add(fab, faCheckSquare, faCoffee, faSignOutAlt, faPlusSquare, faSave, faSearch, faPlus, faServer, faUser, faArrowCircleUp, faArrowCircleDown, faFilter, faEdit, faChevronLeft, faTrash, faLock, faLockOpen)

library.add(fab, faCheckSquare, faCoffee, faSignOutAlt, faPlusSquare, faSearch, faPlus, faServer, faUser, faArrowCircleUp, faArrowCircleDown, faFilter, faEdit, faChevronLeft)

//axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwt')}`;

let postsInProgress = 0;

function UrediRad(props) {

  const prijavljeniKorisnik = useSelector(state => state.prijavljeniKorisnik);

  const navigate = useNavigate();

  let { id } = useParams();

  const [smijeSeIzvrsiti, setSmijeSeIzvrsiti] = useState(0);

  const [repo, setRepo] = React.useState(null);

  //const [datumObjave, setDatumObjave] = useState(new Date());
  const [datumObjave, setDatumObjave] = useState();

  const [saStudentom, setSaStudentom] = useState(0);
  const [sGospodarstvom, setSGospodarstvom] = useState(0);
  const [naProjektu, setNaProjektu] = useState(0);
  const [medjuzavodskiRad, setMedjuzavodskiRad] = useState(0);
  const [radSDrugimZnanInst, setRadSDrugimZnanInst] = useState(0);
  const [radSInozemnimZnan, setRadSInozemnimZnan] = useState(0);
  const [sDoktorandom, setSDoktorandom] = useState(0);
  const [impactFactor, setImpactFactor] = useState(0.0);
  const [citationIndicator, setCitationIndicator] = useState(0.0);
  const [inozemni, setInozemni] = useState(0);
  const [datumObjaveLock, setDatumObjaveLock] = useState(0);
  const [otvoreniPristup, setOtvoreniPristup] = useState(0);
  const [vanUstanove, setVanUstanove] = useState(0);
  const [duplikat, setDuplikat] = useState(0);
  

  const [smijeUrediti, setSmijeUrediti] = useState(false);
  const [rBrAutora, setRBrAutora] = useState(0);

  const [IDMojRad, setIDMojRad] = useState(null);

  const [glAutor1, setGlAutor1] = useState({ label: "-", value: "nitko" })
  const [glAutor1rbr, setGlAutor1rbr] = useState()
  
  const handleGlAutor1 = (e) => {
    setGlAutor1({ value: e.value, label: e.label });
    listaAutoraCrorisMix.map((post, index) => {
       if (e.label === post.label) setGlAutor1rbr(index);
    })
    
  }

  const [glAutor2, setGlAutor2] = useState({ label: "-", value: "nitko" })
  const [glAutor2rbr, setGlAutor2rbr] = useState()

  const handleGlAutor2 = (e) => {
    setGlAutor2({ value: e.value, label: e.label });
    listaAutoraCrorisMix.map((post, index) => {
      if (e.label === post.label) setGlAutor2rbr(index);
    })
    
  }

  const [JCRKategorije, setJCRKategorije] = useState(null);
  const [JCIKategorije, setJCIKategorije] = useState(null);
  const [SJRKategorije, setSJRKategorije] = useState(null);

  let kvartili = [
    /*{ label: "-", value: 0 },*/
    { label: "Q1", value: 1 },
    { label: "Q2", value: 2 },
    { label: "Q3", value: 3 },
    { label: "Q4", value: 4 }
  ]



  const [idJCR1Q, setIdJCR1Q] = useState(null)
  const [idJCR2Q, setIdJCR2Q] = useState(null)
  const [idJCI1Q, setIdJCI1Q] = useState(null)
  const [idJCI2Q, setIdJCI2Q] = useState(null)
  const [idSJR1Q, setIdSJR1Q] = useState(null)
  const [idSJR2Q, setIdSJR2Q] = useState(null)

  const [dohvacenJCR1Q, setDohvacenJCR1Q] = useState({ label: "-", value: 0 })
  const [dohvacenJCR2Q, setDohvacenJCR2Q] = useState({ label: "-", value: 0 })
  const [dohvacenJCI1Q, setDohvacenJCI1Q] = useState({ label: "-", value: 0 })
  const [dohvacenJCI2Q, setDohvacenJCI2Q] = useState({ label: "-", value: 0 })
  const [dohvacenSJR1Q, setDohvacenSJR1Q] = useState({ label: "-", value: 0 })
  const [dohvacenSJR2Q, setDohvacenSJR2Q] = useState({ label: "-", value: 0 })

  const [dohvaceneJCR1Kat, setDohvaceneJCR1Kat] = useState({ label: "-", value: 0 })
  const [dohvaceneJCR2Kat, setDohvaceneJCR2Kat] = useState({ label: "-", value: 0 })  
  const [dohvaceneJCI1Kat, setDohvaceneJCI1Kat] = useState({ label: "-", value: 0 })
  const [dohvaceneJCI2Kat, setDohvaceneJCI2Kat] = useState({ label: "-", value: 0 })
  const [dohvaceneSJR1Kat, setDohvaceneSJR1Kat] = useState({ label: "-", value: 0 })
  const [dohvaceneSJR2Kat, setDohvaceneSJR2Kat] = useState({ label: "-", value: 0 })

  const clearJCR1 = () => {
    setDohvacenJCR1Q({ label: "-", value: 0 });
    setDohvaceneJCR1Kat({ label: "-", value: 0 });
    setJCR1Q(0);
    setJCR1Kat(0);
    console.log("clearing value ", JCR1Q, JCR1Kat);
    console.log("clearing dohvacenvalue ", dohvacenJCR1Q, dohvaceneJCR1Kat);
  }

  const clearJCR2 = () => {
    setDohvacenJCR2Q({ label: "-", value: 0 });
    setDohvaceneJCR2Kat({ label: "-", value: 0 });
    setJCR2Q(0);
    setJCR2Kat(0);   
  }

  const clearJCI1 = () => {
    setDohvacenJCI1Q({ label: "-", value: 0 });
    setDohvaceneJCI1Kat({ label: "-", value: 0 });
    setJCI1Q(0);
    setJCI1Kat(0);    
  }

  const clearJCI2 = () => {
    setDohvacenJCI2Q({ label: "-", value: 0 });
    setDohvaceneJCI2Kat({ label: "-", value: 0 });
    setJCI2Q(0);
    setJCI2Kat(0);   
  }

  const clearSJR1 = () => {
    setDohvacenSJR1Q({ label: "-", value: 0 });
    setDohvaceneSJR1Kat({ label: "-", value: 0 });
    setSJR1Q(0);
    setSJR1Kat(0);   
  }

  const clearSJR2 = () => {
    setDohvacenSJR2Q({ label: "-", value: 0 });
    setDohvaceneSJR2Kat({ label: "-", value: 0 });
    setSJR2Q(0);
    setSJR2Kat(0);   
  }

  const [JCR1Q, setJCR1Q] = useState(null);
  const handleJCR1Q = (e) => {
    setJCR1Q(e.value);
    setDohvacenJCR1Q(e); //ovo sam ubacio jer se prebacujem na value umjesto defaultvalue ali zato cijelo vrijeme handlam kompletan odabir (label i value) u dropdownu
    console.log("odabran je prvi jcr q" + JCR1Q);
  }
  const [JCR2Q, setJCR2Q] = useState(null);
  const handleJCR2Q = (e) => {
    setJCR2Q(e.value);
    setDohvacenJCR2Q(e);
    console.log("odabran je drugi jcr q" + JCR2Q);
  }
  const [JCI1Q, setJCI1Q] = useState(null);
  const handleJCI1Q = (e) => {
    setJCI1Q(e.value);
    setDohvacenJCI1Q(e); //ovo sam ubacio jer se prebacujem na value umjesto defaultvalue ali zato cijelo vrijeme handlam kompletan odabir (label i value) u dropdownu
  }
  const [JCI2Q, setJCI2Q] = useState(null);
  const handleJCI2Q = (e) => {
    setJCI2Q(e.value);
    setDohvacenJCI2Q(e);
  }
  const [SJR1Q, setSJR1Q] = useState(null);
  const handleSJR1Q = (e) => {
    setSJR1Q(e.value);
    setDohvacenSJR1Q(e);
    console.log("odabran je prvi sjr q" + SJR1Q);
  }
  const [SJR2Q, setSJR2Q] = useState(null);
  const handleSJR2Q = (e) => {
    setSJR2Q(e.value);
    setDohvacenSJR2Q(e)
    console.log("odabran je drugi sjr q" + SJR2Q);
  }

  const [JCR1Kat, setJCR1Kat] = useState(null);
  const handleJCR1Kat = (e) => {
    setJCR1Kat(e.value);
    setDohvaceneJCR1Kat(e);
    console.log("odabrana je prva jcr kat " + JCR1Kat);
  }
  const [JCR2Kat, setJCR2Kat] = useState(null);
  const handleJCR2Kat = (e) => {
    setJCR2Kat(e.value);
    setDohvaceneJCR2Kat(e);
    console.log("odabrana je druga jcr kat " + JCR2Kat);
  }
  const [JCI1Kat, setJCI1Kat] = useState(null);
  const handleJCI1Kat = (e) => {
    setJCI1Kat(e.value);
    setDohvaceneJCI1Kat(e);    
  }
  const [JCI2Kat, setJCI2Kat] = useState(null);
  const handleJCI2Kat = (e) => {
    setJCI2Kat(e.value);
    setDohvaceneJCI2Kat(e);
  }
  const [SJR1Kat, setSJR1Kat] = useState(null);
  const handleSJR1Kat = (e) => {
    setSJR1Kat(e.value);
    setDohvaceneSJR1Kat(e);
    console.log("odabrana je prva sjr kat " + SJR1Kat);
  }
  const [SJR2Kat, setSJR2Kat] = useState(null);
  const handleSJR2Kat = (e) => {
    setSJR2Kat(e.value);
    setDohvaceneSJR2Kat(e);
    console.log("odabrana je druga sjr kat " + SJR2Kat);
  }

  const handleChangeIF = event => {
    setImpactFactor(event.target.value);
  };

  const handleChangeJCI = event => {
    setCitationIndicator(event.target.value);
  };


  React.useEffect(() => {
    
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwt')}`;

    axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-id/' + id).then((response) => {
      setRepo(response.data);
      console.log("uredi rad: dobio sam korisnika", prijavljeniKorisnik)
      if (response.data.data[0].datum_objave != '0000-00-00') setDatumObjave(new Date(moment(response.data.data[0].datum_objave).format("YYYY-MM-DD")));

      if (response.data.data[0].rad_sa_gospodarstvom == 0) setSGospodarstvom(0); else setSGospodarstvom(1);
      if (response.data.data[0].rad_na_projektu == 0) setNaProjektu(0); else setNaProjektu(1);
      if (response.data.data[0].medjuzavodski_rad == 0) setMedjuzavodskiRad(0); else setMedjuzavodskiRad(1);
      if (response.data.data[0].rad_s_drugim_znanstvenim_institucijama == 0) setRadSDrugimZnanInst(0); else setRadSDrugimZnanInst(1);
      if (response.data.data[0].rad_s_inozemnim_znanstvenicima == 0) setRadSInozemnimZnan(0); else setRadSInozemnimZnan(1);
      setImpactFactor(response.data.data[0].impact_factor_casopisa);
      setCitationIndicator(response.data.data[0].jci_casopisa);
      if (response.data.data[0].inozemni == 0) setInozemni(0); else setInozemni(1);
      if (response.data.data[0].datum_objave_lock == 0) setDatumObjaveLock(0); else setDatumObjaveLock(1);
      if (response.data.data[0].rad_u_otvorenom_pristupu == 0) setOtvoreniPristup(0); else setOtvoreniPristup(1);
      if (response.data.data[0].duplikat == 0) setDuplikat(0); else setDuplikat(1);
      //console.log("api kaze:" + response.data.data[0].rad_sa_studentom);
      //console.log("dat_lock: "+ response.data.data[0].datumObjaveLock);

      

      let rBrAut = 0;

      //ovo je ekstra dodatak da popuni glavnog autora u dropdownu ako nije s ustanove tj nije na croris listi autora
      let autori = response.data.data[0].autori.split(';').map(autor => autor.trim());
      autori.map((post, index) => {
        let prez_ime = autori[index].split(',').map(author => author.trim());

        
        if (response.data.data[0].glavni_autor1 === '' && response.data.data[0].glautor1_rbr === "" + (index + 1)) {
          
          setGlAutor1({value: '', label: prez_ime[1] + ' ' + prez_ime[0]});
          setGlAutor1rbr(index + 1);
        }
        if (response.data .data[0].glavni_autor2 === '' && response.data.data[0].glautor2_rbr === "" + (index + 1)) {
          setGlAutor2({value: '', label: prez_ime[1] + ' ' + prez_ime[0]});
          setGlAutor2rbr(index + 1);          
        }
      })
      //kraj dodatka
    
      response.data.data[0].autori_na_radu.forEach(function (val, idx) {
        console.log("autor " + idx + " je " + val.aai);
        if (val.aai == props.korisnik && props.korisnik != 'Anonimni korisnik' && props.korisnik != '') {
          setSmijeUrediti(true);
          setIDMojRad(val.id);
          console.log("r br autora se postavlja na " + idx);
          setRBrAutora(idx);
          rBrAut = idx;
          console.log("sa studentom se postavlja na " + val.rad_sa_studentom);
          setSaStudentom(val.rad_sa_studentom);
          setSDoktorandom(val.rad_s_doktorandom);
          setVanUstanove(val.rad_van_ustanove);
        }        
        console.log("r br autora koji uređuje je: " + rBrAutora);

        if(val.aai === response.data.data[0].glavni_autor1 && val.aai!=""){
          setGlAutor1({value: response.data.data[0].glavni_autor1, label: val.ime + " " + val.prezime});
          setGlAutor1rbr(response.data.data[0].glautor1_rbr);
        }
        else if(val.aai === "" && response.data.data[0].glautor1_rbr === ""+(idx+1))
        {
          setGlAutor1({value: response.data.data[0].glavni_autor1, label: val.ime + " " + val.prezime});
          setGlAutor1rbr(response.data.data[0].glautor1_rbr);
        }



        if(val.aai === response.data.data[0].glavni_autor2 && val.aai!="")
        {
          setGlAutor2({value: response.data.data[0].glavni_autor2, label: val.ime + " " + val.prezime});
          setGlAutor2rbr(response.data.data[0].glautor2_rbr);
        }
        else if(val.aai === "" && response.data.data[0].glautor2_rbr === ""+(idx+1))
        {
          setGlAutor2({value: response.data.data[0].glavni_autor2, label: val.ime + " " + val.prezime});
          setGlAutor2rbr(response.data.data[0].glautor2_rbr);
        }
      });


      let tempDohvacenID = [null, null];
      let tempDohvaceneKat = [{ label: null, value: null }, { label: null, value: null }];
      let tempDohvacenQ = [{ label: null, value: null }, { label: null, value: null }];

      response.data.data[0].autori_na_radu[rBrAut].kvartili_autora_na_radu.filter((post) => {
        if (post.naziv_kategorizacije.toLowerCase().includes('jcr')) {
          return post;
        }
      }).map((post, index) => {
        tempDohvacenID[index] = post.id;
        tempDohvacenQ[index] = {
          label: post.kvartil==="0" ? "-":"Q" + post.kvartil,
          value: post.kvartil
        }
        tempDohvaceneKat[index] = {
          label: post.kvartil==="0" ? "-":post.naziv_kategorije,
          value: post.id_kategorije
        }

      })


      console.log("Dohvacen Q:", tempDohvacenQ[0]);
      console.log("Dohvacene kat:", tempDohvaceneKat[0]);
      console.log("Ja sam autor:", rBrAut);


      setIdJCR1Q(tempDohvacenID[0]);
      setIdJCR2Q(tempDohvacenID[1]);
      setDohvacenJCR1Q(tempDohvacenQ[0]);
      setDohvacenJCR2Q(tempDohvacenQ[1]);
      setDohvaceneJCR1Kat(tempDohvaceneKat[0]);
      setDohvaceneJCR2Kat(tempDohvaceneKat[1]);
      setJCR1Q(tempDohvacenQ[0].value);
      setJCR2Q(tempDohvacenQ[1].value);
      setJCR1Kat(tempDohvaceneKat[0].value);
      setJCR2Kat(tempDohvaceneKat[1].value);

      tempDohvacenID = [null, null];
      tempDohvaceneKat = [{ label: null, value: null }, { label: null, value: null }];
      tempDohvacenQ = [{ label: null, value: null }, { label: null, value: null }];

      response.data.data[0].autori_na_radu[rBrAut].kvartili_autora_na_radu.filter((post) => {
        if (post.naziv_kategorizacije.toLowerCase().includes('jci')) {
          return post;
        }
      }).map((post, index) => {
        tempDohvacenID[index] = post.id;
        tempDohvacenQ[index] = {
          label: post.kvartil==="0" ? "-":"Q" + post.kvartil,
          value: post.kvartil
        }
        tempDohvaceneKat[index] = {
          label: post.kvartil==="0" ? "-":post.naziv_kategorije,
          value: post.id_kategorije
        }

      })

      setIdJCI1Q(tempDohvacenID[0]);
      setIdJCI2Q(tempDohvacenID[1]);
      setDohvacenJCI1Q(tempDohvacenQ[0]);
      setDohvacenJCI2Q(tempDohvacenQ[1]);
      setDohvaceneJCI1Kat(tempDohvaceneKat[0]);
      setDohvaceneJCI2Kat(tempDohvaceneKat[1]);
      setJCI1Q(tempDohvacenQ[0].value);
      setJCI2Q(tempDohvacenQ[1].value);
      setJCI1Kat(tempDohvaceneKat[0].value);
      setJCI2Kat(tempDohvaceneKat[1].value);

      tempDohvacenID = [null, null];
      tempDohvaceneKat = [{ label: null, value: null }, { label: null, value: null }];
      tempDohvacenQ = [{ label: null, value: null }, { label: null, value: null }];

      response.data.data[0].autori_na_radu[rBrAut].kvartili_autora_na_radu.filter((post) => {
        if (post.naziv_kategorizacije.toLowerCase().includes('sjr')) {
          return post;
        }
      }).map((post, index) => {
        tempDohvacenID[index] = post.id;
        tempDohvacenQ[index] = {
          label: post.kvartil==="0" ? "-":"Q" + post.kvartil,
          value: post.kvartil
        }
        tempDohvaceneKat[index] = {
          label: post.kvartil==="0" ? "-":post.naziv_kategorije,
          value: post.kvartil==="0" ? "0":post.id_kategorije
        }
      })


      setIdSJR1Q(tempDohvacenID[0]);
      setIdSJR2Q(tempDohvacenID[1]);
      setDohvacenSJR1Q(tempDohvacenQ[0]);
      setDohvacenSJR2Q(tempDohvacenQ[1]);
      setDohvaceneSJR1Kat(tempDohvaceneKat[0]);
      setDohvaceneSJR2Kat(tempDohvaceneKat[1]);
      setSJR1Q(tempDohvacenQ[0].value);
      setSJR2Q(tempDohvacenQ[1].value);
      setSJR1Kat(tempDohvaceneKat[0].value);
      setSJR2Kat(tempDohvaceneKat[1].value);

      console.log(props.korisnik + "smije urediti: " + smijeUrediti + " moj rad: " + IDMojRad + " s podacima: ");
    });



    axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/kategorije/jcr').then((response) => {
      setJCRKategorije(response.data);
    });

    axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/kategorije/jci').then((response) => {
      setJCIKategorije(response.data);
    });

    axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/kategorije/sjr').then((response) => {
      setSJRKategorije(response.data);
    });
  }, []);



  if (!repo) return null;
  if (!JCRKategorije) return null;
  if (!JCIKategorije) return null;
  if (!SJRKategorije) return null;

  let listaAutora = [];
  listaAutora.push({label: "-", value: "nitko"})
  repo.data[0].autori_na_radu.map((post, index) => {
    let res = {label: repo.data[0].autori_na_radu[index].ime + " " + repo.data[0].autori_na_radu[index].prezime, value: repo.data[0].autori_na_radu[index].aai};
    listaAutora.push(res);
  });

  console.log("Lista autora:",listaAutora);

  //ovdje pocinju izmjene da radi s novim crosbijem (koji ne daje sve autore na radu)
  let listaAutoraCrorisMix = [];
  listaAutoraCrorisMix.push({label: "-", value: "nitko"})  

  const autori = repo.data[0].autori.split(';').map(autor => autor.trim());
  console.log("const autori:",autori);

 
  
  autori.map((post, index)=>{
    let prez_ime = autori[index].split(',').map(author => author.trim());

    let imaLink = false;
    let indLink = 0;

    repo.data[0].autori_na_radu.map((post, index) => {
      let res = repo.data[0].autori_na_radu[index].ime + " " + repo.data[0].autori_na_radu[index].prezime + "; ";
      let res2 = prez_ime[1] + " " + prez_ime[0]+"; ";
      if(res===res2){
        imaLink=true;                
        indLink=index;
      }
    }) 
    
    let lab='';
    let val='';
    if(imaLink==true){
      lab = repo.data[0].autori_na_radu[indLink].ime + " " + repo.data[0].autori_na_radu[indLink].prezime;
      val = repo.data[0].autori_na_radu[indLink].aai;
    }
    else{
      lab = prez_ime[1] + " " + prez_ime[0];
      val = '';
    }

    let item = {label: lab, value: val};
    listaAutoraCrorisMix.push(item);    
  

  })

  console.log("listaAutoraCrorisMix: ", listaAutoraCrorisMix);
//ovdje zavrsavaju izmjene*/
  


  let listaNasihAutora = [];
  listaNasihAutora.push({label: "-", value: "nitko"})
  repo.data[0].autori_na_radu.map((post, index) => {
    let res = {label: repo.data[0].autori_na_radu[index].ime + " " + repo.data[0].autori_na_radu[index].prezime, value: repo.data[0].autori_na_radu[index].aai};
    if( repo.data[0].autori_na_radu[index].aai!='')listaNasihAutora.push(res);
  });

  let listaJCRKategorije = [];
  let dropDownEle = { label: "-", value: "0" };
  
  //listaJCRKategorije.push(dropDownEle);
  
  JCRKategorije.data.forEach(element => {
    let dropDownEle = { label: element["naziv_kategorije"], value: element["id"] };
    listaJCRKategorije.push(dropDownEle);
  });

  let listaJCIKategorije = [];

  JCIKategorije.data.forEach(element => {
    let dropDownEle = { label: element["naziv_kategorije"], value: element["id"] };
    listaJCIKategorije.push(dropDownEle);
  });

  let listaSJRKategorije = [];
  let dropDownEle2 = { label: "-", value: "0" };
  
  //listaSJRKategorije.push(dropDownEle2);
  
  SJRKategorije.data.forEach(element => {
    let dropDownEle2 = { label: element["naziv_kategorije"], value: element["id"] };
    listaSJRKategorije.push(dropDownEle2);
  });


  function wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }



  const post = async () => {

    let validacija = 0;
    console.log("jwt koji šalje post: ",localStorage.getItem('jwt'));
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwt')}`;
    postsInProgress = 0;
    postsInProgress++;
    //if (smijeUrediti) {
    console.log(props.korisnik + ' smije urediti');
    console.log('datum objave: ', datumObjave);


    await axios.post('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad/' + id, {
      id: id,
      glavni_autor1: glAutor1.value,
      glautor1_rbr: glAutor1rbr,
      glavni_autor2: glAutor2.value,
      glAutor2_rbr: glAutor2rbr,
      datum_objave: datumObjave,
      datum_objave_lock: datumObjaveLock,
      //rad_sa_studentom: saStudentom,
      rad_u_otvorenom_pristupu: otvoreniPristup,
      rad_sa_gospodarstvom: sGospodarstvom,
      rad_na_projektu: naProjektu,
      medjuzavodski_rad: medjuzavodskiRad,
      rad_s_drugim_znanstvenim_institucijama: radSDrugimZnanInst,
      rad_s_inozemnim_znanstvenicima: radSInozemnimZnan,
      impact_factor_casopisa: impactFactor,
      jci_casopisa: citationIndicator,
      inozemni: inozemni,
      duplikat: duplikat
    },{withCredentials: true})
      .then(function (response) {
        console.log("prvi post response:", response);
        postsInProgress--;
        console.log("csbp prvi:" + postsInProgress);
        console.log("citation inicator", citationIndicator);
      })
      .catch(function (error) {
        console.log("prvi post response:", error);
        postsInProgress--;
      });
    console.log()
    //this.forceUpdate();
    //}

    let post_q_url = '';

    console.log(idJCR1Q);
    console.log(idJCR2Q);
    console.log(idSJR1Q);
    console.log(idSJR2Q);
    console.log(dohvaceneJCR1Kat);
    console.log(dohvaceneJCR2Kat);
    console.log(dohvaceneSJR1Kat);
    console.log(dohvaceneSJR2Kat);
    console.log("šaljem kvartile: \n" + IDMojRad + "\n " + idJCR1Q + " " + JCR1Q + " " + JCR1Kat + "\n " + idJCR2Q + " " + JCR2Q + " " + JCR2Kat + "\n " + idSJR1Q + " " + SJR1Q + " " + SJR1Kat + "\n " + idSJR2Q + " " + SJR2Q + " " + SJR2Kat);


    if (idJCR1Q == null) post_q_url = 'https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-kvartili-insert';
    else post_q_url = 'https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-kvartili-update/' + idJCR1Q;
    if (JCR1Q != null && JCR1Kat != null) {
      console.log("usao u izmjenu prvog jcr", JCR1Q, " kat ", JCR1Kat);
      postsInProgress++;
      await axios.post(post_q_url, {
        id: idJCR1Q,
        id_autor_rad: IDMojRad,
        id_kategorije: JCR1Q===0 ? 0 : JCR1Kat,
        kvartil: JCR1Kat===0 ? 0 : JCR1Q
      },{withCredentials: true})
        .then(function (response) {
          postsInProgress--;
          console.log("response zapisa prvog q",response);
        })
        .catch(function (error) {
          postsInProgress--;
          console.log("error zapisa prvog q",error);
        });
    }
    else validacija++;


    if (idJCR2Q == null) post_q_url = 'https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-kvartili-insert';
    else post_q_url = 'https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-kvartili-update/' + idJCR2Q;
    if (JCR2Q != null && JCR2Kat != null) {
      postsInProgress++;
      await axios.post(post_q_url, {
        id: idJCR2Q,
        id_autor_rad: IDMojRad,
        id_kategorije: JCR2Q===0 ? 0 : JCR2Kat,
        kvartil: JCR2Kat===0 ? 0 : JCR2Q
      },{withCredentials: true})
        .then(function (response) {
          postsInProgress--;
          console.log(response);
        })
        .catch(function (error) {
          postsInProgress--;
          console.log(error);
        });
      console.log()
    }
    else validacija++;


    if (idJCI1Q == null) post_q_url = 'https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-kvartili-insert';
    else post_q_url = 'https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-kvartili-update/' + idJCI1Q;
    if (JCI1Q != null && JCI1Kat != null) {
      postsInProgress++;
      await axios.post(post_q_url, {
        id: idJCI1Q,
        id_autor_rad: IDMojRad,
        id_kategorije: JCI1Q===0 ? 0 : JCI1Kat,
        kvartil: JCI1Kat===0 ? 0 : JCI1Q
      },{withCredentials: true})
        .then(function (response) {
          postsInProgress--;
        })
        .catch(function (error) {
          postsInProgress--;
        });
    }
    else validacija++;


    if (idJCI2Q == null) post_q_url = 'https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-kvartili-insert';
    else post_q_url = 'https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-kvartili-update/' + idJCI2Q;
    if (JCI2Q != null && JCI2Kat != null) {
      postsInProgress++;
      await axios.post(post_q_url, {
        id: idJCI2Q,
        id_autor_rad: IDMojRad,
        id_kategorije: JCI2Q===0 ? 0 : JCI2Kat,
        kvartil: JCI2Kat===0 ? 0 : JCI2Q
      },{withCredentials: true})
        .then(function (response) {
          postsInProgress--;
        })
        .catch(function (error) {
          postsInProgress--;
        });
    }
    else validacija++;


    if (idSJR1Q == null) post_q_url = 'https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-kvartili-insert';
    else post_q_url = 'https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-kvartili-update/' + idSJR1Q;
    if (SJR1Q != null && SJR1Kat != null) {
      postsInProgress++;
      await axios.post(post_q_url, {
        id: idSJR1Q,
        id_autor_rad: IDMojRad,
        id_kategorije: SJR1Q===0 ? 0 : SJR1Kat,
        kvartil: SJR1Kat===0 ? 0 : SJR1Q
      },{withCredentials: true})
        .then(function (response) {
          postsInProgress--;
          console.log(response);
        })
        .catch(function (error) {
          postsInProgress--;
          console.log(error);
        });
      console.log()
    }
    else validacija++;

    if (idSJR2Q == null) post_q_url = 'https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-kvartili-insert';
    else post_q_url = 'https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-kvartili-update/' + idSJR2Q;
    if (SJR2Q != null && SJR2Kat != null) {
      postsInProgress++;
      await axios.post(post_q_url, {
        id: idSJR2Q,
        id_autor_rad: IDMojRad,
        id_kategorije: SJR2Q===0 ? 0 : SJR2Kat,
        kvartil: SJR2Kat===0 ? 0 : SJR2Q
      },{withCredentials: true})
        .then(function (response) {
          postsInProgress--;
          console.log(response);
        })
        .catch(function (error) {
          postsInProgress--;
          console.log(error);
        });
      console.log()
    }
    else validacija++;

    postsInProgress++;
    await axios.post('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-autori-update/' + IDMojRad, {
      id: IDMojRad,
      rad_sa_studentom: saStudentom,
      rad_s_doktorandom: sDoktorandom,
      rad_van_ustanove: vanUstanove
    },{withCredentials: true})
      .then(function (response) {

        postsInProgress--;
        console.log(response);
        console.log("csbp zadnji:" + postsInProgress);
        //history.push('/rad/1116');
      })
      .catch(function (error) {
        postsInProgress--;
        console.log(error);
      });
    console.log()



    if (postsInProgress == 0) {
      toast.success('Podaci su uspješno pohranjeni.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autClose: 2000,
        transition: Slide
      });
    }

    /*if (validacija >= 0) {

      toast.warning('Neki podaci su nepotpuni i nisu pohranjeni.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autClose: 2000,
        transition: Slide
      });
    }
    else {

      setSmijeSeIzvrsiti(1);

    }*/

    navigate(`/rad/${id}`)
    //wait(1000);

    /*while (postsInProgress != 0) {
      console.log("pip:" + postsInProgress);
    };*/

    //window.location.href = '/radovi';

  }

  const temaZaSelect = (theme) => ({
    ...theme,
    borderRadius: 5,
    colors: {
      ...theme.colors,
      text: 'orangered',
      primary25: 'lightGrey',
      primary50: 'lightGrey',
      primary: 'gray',
    },
  })

  const handleKopiraj = (e)=>{

    setJCR1Q(e.kvartil);
    setJCR1Kat(e.naziv_kategorije);
    console.log("handle kopiraj:", e.kvartil +" "+ e.naziv_kategorije);

    let temp = {
      label: "Q" + e.kvartil,
      value: e.kvartil
    }


    
  }


  return (
    1?(
    <div className="ubuntu-dark">

      <div className="row">
      <div className="col-md-3 border-right align-left">
          <Link className="ubuntu-result-bold" to={`/rad/${id}`} ><FontAwesomeIcon className="ubuntu-dark-cond" icon="chevron-left" />&nbsp;&nbsp;Natrag</Link>
        </div>
        <div className="col-md-9">
          <p className="ubuntu-gray align-left mobile-right">Uređuju se podaci o radu # {id}</p>
        </div>

      </div>
      <hr />
      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15">
          <strong>Naslov</strong>
        </div>
        <div className="col-md-9 padding15 fw-bold fw-underline faded">
          {repo.data[0].naslov_en}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Autori</strong>
        </div>
        <div className="col-md-9 padding15 faded">
          {repo.data[0].autori}
          {/*
            repo.data[0].autori_na_radu.map((post, index) => {
              return repo.data[0].autori_na_radu[index].ime + " " + repo.data[0].autori_na_radu[index].prezime + "; ";
            })
          */}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Glavni autor 1</strong>
          <div><text className="ubuntu-dark-cond small">(nositelj problematike)</text></div>
        </div>
        <div className="col-md-9 padding15">
          <Select className="col-md-4 mb-1 mt-1" theme={temaZaSelect} options={listaAutoraCrorisMix} defaultValue={glAutor1} onChange={(e) => {handleGlAutor1(e)}} />              
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Glavni autor 2</strong>
          <div><text className="ubuntu-dark-cond small">(najviše pridonio rješavanju problema)</text></div>
        </div>
        <div className="col-md-9 padding15">
          <Select className="col-md-4 mb-1 mt-1" theme={temaZaSelect} options={listaAutoraCrorisMix} defaultValue={glAutor2} onChange={(e) => { handleGlAutor2(e) }} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Publikacija</strong>
        </div>
        <div className="col-md-9 padding15 faded">
          {repo.data[0].naziv_casopisa}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Godina izdavanja</strong>
        </div>
        <div className="col-md-9 padding15 faded">
          {repo.data[0].godina}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Datum izdavanja</strong>
        </div>
        
        <div className="col-md-2 padding15">
          <DatePicker className="ubuntu-dark nice-textbox" disabled={repo.data[0].datum_objave_lock==='1'} selected={datumObjave} onChange={(date) => { if(date==null)setDatumObjave(date); else setDatumObjave(new Date(moment(date).format("YYYY-MM-DD")))}} dateFormat="dd.MM.yyyy" showYearDropdown scrollableYearDropdown />
          </div>
          <div className="col-md-7 padding15">
          {repo.data[0].datum_objave_lock==='1' ?
          <span className="ubuntu-dark"> &nbsp;&nbsp;<FontAwesomeIcon icon="lock" />&nbsp;&nbsp;&nbsp;&nbsp; </span>
          :
         <div> <span className="ubuntu-red"> &nbsp;&nbsp;<FontAwesomeIcon icon="lock-open" />&nbsp;&nbsp;&nbsp;&nbsp;</span>  

          <input type="checkbox" disabled={repo.data[0].datum_objave_lock==='1'}
            checked={datumObjaveLock=='1'}
            onChange={() => {setDatumObjaveLock((datumObjaveLock == 0 && datumObjave!= null) ? 1 : 0); 
              if(datumObjaveLock==0)toast.warning('Nakon što zaključate i spremite datum objave više ga nećete moći uređivati.', {
              position: toast.POSITION.BOTTOM_CENTER,
              autClose: 3000,
              transition: Slide
            });}
          }
          />
          &nbsp;&nbsp;Zaključavanje </div>
        }
        </div>

      </div>



      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Vrsta publikacije</strong>
        </div>
        <div className="col-md-9 padding15 faded">
          {repo.data[0].crosbi_kategorija}
        </div>
      </div>

      {(repo.data[0].crosbi_kategorija === "Radovi u zbornicima skupova" || repo.data[0].crosbi_kategorija === "Knjige i poglavlja u knjigama") ? (<div />) : (
      <div>
      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>JCR Journal Impact Factor (JIF)</strong>
        </div>
        <div className="col-md-9 padding15">
        <input className="nice-textbox" type="text" value={impactFactor}
            onChange={handleChangeIF}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>JCR Journal Citation Indicator (JCI)</strong>
        </div>
        <div className="col-md-9 padding15">
        <input className="nice-textbox" type="text" value={citationIndicator}
            onChange={handleChangeJCI}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>{repo.data[0].crosbi_kategorija != "Prilog u časopisu" ? "Inozemna publikacija" : "Inozemni časopis"}</strong>
        </div>
        <div className="col-md-9 padding15">
        <input type="checkbox"
            checked={inozemni == '1'}
            onChange={() => setInozemni(inozemni == 0 ? 1 : 0)}
          />
        </div>
      </div>
      </div>
      )}

      {/*
        <div className="row">
          <div className="col-md-3 align-right border-right padding15 ">
            <strong>Zavod</strong>
          </div>
      
          <div className="col-md-9 padding15">
            
          </div>
        </div>  */}
      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>DOI</strong>
        </div>
        <div className="col-md-9 padding15 faded">
          {repo.data[0].urls_doi}
        </div>
      </div>

      {/*
        <div className="row">
          <div className="col-md-3 align-right border-right padding15 ">
            <strong>Konferencija</strong>
          </div>
          <div className="col-md-9 padding15">
          
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 align-right border-right padding15 ">
            <strong>Naziv događaja</strong>
          </div>
          <div className="col-md-9 padding15">
            
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 align-right border-right padding15 ">
            <strong>Mjesto</strong>
          </div>
          <div className="col-md-9 padding15">
            
          </div>
        </div>
        */}
      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Izdanje/Broj</strong>
        </div>
        <div className="col-md-9 padding15 faded">
          {repo.data[0].volume}/{repo.data[0].no_issue}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Stranica</strong>

        </div>
        <div className="col-md-9 padding15 faded">
          {repo.data[0].paper_pages}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Rad u otvorenom pristupu</strong>
          <div><text className="ubuntu-dark-cond small fst-italic">(Open access)</text></div>
        </div>
        <div className="col-md-9 padding15">
          <input type="checkbox"
            checked={otvoreniPristup == '1'}
            onChange={() => setOtvoreniPristup(otvoreniPristup == 0 ? 1 : 0)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>URL</strong>
        </div>
        <div className="col-md-9 padding15 faded">
          {repo.data[0].urls_oa_urls}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Rad s gospodarstvom</strong>
        </div>
        <div className="col-md-9 padding15">
          <input type="checkbox"
            checked={sGospodarstvom == '1'}
            onChange={() => setSGospodarstvom(sGospodarstvom == 0 ? 1 : 0)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Rad na projektu</strong>
        </div>
        <div className="col-md-9 padding15">
          <input type="checkbox"
            defaultChecked={naProjektu == '1'}
            onChange={() => setNaProjektu(naProjektu == 0 ? 1 : 0)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Međuzavodski rad</strong>
        </div>
        <div className="col-md-9 padding15">
          <input type="checkbox"
            checked={medjuzavodskiRad == '1'}
            onChange={() => setMedjuzavodskiRad(medjuzavodskiRad == 0 ? 1 : 0)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Rad s drugim znanstvenim institucijama</strong>
        </div>
        <div className="col-md-9 padding15">
        <input type="checkbox"
            checked={radSDrugimZnanInst == '1'}
            onChange={() => setRadSDrugimZnanInst(radSDrugimZnanInst == 0 ? 1 : 0)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Rad s inozemnim znanstvenicima</strong>
        </div>
        <div className="col-md-9 padding15">
        <input type="checkbox"
            checked={radSInozemnimZnan == '1'}
            onChange={() => setRadSInozemnimZnan(radSInozemnimZnan == 0 ? 1 : 0)}
          />
        </div>
      </div>

      

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
        <hr/>
        <text className="ubuntu-gray-small align-left fw-bold"></text>
        </div>
        <div className="col-md-9 padding15">
        <hr/>
          <text className="ubuntu-gray-small align-left fw-bold">↓ INDIVIDUALNI PODACI ZA AUTORE ↓</text>
          
        </div>
      </div>  

        {
          (repo.data[0].crosbi_kategorija === "Radovi u zbornicima skupova" || repo.data[0].crosbi_kategorija === "Knjige i poglavlja u knjigama") ? (<div />) : (
            <div>

              <div className="row">
                <div className="col-md-3 align-left-right border-right padding15 ">
                  <strong>Moji kvartili</strong>
                </div>
                <div className="row col-md-9 padding15">
                  <div className="col-md-5 left border-right ubuntu-dark-cond">
                    <span className="border-bottom"><strong>JCR </strong> - Journal Impact Factor (JIF)</span><br />
                    <span className="row">
                      <Select className="col-md-3 mb-1 mt-1" theme={temaZaSelect} options={kvartili} value={dohvacenJCR1Q} /*defaultValue={dohvacenJCR1Q}*/ onChange={(e) => { handleJCR1Q(e) }} />
                      <Select className="col-md-8 mb-1 mt-1" theme={temaZaSelect} options={listaJCRKategorije} value={dohvaceneJCR1Kat} /*defaultValue={dohvaceneJCR1Kat}*/ onChange={(e) => { handleJCR1Kat(e) }} />
                      <button className="col-md-1 btn-simple2 padding-icon" onClick={()=>{clearJCR1()}}><FontAwesomeIcon icon="trash"/></button>
                    </span>
                    <span className="row">
                      <Select className="col-md-3 mb-1 mt-1" theme={temaZaSelect} options={kvartili} value={dohvacenJCR2Q} /*defaultValue={dohvacenJCR2Q}*/ onChange={(e) => { handleJCR2Q(e) }} />
                      <Select className="col-md-8 mb-1 mt-1" theme={temaZaSelect} options={listaJCRKategorije} value={dohvaceneJCR2Kat} /*defaultValue={dohvaceneJCR2Kat}*/ onChange={(e) => { handleJCR2Kat(e) }} />
                      <button className="col-md-1 btn-simple2 padding-icon" onClick={()=>{clearJCR2()}}><FontAwesomeIcon icon="trash"/></button>
                    </span>
                    {/*<input type="text" name="jcrQa" className="col-md-2 mb-1 mt-1 nice-textbox" placeholder="Q" />&nbsp;
            <input type="text" name="jcrQaCat" className="col-md-9 nice-textbox" placeholder="Kategorija" />
            <br />
            <input type="text" name="jcrQb" className="col-md-2 mb-1 nice-textbox" placeholder="Q" />&nbsp;
            <input type="text" name="jcrQbCat" className="col-md-9 nice-textbox" placeholder="Kategorija" />
      <br />*/}

                  </div>
                  <div className="col-md-5 left border-right ubuntu-dark-cond">
                    <span className="border-bottom"><strong>JCR </strong> - Journal Citation Indicator (JCI)</span><br />
                    <span className="row">
                      <Select className="col-md-3 mb-1 mt-1" theme={temaZaSelect} options={kvartili} value={dohvacenJCI1Q} /*defaultValue={dohvacenSJR1Q}*/ onChange={(e) => { handleJCI1Q(e) }} />
                      <Select className="col-md-8 mb-1 mt-1" theme={temaZaSelect} options={listaJCIKategorije} value={dohvaceneJCI1Kat} /*defaultValue={dohvaceneSJR1Kat}*/ onChange={(e) => { handleJCI1Kat(e) }} />
                      <button className="col-md-1 btn-simple2 padding-icon" onClick={()=>{clearJCI1()}}><FontAwesomeIcon icon="trash"/></button>
                    </span>
                    <span className="row">
                      <Select className="col-md-3 mb-1 mt-1" theme={temaZaSelect} options={kvartili} value={dohvacenJCI2Q} /*defaultValue={dohvacenSJR2Q}*/ onChange={(e) => { handleJCI2Q(e) }} />
                      <Select className="col-md-8 mb-1 mt-1" theme={temaZaSelect} options={listaJCIKategorije} value={dohvaceneJCI2Kat} /*defaultValue={dohvaceneSJR2Kat}*/ onChange={(e) => { handleJCI2Kat(e) }} />
                      <button className="col-md-1 btn-simple2 padding-icon" onClick={()=>{clearJCI2()}}><FontAwesomeIcon icon="trash"/></button>
                    </span>

                  </div>                
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 align-left-right border-right padding15 ">
                  
                </div>
                <div className="row col-md-9 padding15">
                  <div className="col-md-5 left border-right ubuntu-dark-cond">
                    <span className="border-bottom"><strong>SJR</strong> - SCImago Journal Rank Indicator</span><br />
                    <span className="row">
                      <Select className="col-md-3 mb-1 mt-1" theme={temaZaSelect} options={kvartili} value={dohvacenSJR1Q} /*defaultValue={dohvacenSJR1Q}*/ onChange={(e) => { handleSJR1Q(e) }} />
                      <Select className="col-md-8 mb-1 mt-1" theme={temaZaSelect} options={listaSJRKategorije} value={dohvaceneSJR1Kat} /*defaultValue={dohvaceneSJR1Kat}*/ onChange={(e) => { handleSJR1Kat(e) }} />
                      <button className="col-md-1 btn-simple2 padding-icon" onClick={()=>{clearSJR1()}}><FontAwesomeIcon icon="trash"/></button>
                    </span>
                    <span className="row">
                      <Select className="col-md-3 mb-1 mt-1" theme={temaZaSelect} options={kvartili} value={dohvacenSJR2Q} /*defaultValue={dohvacenSJR2Q}*/ onChange={(e) => { handleSJR2Q(e) }} />
                      <Select className="col-md-8 mb-1 mt-1" theme={temaZaSelect} options={listaSJRKategorije} value={dohvaceneSJR2Kat} /*defaultValue={dohvaceneSJR2Kat}*/ onChange={(e) => { handleSJR2Kat(e) }} />
                      <button className="col-md-1 btn-simple2 padding-icon" onClick={()=>{clearSJR2()}}><FontAwesomeIcon icon="trash"/></button>
                    </span>
                  </div>   
                </div>
              </div>


              <div className="row">
                <div className="col-md-3 align-left-right border-right padding15 ">
                  <strong>Kvartili drugih autora</strong>

                </div>
                <div className="col-md-9 padding15">
                  <div className="col-md-8 left border-right ubuntu-dark-cond">
                    <span className="border-bottom">JCR - Journal Impact Factor (JIF)</span><br />


                    {/*
                repo.data[0].autori_na_radu[0].kvartili_autora_na_radu.filter((post) => {

                  if (post.naziv_kategorizacije.toLowerCase().includes('jcr')) {
                    console.log("--|--")
                    return post;
                  }
                }
                ).map((post, index) => {
                  console.log("trebaju biti svi kvartili2: ", post);
                  let resQJCR = post.kvartil;
                  let resKatJCR = post.naziv_kategorije;
                  console.log(resQJCR + "***" + resKatJCR);
                  return <text> <strong>Q{resQJCR}</strong> [{resKatJCR}]<br /></text>;

                })
              */}

                    {
                      [].concat.apply([], repo.data[0].autori_na_radu.map((post, index) => {
                        //console.log("prvi map: ", post.kvartili_autora_na_radu)
                        if (post.kvartili_autora_na_radu.length > 0) {
                          /* let autor_obj = [...post.kvartili_autora_na_radu,{};
                           autor_obj["aai"] = post.aai;
                           console.log("autor",autor_obj);
                           if(post.aai!=props.korisnik)
                           return autor_obj
                         */
                          let authors = post.kvartili_autora_na_radu.map((item) => {
                            item.prezime = post.prezime;
                            item.ime = post.ime;
                            return item;
                          });
                          if (post.aai != props.korisnik)
                            return authors;
                        }
                      }).filter((post) => {
                        return post !== undefined;
                      })
                      ).filter((post) => {

                        if (post.naziv_kategorizacije.toLowerCase().includes('jcr'))
                          return post;
                      }
                      ).map((post, index) => {
                        //console.log("trebaju biti svi kvartili2: ", post, post.ime);
                        let autor = post.ime.charAt(0) + " " + post.prezime;
                        let resQJCR = post.kvartil;
                        let resKatJCR = post.naziv_kategorije;
                        //console.log(resQJCR + "***" + resKatJCR);
                        //console.log("hrcin log", repo.data[0].autori_na_radu[post.id_autor_rad].aai);
                        //return (
                        //  <>{resQJCR}</>
                        //)
                        return (<div>
                          <text> <strong>Q{resQJCR}</strong> [{resKatJCR}] <i>-- upisao {autor}</i><br /></text>
                          {/*<Button className="ubuntu-dark-cond" onClick={() => handleKopiraj(post)}>Kopiraj</Button>*/}
                        </div>);

                      })
                    }


                  </div>

                  <br />
                  <div className="col-md-8 left border-right ubuntu-dark-cond">
                    <span className="border-bottom">JCR - Journal Citation Indicator (JCI)</span><br />
               
                    {
                      [].concat.apply([], repo.data[0].autori_na_radu.map((post, index) => {
                        if (post.kvartili_autora_na_radu.length > 0) {
                          let authors = post.kvartili_autora_na_radu.map((item) => {
                            item.prezime = post.prezime;
                            item.ime = post.ime;
                            return item;
                          });
                          if (post.aai != props.korisnik)
                            return authors;
                        }
                      }).filter((post) => {
                        return post !== undefined;
                      })
                      ).filter((post) => {

                        if (post.naziv_kategorizacije.toLowerCase().includes('jci'))
                          return post;
                      }
                      ).map((post, index) => {
                        let autor = post.ime.charAt(0) + " " + post.prezime;
                        let resQJCR = post.kvartil;
                        let resKatJCR = post.naziv_kategorije;
                        //console.log(resQJCR + "***" + resKatJCR);
                        //console.log("hrcin log", repo.data[0].autori_na_radu[post.id_autor_rad].aai);
                        //return (
                        //  <>{resQJCR}</>
                        //)
                        return (<div>
                          <text> <strong>Q{resQJCR}</strong> [{resKatJCR}] <i>-- upisao {autor}</i><br /></text>
                          {/*<Button className="ubuntu-dark-cond" onClick={() => handleKopiraj(post)}>Kopiraj</Button>*/}
                        </div>);

                      })
                    }


                  </div>




                  <br />
                  <div className="col-md-8 left border-right ubuntu-dark-cond">
                    <span className="border-bottom">SJR - SCImago Journal Rank Indicator</span><br />
                    {
                      [].concat.apply([], repo.data[0].autori_na_radu.map((post, index) => {
                        //console.log("prvi map: ", post.kvartili_autora_na_radu)
                        if (post.kvartili_autora_na_radu.length > 0) {
                          /* let autor_obj = [...post.kvartili_autora_na_radu,{};
                           autor_obj["aai"] = post.aai;
                           console.log("autor",autor_obj);
                           if(post.aai!=props.korisnik)
                           return autor_obj
                         */
                          let authors = post.kvartili_autora_na_radu.map((item) => {
                            item.prezime = post.prezime;
                            item.ime = post.ime;
                            return item;
                          });
                          if (post.aai != props.korisnik)
                            return authors;
                        }
                      }).filter((post) => {
                        return post !== undefined;
                      })
                      ).filter((post) => {

                        if (post.naziv_kategorizacije.toLowerCase().includes('sjr'))
                          return post;
                      }
                      ).map((post, index) => {
                        //console.log("trebaju biti svi kvartili2: ", post, post.ime);
                        let autor = post.ime.charAt(0) + " " + post.prezime;
                        let resQJCR = post.kvartil;
                        let resKatJCR = post.naziv_kategorije;
                        //console.log(resQJCR + "***" + resKatJCR);
                        //console.log("hrcin log", repo.data[0].autori_na_radu[post.id_autor_rad].aai);
                        //return (
                        //  <>{resQJCR}</>
                        //)
                        return (<div>
                          <text> <strong>Q{resQJCR}</strong> [{resKatJCR}] <i>-- upisao {autor}</i><br /></text>
                          {/*<Button className="ubuntu-dark-cond" onClick={() => handleKopiraj(post)}>Kopiraj</Button>*/}
                        </div>);

                      })
                    }
                  </div>
                </div>
              </div>

            </div>
          )
        }


      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Rad sa studentom</strong>
          <div><text className="ubuntu-dark-cond small">(čiji je autor mentor)</text></div>
        </div>
        <div className="col-md-9 padding15">
          <input type="checkbox"
            checked={saStudentom == '1'}
            onChange={() => { setSaStudentom(saStudentom == 0 ? 1 : 0); }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Rad s doktorandom</strong>
          <div><text className="ubuntu-dark-cond small">(čiji je autor mentor)</text></div>
        </div>
        <div className="col-md-9 padding15">
          <input type="checkbox"
            checked={sDoktorandom == '1'}
            onChange={() => { setSDoktorandom(sDoktorandom == 0 ? 1 : 0); }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">
          <strong>Rad van ustanove</strong>          
        </div>
        <div className="col-md-9 padding15">
          <input type="checkbox"
            checked={vanUstanove == '1'}
            onChange={() => { setVanUstanove(vanUstanove == 0 ? 1 : 0); }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right padding15 ">


        </div>

        <div className="col-md-9 padding15">
          {smijeUrediti?
          //<Link className="btn btn-secondary rounded-corners " type="submit" onClick={() => post()} to={smijeSeIzvrsiti == 1 ? (`/rad/${id}`) : (`/rad/${id}`)}>Spremi&nbsp;&nbsp;<FontAwesomeIcon className="ubuntu-white" icon="save" /></Link>
          <a className="btn btn-secondary ubuntu-light-cond" onClick={() => post()}>Spremi&nbsp;&nbsp;<FontAwesomeIcon className="ubuntu-white" icon="save" /></a>
          
          :
          <div />
          }
          {/*<Link className="btn btn-secondary rounded-corners " type="submit" onClick={() => post()}>Spremi&nbsp;&nbsp;<FontAwesomeIcon className="ubuntu-white" icon="save" /></Link>*/}

        </div>

      </div>

      <div className="row">
        <div className="col-md-3 align-left-right border-right ">
        <hr/>
        </div>
        <div className="col-md-9 ">
        <hr/>
        </div>
      </div> 
      <div className="row">
        <div className="col-md-3 align-left-right border-right padding-bottom-large">
          <strong>Duplikat</strong>          
        </div>
        <div className="col-md-9 padding-bottom-large">
          <input type="checkbox"
            checked={duplikat == '1'}
            onChange={() => { setDuplikat(duplikat == 0 ? 1 : 0); 
              if(duplikat==0)toast.warning('Nakon što spremite izmjene ovaj rad više neće biti vidljiv u sustavu!', {
              position: toast.POSITION.BOTTOM_CENTER,
              autClose: 3000,
              transition: Slide
            });}}
          />
          <text className="ubuntu-dark-cond small"> &nbsp;&nbsp;&nbsp;[ako označite ovo polje rad će se smatrati duplikatom i neće više biti prikazan u sustavu]</text>
        </div>
      </div> 


    </div>
    )
    :
    (<div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="ubuntu-gray center">Nemate ovlasti uređivati ovaj rad.</div>
      <br/>
      <br/>
      <br/>      
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>      
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>      
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>

      </div>

    )



  );


















  {/*
        <div className="container">
            <div class="row">
                <div class="col-md-3 align-right border-right padding15 ubuntu">
                    <strong>Naslov</strong>
                </div>
                <div class="col-md-9 padding15">
                    <input type="text" name="Naslov" className="full-width" />
                </div>
            </div>
            {
<div class="row">
  <div class="col-md-3 align-right border-right padding15">
    
    <strong>Autori</strong>
  </div>
  <div class="col-md-9 padding15">
  <input type="text" name="Naslov" className="full-width" />
  </div>
</div>
<div class="row">
  <div class="col-md-3 align-right border-right padding15">
    
    <strong>Datum</strong>
  </div>
  <div class="col-md-9 padding15">
  <input type="text" name="Naslov" className="full-width" /><input type="text" name="Naslov" className="full-width" />
  </div>
</div>
<div class="row">
  <div class="col-md-3 align-right border-right padding15">
    <%# form.label :publication_type %>
    <strong>Vrsta publikacije</strong>
  </div>
  <div class="col-md-9 padding15">
    <%= form.text_field :publication_type, class: "full-width" %>
  </div>
</div>
<div class="row">
  <div class="col-md-3 align-right border-right padding15">
    <%# form.label :departments %>
    <strong>Zavod</strong>
  </div>
  <div class="col-md-9 padding15">
    <%= form.text_field :departments, class: "full-width" %>
  </div>
</div>
<div class="row">
  <div class="col-md-3 align-right border-right padding15">
    <%# form.label :doi %>
    <strong>DOI</strong>
  </div>
  <div class="col-md-9 padding15">
    <%= form.text_field :doi, class: "full-width" %>
  </div>
</div>
<div class="row">
  <div class="col-md-3 align-right border-right padding15">
    <%# form.label :publication %>
    <strong>Publikacija</strong>
  </div>
  <div class="col-md-9 padding15">
    <%= form.text_field :publication, class: "full-width" %>
  </div>
</div>
<div class="row">
  <div class="col-md-3 align-right border-right padding15">
    <%# form.label :conference %>
    <strong>Konferencija</strong>
  </div>
  <div class="col-md-9 padding15">
    <%= form.check_box :conference %>
  </div>
</div>
<div class="row">
  <div class="col-md-3 align-right border-right padding15">
    <%# form.label :event_name %>
    <strong>Naziv događaja</strong>
  </div>
  <div class="col-md-9 padding15">
    <%= form.text_field :event_name, class: "full-width" %>
  </div>
</div>
<div class="row">
  <div class="col-md-3 align-right border-right padding15">
    <%# form.label :place %>
    <strong>Mjesto</strong>
  </div>
  <div class="col-md-9 padding15">
    <%= form.text_field :place, class: "full-width" %>
  </div>
</div>
<div class="row">
  <div class="col-md-3 align-right border-right padding15">
    <%# form.label :volume %>
    <strong>Izdanje</strong>
  </div>
  <div class="col-md-9 padding15">
    <%= form.text_field :volume, class: "full-width" %>
  </div>
</div>
<div class="row">
  <div class="col-md-3 align-right border-right padding15">
    <%# form.label :page_from %>
    <strong>Stranica</strong>
  </div>
  <div class="col-md-9 padding15">
    Od <%= form.text_field :page_from %> do <%= form.text_field :page_to %>
  </div>
</div>
<div class="row">
  <div class="col-md-3 align-right border-right padding15">
    <%# form.label :url %>
    <strong>URL</strong>
  </div>
  <div class="col-md-9 padding15">
    <%= form.text_area :url, class: "full-width" %>
  </div>
</div>



  <div class="row">
      <div class="col-md-3 align-right border-right padding15">
        
      </div>
      <div class="col-md-9 padding15">
        <%# form.submit 'Spremi', class: "btn btn-primary" %>
        <%= button_tag(type: 'submit', class: "btn btn-primary") do %>
          Spremi&nbsp&nbsp<i class="fa fa-floppy-o"></i>
          <% end %>
      </div>
  </div>

<div class="row padding15"></div>


            </div> */}

}

export default UrediRad;