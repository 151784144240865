import React, { useCallback, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { useLocation, useHistory, useParams, Link, NavLink } from "react-router-dom"
import { fab } from '@fortawesome/free-brands-svg-icons'
import axios from "axios"
import moment from "moment"
import Dropdown from 'react-bootstrap/Dropdown'
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { decode } from 'html-entities';
import jwtDecode from 'jwt-decode';
import { faCheckSquare, faCoffee, faSignOutAlt, faPlusSquare, faSave, faSearch, faPlus, faServer, faUser, faArrowCircleUp, faArrowCircleDown, faFilter, faEdit, faChevronLeft, faRetweet, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons'
library.add(faCheckSquare, faCoffee, faSignOutAlt, faPlusSquare, faSave, faSearch, faPlus, faServer, faUser, faArrowCircleUp, faArrowCircleDown, faFilter, faEdit, faChevronLeft, faRetweet, faLock, faLockOpen)
toast.configure()

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwt')}`;

function Rad(props) {


  const filters = useSelector(state => state.filters);
  const prijavljeniKorisnik = useSelector(state => state.prijavljeniKorisnik);

  /*const location=useLocation()
  console.log(location)*/

  let { id } = useParams();
  let datIzd = "---";
  const [repo, setRepo] = React.useState(null);
  const [smijeUrediti, setSmijeUrediti] = useState(false);
  const [rBrAutora, setRBrAutora] = useState(0);

  let textToCopyCrosbi = "";
  let textToCopyIEEE = "";

  const [bezMBZ, setBezMBZ] = useState(0);

  const [loading, setLoading] = useState(false);

  function wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }


  React.useEffect(() => {

    console.log("rad: dobio sam korisnika", props.korisnik)


        const decodedToken = jwtDecode(localStorage.getItem('jwt'));

        

        //console.log("prijavljen je:" + response.data.data[0].ime);
        if (decodedToken.aai == "") {
          prijavljeniKorisnik.aai = 'Anonimni korisnik';

        }
        else {
          prijavljeniKorisnik.aai = decodedToken.aai;

        }

        axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/rad-id/' + id).then((response) => {

          setRepo(response.data);
          if (response.data.data[0].datum_objave != '0000-00-00') {
            datIzd = moment(response.data.data[0].datum_objave).format("DD.MM.YYYY.");
          }
          else datIzd = "";
          textToCopyCrosbi = response.data.data[0].citation_en;
          let brBezMBZ = 0;
          response.data.data[0].autori_na_radu.forEach(function (val, idx) {
            /*if (val.aai == props.korisnik && props.korisnik != 'Anonimni korisnik' && props.korisnik != '') {*/
            if (val.aai == prijavljeniKorisnik.aai && prijavljeniKorisnik.aai != 'Anonimni korisnik' && prijavljeniKorisnik.aai != '') {
              setSmijeUrediti(true);
              setRBrAutora(idx);

              console.log("uređuje " + rBrAutora);
            }

            if (val.nema_broj_znanstvenika) brBezMBZ++;
          });
          console.log("SMIJE UREDITI ", prijavljeniKorisnik.aai);
          setBezMBZ(brBezMBZ);
        });
      



  }, []);
  if (!repo) return null;

  const handleDropDownKor = (e) => {
    //setDropdownKor(e);


    if (e.aai === '') {
      toast.warning('Autor ne postoji u bazi podataka naše ustanove.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autClose: 2000,
        transition: Slide
      });
    }
    else {
      filters.autori = []
      filters.autori[0] = { value: e.aai, label: e.prezime + " " + e.ime }
    }

    console.log("rad kaže:", e)
  }

  const handleDropDownKor2 = (e) => {



    
    filters.autori = []
    filters.autori[0] = { label: e[0] + " " + e[1]}
    

    console.log("rad kaže:", e)
  }


  function kopirajBibliografiju(text) {

    navigator.clipboard.writeText(text);

    toast.info('Spremljeno u međuspremnik. Možete zalijepiti s CTRL+V na željeno mjesto.', {
      position: toast.POSITION.BOTTOM_CENTER,
      autClose: 2000,
      transition: Slide
    });
  }

  const handleOsvjeziSCROSBI = async (e) => {
    await axios.get('https://www.ferit.unios.hr/e-znanstveni-radovi/api/v1/api_jwt.php/osvjezi-radove-autora/' + e + '/' + props.korisnik).then((response) => {
      if (response.data.status === 'success') {
        toast.info('Osvježeni su podaci s CROSBI baze korisnika ' + props.korisnik + ' za ' + e + '. godinu.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autClose: 2000,
          transition: Slide
        });
      }
    })
  }

  const autori = repo.data[0].autori.split(';').map(autor => autor.trim());
  
  

  return (
    <div className="ubuntu-dark">
      <div className="row">
        <div className="col-md-3 border-right align-left">
          <Link className="ubuntu-result-bold" to={"/radovi"} onClick={() => { console.log("Natrag: ", filters.autori) }}><FontAwesomeIcon className="" icon="chevron-left" />&nbsp;&nbsp;Natrag</Link>
        </div>
        <div className="col-md-9">
          <div className="row">

            <div className="col-md-4 mobile-right">
              <text className="ubuntu-gray-small align-left fw-bold">PRIKAZUJU SE PODACI O RADU # {id}</text>&nbsp;&nbsp;&nbsp;
            </div>
            <div className="col-md-4 mobile-right">
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="btn btn-secondary ubuntu-light-cond" size="sm">
                  Kopiraj podatke
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => { kopirajBibliografiju(repo.data[0].citation_en.replace("\n","\r")) }}>CROSBI</Dropdown.Item>
                  <Dropdown.Item onClick={() => {
                    /*let autori_ieee_old = ""
                    repo.data[0].autori_na_radu.map((post) => {
                      autori_ieee_old += post.ime.charAt(0) + ". " + post.prezime + ", ";
                      return 0;
                    })*/
                    let autori_ieee = "" //ovo je nova verzija koja parsira redom autore iz stringa i stavlja ih u ieee format
                    let prez_ime = repo.data[0].autori.split(';').map(author => author.trim());
                   
                    let processedNames = prez_ime.map(name => {
                        // Trim any leading/trailing whitespace
                        name = name.trim();
                       
                        if (name) {  // Check if the name is not empty
                            // Split the name by comma to separate first and last names
                            let parts = name.split(',').map(part => part.trim());

                            if (parts.length === 2) {
                                let [lastName, firstName] = parts;
                    
                                // Take the first letter of the first name and add a period
                                let initial = firstName[0] + '.';
                            
                                // Return the processed name in the desired format
                                return initial + ' ' + lastName;
                            }
                           
                        }                                               
                        return '';  // Return an empty string if the name was empty
                    });
                    
                    autori_ieee = processedNames.filter(name => name).join(', ');
                    // Ensure the output ends with a comma if needed
                    if (autori_ieee && !autori_ieee.endsWith(',')) {
                        autori_ieee += ', ';
                    }


                    if (repo.data[0].kategorija == "Radovi u časopisima")
                      textToCopyIEEE = autori_ieee + "\"" + repo.data[0].naslov_en + ",\" " + repo.data[0].naziv_casopisa + ", vol. " + repo.data[0].volume + ", no. " + repo.data[0].no_issue + ", pp. " + repo.data[0].paper_pages + ", " + repo.data[0].godina + ".\n\n";
                    else
                      textToCopyIEEE += autori_ieee + "\"" + repo.data[0].naslov_en + ",\" in " + repo.data[0].naziv_casopisa + ", " + repo.data[0].godina + ", pp. " + repo.data[0].paper_pages + "\n\n";

                    kopirajBibliografiju(textToCopyIEEE)
                  }
                  }>IEEE</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

          </div>

        </div>

      </div>
      <hr />

      <div className="row">
        <div className="col-md-3 align-right border-right">


        </div>

        <div className="col-md-9 padding15">
          {/*<button className="btn btn-secondary rounded-corners " type="submit">Uredi&nbsp;&nbsp;<FontAwesomeIcon className="ubuntu-white" icon="edit" /></button>*/}


          {smijeUrediti ?
            (<div className="col-md-9">
              <div className="row">
                <div className="col-md-4 padding5">
                  <Link className="btn btn-secondary ubuntu-light-cond" to={"/uredirad/" + id} >Uredi&nbsp;&nbsp;<FontAwesomeIcon className="ubuntu-white" icon="edit" /></Link>
                </div>
                <div className="col-md-5 padding5">
                  <Link className="btn btn-secondary ubuntu-light-cond" onClick={() => { handleOsvjeziSCROSBI(repo.data[0].godina) }} to={"/rad/" + id} >Osvježi podatke s CROSBI-ja&nbsp;&nbsp;<FontAwesomeIcon className="ubuntu-white" icon="retweet" /></Link>
                </div>
              </div>
            </div>
            )
            :
            (<div />)
          }


        </div>

      </div>
      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Naslov</strong>
        </div>
        <div className="col-md-9 padding15 fw-bold fw-underline">
          {repo.data[0].naslov_en}
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Autori</strong>
        </div>
        <div className="col-md-9 padding15">

          {autori.map((post, index) => {
            let prez_ime = autori[index].split(',').map(author => author.trim());

            let imaLink = false;
            let indLink = 0;

            repo.data[0].autori_na_radu.map((post, index) => {
              let res = repo.data[0].autori_na_radu[index].ime + " " + repo.data[0].autori_na_radu[index].prezime + "; ";
              let res_anr = repo.data[0].autori_na_radu[index];
              let res2 = prez_ime[1] + " " + prez_ime[0] + "; ";
              console.log("res 1 pa 2", res === res2);

              if (res === res2) {
                imaLink = true;
                indLink = index;
              }
            })

            let res = repo.data[0].autori_na_radu[indLink].ime + " " + repo.data[0].autori_na_radu[indLink].prezime + "; ";
            let res_anr = repo.data[0].autori_na_radu[indLink];

            //let option = .find((opt) => opt.label === label);
            return (imaLink && res_anr.aai != '' ?
              <Link className="full-width ubuntu-dark fw-bold" onClick={() => { handleDropDownKor(res_anr) }} to={"/radovi"}> {res} </Link>
              :
              <text className="full-width ubuntu-dark" /*onClick={() => { handleDropDownKor2(prez_ime) }} to={"/radovi"}*/ title="Autor nije s naše ustanove.">{prez_ime[1]} {prez_ime[0]};&nbsp;</text>
            )
          })

          }
        </div>
      </div>
{/*<div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Autori (koji imaju CRORIS profil)</strong>
        </div>
        <div className="col-md-9 padding15">
          {//repo.data[0].autori}
          {
            repo.data[0].autori_na_radu.map((post, index) => {
              let res = repo.data[0].autori_na_radu[index].ime + " " + repo.data[0].autori_na_radu[index].prezime + "; ";
              let res_anr = repo.data[0].autori_na_radu[index];
              console.log("resovi su", res + res_anr);

              return (res_anr.aai === '' ?
                //<Link className={res_anr.nema_broj_znanstvenika ? "full-width ubuntu-red fw-bold" : "full-width ubuntu-dark fw-bold"} onClick={() => { handleDropDownKor(res_anr) }} to={""} > {res} </Link>
                //:
                //<Link className={res_anr.nema_broj_znanstvenika ? "full-width ubuntu-red fw-bold" : "full-width ubuntu-dark fw-bold"} onClick={() => { handleDropDownKor(res_anr) }} to={"/radovi"}> {res} </Link>
                <Link className="full-width ubuntu-red fw-bold" onClick={() => { handleDropDownKor(res_anr) }} to={""} title="Autor nije s naše ustanove."> {res} </Link>
                :
                <Link className="full-width ubuntu-dark fw-bold" onClick={() => { handleDropDownKor(res_anr) }} to={"/radovi"}> {res} </Link>
              );
            })
          }


        </div>
      </div>*/}

      {/*
        (bezMBZ > 0 ?
          <div className="row">
            <div className="col-md-3 align-left-right padding15 ">

            </div>
            <div className="col-md-9">
              <text className="full-width ubuntu-red-cond fw-light"> *Autor/i označeni ovom bojom nemaju AAI na našoj ustanovi.</text>
            </div>
          </div>
          :
          <div> </div>
        )
        */}

      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Glavni autor 1</strong>
          <div><text className="ubuntu-dark-cond small">(nositelj problematike)</text></div>
        </div>
        <div className="col-md-9 padding15">
          
          {/*ovo je komentiran stari način prije nego što croris nije dao nepotpun popis autora 
            repo.data[0].autori_na_radu.map((post, index) => {
            let res = repo.data[0].autori_na_radu[index].ime + " " + repo.data[0].autori_na_radu[index].prezime;
            let res_aai = repo.data[0].autori_na_radu[index].aai;
            console.log("GL AU", res, res_aai, repo.data[0].glautor1_rbr, index + 1)
            
            if (repo.data[0].glavni_autor1 === res_aai && res_aai != "") {
              return (<text>{res}</text>);
            }
            else if (res_aai==="" && repo.data[0].glautor1_rbr === ""+(index + 1)) {
              return (<text>{res}</text>);
            }
          })*/}
          {

            autori.map((post, index) => {
              let prez_ime = autori[index].split(',').map(author => author.trim());
              let imaLink = false;
              if (repo.data[0].glavni_autor1 === "" && repo.data[0].glautor1_rbr === "" + (index + 1)) return <text>{prez_ime[1] + " " + prez_ime[0]}</text>
              else {
                repo.data[0].autori_na_radu.map((post, index) => {
                  let res = repo.data[0].autori_na_radu[index].ime + " " + repo.data[0].autori_na_radu[index].prezime;
                  let res_aai = repo.data[0].autori_na_radu[index].aai;
                  let res2 = prez_ime[1] + " " + prez_ime[0];
                
                  if (res===res2 && repo.data[0].glavni_autor1 === res_aai && res_aai != "") {
                    imaLink = true;                                  
                  }
                })                
              }
              if (imaLink===true) return (<text>{prez_ime[1] + " " + prez_ime[0]}</text>);
            })
          }
          {/*repo.data[0].glavni_autor1*/}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Glavni autor 2</strong>
          <div><text className="ubuntu-dark-cond small">(najviše pridonio rješavanju problema)</text></div>
        </div>
        <div className="col-md-9 padding15">
          {/*repo.data[0].autori_na_radu.map((post, index) => {
            let res = repo.data[0].autori_na_radu[index].ime + " " + repo.data[0].autori_na_radu[index].prezime;
            let res_aai = repo.data[0].autori_na_radu[index].aai;
            if (repo.data[0].glavni_autor2 === res_aai && res_aai != ""){
              return (<text>{res}</text>);
            }
            else if (res_aai==="" && repo.data[0].glautor2_rbr === ""+(index + 1)) {
                return (<text>{res}</text>);
            }
          })*/}
          {

            autori.map((post, index) => {
              let prez_ime = autori[index].split(',').map(author => author.trim());
              let imaLink = false;
              if (repo.data[0].glavni_autor2 === "" && repo.data[0].glautor2_rbr === "" + (index + 1)) return <text>{prez_ime[1] + " " + prez_ime[0]}</text>
              else {
                repo.data[0].autori_na_radu.map((post, index) => {
                  let res = repo.data[0].autori_na_radu[index].ime + " " + repo.data[0].autori_na_radu[index].prezime;
                  let res_aai = repo.data[0].autori_na_radu[index].aai;
                  let res2 = prez_ime[1] + " " + prez_ime[0];
                  if (res === res2 && repo.data[0].glavni_autor2 === res_aai && res_aai != "") {
                    imaLink=true;
                  }
                })
              }
              if(imaLink===true) return (<text>{prez_ime[1] + " " + prez_ime[0]}</text>);

            })
          }
          {/*repo.data[0].glavni_autor2*/}
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Abstract</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].abstract}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Publikacija</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].naziv_casopisa}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Godina izdavanja</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].godina}

        </div>
      </div>
      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Datum izdavanja</strong>
        </div>
        <div className="col-md-9 padding15">

          {moment(repo.data[0].datum_objave).format("DD.MM.YYYY.") != 'Invalid date' ?
            (moment(repo.data[0].datum_objave).format("DD.MM.YYYY."))
            :
            (<div />)
          }
          {repo.data[0].datum_objave === "0000-00-00"? 
          <span className="ubuntu-red"> <FontAwesomeIcon icon="exclamation-triangle" /> Publikacija nema unesen i zaključan datum objave! </span> 
          : 
          <span>{repo.data[0].datum_objave_lock === '1' ? 
              <span className="ubuntu-dark"> &nbsp;<FontAwesomeIcon icon="lock" /> </span> 
              : 
              <span className="ubuntu-red"> &nbsp;<FontAwesomeIcon icon="lock-open" />&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon="exclamation-triangle" /> Publikacija nema zaključan datum objave! </span>
              
              }
          </span>
          }
          
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Vrsta publikacije</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].crosbi_kategorija}
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Podkategorija publikacije</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].crosbi_podkategorija}
        </div>
      </div>
      {(repo.data[0].crosbi_kategorija === "Radovi u zbornicima skupova" || repo.data[0].crosbi_kategorija === "Knjige i poglavlja u knjigama") ? (<div />) : (
        <div className="row">
          <div className="col-md-3 align-left-right padding15 ">
            <strong>Indeksiranost</strong>
          </div>
          <div className="col-md-9 padding15">
            {repo.data[0].indeksiranost_casopisa === null ?
              "-"
              :
              (<div>
                {repo.data[0].indeksiranost_casopisa.map((post) => {
                  return (
                    <div>
                      <i>
                        {(post === "Science Citation Index Expanded (SCI-EXP)" ||
                          post === "SCI-EXP, SSCI i/ili A&amp;HCI" ||
                          post === "Emerging Sources Citation Index (ESCI)") ? <text>&nbsp;&nbsp;&nbsp;-&nbsp;</text> : ""}
                        {decode(post)}
                      </i>
                    </div>)
                })}
              </div>)
            }
          </div>

          <div className="col-md-3 align-left-right padding15 ">
            <strong>JCR Journal Impact Factor (JIF)</strong>
          </div>
          <div className="col-md-9 padding15">
            {repo.data[0].impact_factor_casopisa == 0 ? "-" : repo.data[0].impact_factor_casopisa}
          </div>

          <div className="col-md-3 align-left-right padding15 ">
            <strong>JCR Journal Citation Indicator (JCI)</strong>
          </div>
          <div className="col-md-9 padding15">
            {repo.data[0].jci_casopisa == 0 ? "-" : repo.data[0].jci_casopisa}
          </div>

          <div className="col-md-3 align-left-right padding15 ">
            <strong>{repo.data[0].crosbi_kategorija != "Prilog u časopisu" ? "Inozemna publikacija" : "Inozemni časopis"}</strong>
          </div>
          <div className="col-md-9 padding15">
            {repo.data[0].inozemni == 0 ? 'Ne' : 'Da'}
          </div>
        </div>)}
      {/*
        <div className="row">
          <div className="col-md-3 align-right border-right padding15 ">
            <strong>Zavod</strong>
          </div>
      
          <div className="col-md-9 padding15">
            
          </div>
        </div>  */}
      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>DOI</strong>
        </div>
        <div className="col-md-9 padding15">
          <a target="_blank" className="ubuntu-link-in-text" href={`https://doi.org/${repo.data[0].urls_doi}`}>{repo.data[0].urls_doi}</a>
        </div>
      </div>

      {/*
        <div className="row">
          <div className="col-md-3 align-right border-right padding15 ">
            <strong>Konferencija</strong>
          </div>
          <div className="col-md-9 padding15">
          
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 align-right border-right padding15 ">
            <strong>Naziv događaja</strong>
          </div>
          <div className="col-md-9 padding15">
            
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 align-right border-right padding15 ">
            <strong>Mjesto</strong>
          </div>
          <div className="col-md-9 padding15">
            
          </div>
        </div>
        */}
      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Izdanje/Broj</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].volume}/{repo.data[0].no_issue}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Stranica</strong>

        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].paper_pages}
        </div>
      </div>


      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Rad u otvorenom pristupu</strong>
          <div><text className="ubuntu-dark-cond small fst-italic">(Open access)</text></div>
        </div>
        <div className="col-md-9 padding15">
        {repo.data[0].rad_u_otvorenom_pristupu == 0 ? 'Ne' : 'Da'}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>URL</strong>
        </div>
        <div className="col-md-9 padding15">
          <a target="_blank" className="ubuntu-link-in-text" href={`https://doi.org/${repo.data[0].urls_oa_urls}`}>{repo.data[0].urls_oa_urls}</a>

        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Rad s gospodarstvom</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].rad_sa_gospodarstvom == 0 ? 'Ne' : 'Da'}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Rad na projektu</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].rad_na_projektu == 0 ? 'Ne' : 'Da'}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Međuzavodski rad</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].medjuzavodski_rad == 0 ? 'Ne' : 'Da'}
        </div>
      </div>


      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Rad s drugim znanstvenim institucijama</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].rad_s_drugim_znanstvenim_institucijama == 0 ? 'Ne' : 'Da'}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Rad s inozemnim znanstvenicima</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].rad_s_inozemnim_znanstvenicima == 0 ? 'Ne' : 'Da'}
        </div>
      </div>



      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <hr />
          <text className="ubuntu-gray-small align-left fw-bold"></text>
        </div>
        <div className="col-md-9 padding15">
          <hr />
          <text className="ubuntu-gray-small align-left fw-bold">↓ INDIVIDUALNI PODACI ZA AUTORE ↓</text>

        </div>
      </div>

      {/*
      <div className="row">
        <div className="col-md-3 align-right border-right padding15 ">
          <strong>Moji kvartili</strong>
        </div>
        <div className="row col-md-9 padding15">
          <div className="col-md-4 left border-right ubuntu-dark-cond">
            <span className="border-bottom"><strong>JCR </strong>(Journal Citation Report)</span><br />
            {smijeUrediti ?
              repo.data[0].autori_na_radu[rBrAutora].kvartili_autora_na_radu.filter((post) => {
                if (post.naziv_kategorizacije.toLowerCase().includes('jcr')) {

                  return post;
                }
              }).map((post, index) => {
                let resQJCR = post.kvartil;
                let resKatJCR = post.naziv_kategorije;
                //console.log(resQJCR + " " + resKatJCR);
                if (index < 2) return <text> <strong>Q{resQJCR}</strong> [{resKatJCR}]<br /></text>;
              })
              :
              <div />
            }
          </div>
          <div className="col-md-4 left border-right ubuntu-dark-cond">
            <span className="border-bottom"><strong>SJR </strong>(SCImago)</span><br />
            {smijeUrediti ?
              repo.data[0].autori_na_radu[rBrAutora].kvartili_autora_na_radu.filter((post) => {
                if (post.naziv_kategorizacije.toLowerCase().includes('sjr')) {
                  //console.log(post);
                  return post;
                }
              }).map((post, index) => {
                let resQSJR = post.kvartil;
                let resKatSJR = post.naziv_kategorije;
                if (index < 2) return <text> <strong>Q{resQSJR}</strong> [{resKatSJR}]<br /></text>;
              })
              :
              <div />

            }
          </div>
        </div>
      </div>
*/}

      {
        (repo.data[0].crosbi_kategorija === "Radovi u zbornicima skupova" || repo.data[0].crosbi_kategorija === "Knjige i poglavlja u knjigama") ? (<div />) : (
          <div>

            <div className="row">
              <div className="col-md-3 align-left-right padding15 ">
                <strong>Kvartil</strong>
              </div>
              <div className="col-md-9 padding15">
                <div className="col-md-8 left ubuntu-dark-cond">
                  <span className="border-bottom">JCR - Journal Impact Factor (JIF)</span><br />
                  {
                    [].concat.apply([], repo.data[0].autori_na_radu.map((post, index) => {
                      if (post.kvartili_autora_na_radu.length > 0) {
                        let authors = post.kvartili_autora_na_radu.map((item) => {
                          item.prezime = post.prezime;
                          item.ime = post.ime;
                          return item;
                        });
                        //if (post.aai != props.korisnik)
                        return authors;
                      }
                    }).filter((post) => {
                      return post !== undefined;
                    })
                    ).filter((post) => {

                      if (post.naziv_kategorizacije.toLowerCase().includes('jcr'))
                        return post;
                    }
                    ).map((post, index) => {
                      let autor = post.ime.charAt(0) + " " + post.prezime;
                      let resQJCR = post.kvartil;
                      let resKatJCR = post.naziv_kategorije;
                      return (<div>
                        <text> <strong>Q{resQJCR}</strong> [{resKatJCR}] <i>-- upisao {autor}</i><br /></text>
                      </div>);

                    })
                  }


                </div>
                <br />
                <div className="col-md-8 left ubuntu-dark-cond">
                  <span className="border-bottom">JCR - Journal Citation Indicator (JCI)</span><br />
                  {
                    [].concat.apply([], repo.data[0].autori_na_radu.map((post, index) => {
                      if (post.kvartili_autora_na_radu.length > 0) {
                        let authors = post.kvartili_autora_na_radu.map((item) => {
                          item.prezime = post.prezime;
                          item.ime = post.ime;
                          return item;
                        });
                        //if (post.aai != props.korisnik)
                        return authors;
                      }
                    }).filter((post) => {
                      return post !== undefined;
                    })
                    ).filter((post) => {

                      if (post.naziv_kategorizacije.toLowerCase().includes('jci'))
                        return post;
                    }
                    ).map((post, index) => {
                      let autor = post.ime.charAt(0) + " " + post.prezime;
                      let resQJCR = post.kvartil;
                      let resKatJCR = post.naziv_kategorije;
                      return (<div>
                        <text> <strong>Q{resQJCR}</strong> [{resKatJCR}] <i>-- upisao {autor}</i><br /></text>
                      </div>);

                    })
                  }


                </div>
                <br />
                <div className="col-md-8 left ubuntu-dark-cond">
                  <span className="border-bottom">SJR - SCImago Journal Rank Indicator</span><br />
                  {
                    [].concat.apply([], repo.data[0].autori_na_radu.map((post, index) => {
                      if (post.kvartili_autora_na_radu.length > 0) {
                        let authors = post.kvartili_autora_na_radu.map((item) => {
                          item.prezime = post.prezime;
                          item.ime = post.ime;
                          return item;
                        });
                        //if (post.aai != props.korisnik)
                        return authors;
                      }
                    }).filter((post) => {
                      return post !== undefined;
                    })
                    ).filter((post) => {

                      if (post.naziv_kategorizacije.toLowerCase().includes('sjr'))
                        return post;
                    }
                    ).map((post, index) => {
                      let autor = post.ime.charAt(0) + " " + post.prezime;
                      let resQJCR = post.kvartil;
                      let resKatJCR = post.naziv_kategorije;
                      return (<div>
                        <text> <strong>Q{resQJCR}</strong> [{resKatJCR}] <i>-- upisao {autor}</i><br /></text>
                      </div>);

                    })
                  }
                </div>
              </div>
            </div>

          </div>)}

      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Rad sa studentom</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].autori_na_radu.map((post, index) => {
            return (<div><text>{post.rad_sa_studentom == 0 ? 'Ne' : 'Da'}</text><text className="ubuntu-dark-cond"> <i>-- za {post.ime.charAt(0) + " " + post.prezime}</i></text></div>)
          })
          }
          {/*{repo.data[0].autori_na_radu[rBrAutora].rad_sa_studentom == 0 ? 'Ne' : 'Da'}*/}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Rad s doktorandom</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].autori_na_radu.map((post, index) => {
            return (<div><text>{post.rad_s_doktorandom == 0 ? 'Ne' : 'Da'}</text><text className="ubuntu-dark-cond"> <i>-- za {post.ime.charAt(0) + " " + post.prezime}</i></text></div>)
          })
          }
          {/*{repo.data[0].autori_na_radu[rBrAutora].rad_s_doktorandom == 0 ? 'Ne' : 'Da'}*/}
        </div>
      </div>

      
      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">
          <strong>Rad van ustanove</strong>
        </div>
        <div className="col-md-9 padding15">
          {repo.data[0].autori_na_radu.map((post, index) => {
            return (<div><text>{post.rad_van_ustanove == 0 ? 'Ne' : 'Da'}</text><text className="ubuntu-dark-cond"> <i>-- za {post.ime.charAt(0) + " " + post.prezime}</i></text></div>)
          })
          }
          {/*{repo.data[0].autori_na_radu[rBrAutora].rad_sa_studentom == 0 ? 'Ne' : 'Da'}*/}
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 align-left-right padding15 ">


        </div>

        <div className="col-md-9 padding15">
          {/*<button className="btn btn-secondary rounded-corners " type="submit">Uredi&nbsp;&nbsp;<FontAwesomeIcon className="ubuntu-white" icon="edit" /></button>*/}

          {smijeUrediti ?
            (<Link className="btn btn-secondary ubuntu-light-cond" to={"/uredirad/" + id} >Uredi&nbsp;&nbsp;<FontAwesomeIcon className="ubuntu-white" icon="edit" /></Link>

            )
            :
            (<div />)
          }


        </div>

      </div>
    </div>



  );


}

export default Rad;