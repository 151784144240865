import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

let datumPrije5 = new Date().getFullYear()-5+"-"+new Date().toLocaleString("en-US", { month: "2-digit" })+"-"+new Date().toLocaleString("en-US", { day : '2-digit'}) 

const store = createStore(() => ({
  filters: {
      autori:[{ label: "Nema odabira", value: "nista" }], //autori:[],
      datumOd:new Date(datumPrije5),
      datumDo:new Date(),
      radSDatumom:  { label: "Svejedno", value: 2 },
      odabraniSvi: false,
      vrstaPublikacije: { label: 'Sve', value: 0 },
      podkategorijaPublikacije: { label: 'Sve', value: 0 },
      indeksiranost: [true,true,true,true,true],
      kvartil: { label: "Svejedno", value: 0 },
      kvartilJCRMulti: [{ label: "Svejedno", value: 0 }],
      kvartilJCIMulti: [{ label: "Svejedno", value: 0 }],
      kvartilSJRMulti: [{ label: "Svejedno", value: 0 }],
      povoljnijiQ: { label: "DA (od svih)", value: 1 },
      kvartilJCR: { label: "Svejedno", value: 0 },
      kvartilSJR: { label: "Svejedno", value: 0 },
      inozemniCasopis: { label: "Svejedno", value: 2 },      
      radSaStudentom: { label: "Svejedno", value: 2 },
      radSDoktorandom: { label: "Svejedno", value: 2 },
      radSGospodarstvom: { label: "Svejedno", value: 2 },
      radNaProjektu: { label: "Svejedno", value: 2 },
      medjuzavodskiRad: { label: "Svejedno", value: 2 },
      radSDrugimZnanInst: { label: "Svejedno", value: 2 },
      radSInozemnimZnan:  { label: "Svejedno", value: 2 },
      radOtvoreniPristup: { label: "Svejedno", value: 2 },
      vanUstanove: { label: "Ne", value: 0 },
      prijavljeniKorisnik: "nesto",
      loading: false
    },
  prijavljeniKorisnik: {
    aai: "Anonimni korisnik",
    jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhYWkiOiJBbm9uaW1uaSBrb3Jpc25payIsImltZSI6IiIsInByZXppbWUiOiIiLCJ0aXR1bGEiOiIiLCJlbWFpbCI6IiIsImlhdCI6MTY3MTYxODk3OCwiZXhwIjoxOTcxNzA1Mzc4fQ.RlRmy2QbHmiGhVz80ngUxuQzpZCU4Z5jDsUC8VixsHw"
  }
  
}));

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
     <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
